import { FC, useState } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ThirdPartyNewsletter/styles';

interface IProps {
  producerName: string;
  withMarginTop?: boolean;
  handleThirdPartyNewsletter: (value: boolean) => void;
}

const ThirdPartyNewsletterSticky: FC<IProps> = ({ handleThirdPartyNewsletter, producerName, withMarginTop }) => {
  const [isShowReadMoreDescription, setIsShowReadMoreDescription] = useState(false);
  const { titleText, descriptionText, acceptBtnText, rejectBtnText } = localeCommon.ThirdPartyNewsletter;
  const { ctaReadMoreBtn, ctaReadLessBtn } = localeCommon.commonMessages;

  return (
    <>
      <S.ThirdPartyNewsletterContainer
        $isVariantSticky
        $withMarginTop={withMarginTop}
        align="center"
        data-testid="ThirdPartyNewsletterSticky"
        justify="center"
      >
        <S.ThirdPartyNewsletterWrapper $isVariantSticky align="center" direction="column" gap={16} justify="center">
          <S.TitleTextWrapper $isVariantSticky>
            <Text
              color={STATIC_COLORS.base.white}
              localeIndex={{ producerName }}
              localeOptions={{ producerNameText: producerName }}
              size="body1"
              text={titleText}
              weight="normal"
            />

            <S.MoreText
              color="#E9C46A"
              handleClick={() => setIsShowReadMoreDescription(prevState => !prevState)}
              size="body1"
              text={isShowReadMoreDescription ? ctaReadLessBtn : ctaReadMoreBtn}
              weight="normal"
            />
          </S.TitleTextWrapper>

          {isShowReadMoreDescription && (
            <Text
              color={STATIC_COLORS.base.white}
              localeIndex={{ producerName }}
              localeOptions={{ producerNameText: producerName }}
              size="body3"
              text={descriptionText}
            />
          )}

          <S.BtnWrapper $isVariantSticky>
            <S.AcceptBtn
              $isVariantSticky
              fontSize="body1"
              size="sm"
              text={acceptBtnText}
              textWeight="medium"
              variant="light"
              onClick={() => handleThirdPartyNewsletter(true)}
            />
            <S.RejectBtn
              $isVariantSticky
              fontSize="body1"
              size="sm"
              text={rejectBtnText}
              textWeight="medium"
              variant="primary"
              onClick={() => handleThirdPartyNewsletter(false)}
            />
          </S.BtnWrapper>
        </S.ThirdPartyNewsletterWrapper>
      </S.ThirdPartyNewsletterContainer>
    </>
  );
};

export default ThirdPartyNewsletterSticky;
