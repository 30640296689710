import styled from 'styled-components';

export const CirclesBackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`;
export const CirclesBackground = styled.div`
  position: absolute;
  top: -70px;
  left: 50%;
  width: auto;
  margin: 0 auto;
  transform: translateX(-50%);
`;
