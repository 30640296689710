import { FC, ReactNode } from 'react';

import { TLanguage } from '@lib/core/retailers/types';
import ServiceInstanceContainer from '@lib/tools/shared/serviceInstances/views/containers/ServiceInstanceContainer';
import { TToastMessages } from '@lib/tools/toastMessage/types/interfaces';

import { FooterWidgetAnonymous, FooterWidgetLogged } from '@components/web/src/atoms/Footers';
import Header from '@components/web/src/atoms/Header/Header';
import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterWidget/styles';
import ToastMessage from '@components/web/src/atoms/Toast/ToastMessage';
import DownloadAppCard from '@components/web/src/organisms/Cards/DownloadAppCard/DownloadAppCard';

interface IProps {
  children: ReactNode;
  isUserAuthenticated: boolean;
  isHideGearIcon: boolean;
  isDarkTheme: boolean;
  isNavigateUserToTest: boolean;
  isShowLanguageSwitcher: boolean;
  isShowServiceInstanceContainer: boolean;
  isEnableRedirectionToApp: boolean;
  locale: string;
  retailerLanguages: TLanguage[];
  profileImageUrl?: string;
  isAnonymousWidgetAccess: boolean;
  shouldHideHomeLink: boolean;
  shouldHideCatalogLink: boolean;
  shouldHideTasteIdLink: boolean;
  shouldHideExploreLink: boolean;
  shouldShowTasteIdGuide?: boolean;
  shouldShowDownloadAppCard: boolean;
  isFixedBody: boolean;
  toastMessages: TToastMessages;
  handleClearToastMessage: () => void;
  handleSetTasteIdPageShown?: () => void;
  handleDownloadAppClick?: () => void;
  handleHideDownloadAppClick?: () => void;
}

const HeaderFooterWidget: FC<IProps> = ({
  children,
  isUserAuthenticated,
  isHideGearIcon,
  isDarkTheme,
  isNavigateUserToTest,
  isShowLanguageSwitcher,
  isShowServiceInstanceContainer,
  isEnableRedirectionToApp,
  locale,
  retailerLanguages,
  profileImageUrl,
  isAnonymousWidgetAccess,
  shouldHideCatalogLink,
  shouldHideTasteIdLink,
  shouldHideHomeLink,
  shouldShowDownloadAppCard,
  shouldHideExploreLink,
  shouldShowTasteIdGuide,
  isFixedBody,
  toastMessages,
  handleClearToastMessage,
  handleSetTasteIdPageShown,
  handleDownloadAppClick,
  handleHideDownloadAppClick,
}) => (
  <S.HeaderFooterWidgetContainer data-testid="HeaderFooterWidget">
    {toastMessages && (
      <ToastMessage
        handleClearToastMessage={handleClearToastMessage}
        message={toastMessages.message}
        title={toastMessages.title}
        type={toastMessages.type}
      />
    )}
    {shouldShowDownloadAppCard && (
      <DownloadAppCard handleClick={handleDownloadAppClick} handleCloseClick={handleHideDownloadAppClick} />
    )}
    <Header
      isDarkTheme={isDarkTheme}
      isNavigateUserToTest={isNavigateUserToTest}
      isShowLanguageSwitcher={isShowLanguageSwitcher}
      locale={locale}
      retailerLanguages={retailerLanguages}
    />
    <S.BodyContainer $isFixedBody={isFixedBody} data-testid="BodyContainer" id="root-scroll-element">
      {isShowServiceInstanceContainer && (
        <ServiceInstanceContainer isEnableRedirectionToApp={isEnableRedirectionToApp} />
      )}
      {children}
    </S.BodyContainer>
    {isUserAuthenticated && !isAnonymousWidgetAccess ? (
      <FooterWidgetLogged
        handleSetTasteIdPageShown={handleSetTasteIdPageShown}
        profileImageUrl={profileImageUrl}
        shouldHideCatalogLink={shouldHideCatalogLink}
        shouldHideExploreLink={shouldHideExploreLink}
        shouldHideHomeLink={shouldHideHomeLink}
        shouldHideTasteIdLink={shouldHideTasteIdLink}
        shouldShowTasteIdGuide={shouldShowTasteIdGuide}
      />
    ) : (
      <FooterWidgetAnonymous isHideGearIcon={isHideGearIcon} />
    )}
  </S.HeaderFooterWidgetContainer>
);

export default HeaderFooterWidget;
