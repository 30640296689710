import { TProductCategory } from '@lib/core/products/types';
import { history } from '@lib/core/service/utils/Navigator';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_COLORS,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import beerPairingIcon from '@components/web/src/assets/png/sketches_recipes_beer.png';
import coffeePairingIcon from '@components/web/src/assets/png/sketches_recipes_coffee.png';
import winePairingIcon from '@components/web/src/assets/png/sketches_recipes_wine.png';
import beerTasteIcon from '@components/web/src/assets/png/sketches_tastetest_beer.png';
import coffeeTasteIcon from '@components/web/src/assets/png/sketches_tastetest_coffee.png';
import wineTasteIcon from '@components/web/src/assets/png/sketches_tastetest_wine.png';

export const setUrlParams = (params: { key: string; value: string }[]) => {
  const {
    location: { pathname, search },
  } = window;
  const searchParams = new URLSearchParams(search);

  params.forEach(({ key, value }) => {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  });

  history.push(`${pathname}?${searchParams.toString()}`);
};

export const removeUrlParams = ({ keys }: { keys: string[] }) => {
  const {
    location: { pathname, search },
  } = window;
  const newSearchParams = new URLSearchParams(search);
  keys.forEach(key => {
    const currentParamValue = newSearchParams.get(key);
    if (currentParamValue) {
      newSearchParams.delete(key);
    }
  });
  const newSearchParamsString = newSearchParams.toString();
  if (newSearchParamsString) {
    history.push(`${pathname}?${newSearchParams.toString()}`);
  } else {
    history.push(pathname);
  }
};

export const parseLimitedCatalogProduct = productInstanceData => {
  const {
    product: { character: productCharacter },
    price,
    currency,
  } = productInstanceData;
  return { ...productInstanceData, character: productCharacter, price: { currency, value: price } };
};

/**
 * @param {string} queryString - queryparams
 * @param {string} queryKey - parameter to get value of
 * @returns {string} value of the parameter
 */
export const getQueryValue = (queryString, querykey) => {
  const params = new URLSearchParams(queryString);
  return params.get(querykey);
};

export const getPrimaryProductCategoryColor = (productCategory: string) =>
  PRODUCT_CATEGORY_COLORS.primary[productCategory] || '';

export const getSecondaryProductCategoryColor = (productCategory: string) =>
  PRODUCT_CATEGORY_COLORS.secondary[productCategory] || '';

export const getRandomIndex = (limitValue: number) => Math.floor(Math.random() * limitValue);

export const isVideoLink = url => {
  // Define an array of video file extensions
  const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.wmv', '.flv', '.webm'];

  // Get the file extension from the URL
  const fileExtension = url.split('.').pop().toLowerCase();

  // Check if the file extension is in the list of video extensions
  return videoExtensions.includes(`.${fileExtension}`);
};

export const getProductCategoryPairingIcon = (productCategory: TProductCategory) => {
  switch (productCategory) {
    case PRODUCT_CATEGORY_WINE:
      return winePairingIcon;
    case PRODUCT_CATEGORY_BEER:
      return beerPairingIcon;
    case PRODUCT_CATEGORY_COFFEE:
      return coffeePairingIcon;
    default:
      return winePairingIcon;
  }
};

export const getProductCategoryTasteIcon = (productCategory: TProductCategory) => {
  switch (productCategory) {
    case PRODUCT_CATEGORY_WINE:
      return wineTasteIcon;
    case PRODUCT_CATEGORY_BEER:
      return beerTasteIcon;
    case PRODUCT_CATEGORY_COFFEE:
      return coffeeTasteIcon;
    default:
      return wineTasteIcon;
  }
};
