import { FC, SyntheticEvent, useEffect, useState } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import * as S from '@components/web/src/atoms/Wishlist/styles';
import WishlistAnimation, {
  TWishlistVariant,
} from '@components/web/src/atoms/Wishlist/WishlistAnimation/WishlistAnimation';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  variant?: TWishlistVariant;
  isItemInWishlist: boolean;
  isItemListLoading?: boolean;
  shouldHideStatus?: boolean;
  handleUpdateWishlist: () => void;
  handleAuthFeatureClick?: () => void;
}

const Wishlist: FC<IProps> = ({
  variant = 'default',
  isItemInWishlist = false,
  isItemListLoading = false,
  shouldHideStatus = false,
  handleUpdateWishlist,
  handleAuthFeatureClick,
}) => {
  const { save, saved } = localeCommon.wishlist;

  const [isStartAnimation, setIsStartAnimation] = useState(false);

  const statusText = isItemInWishlist ? saved : save;
  const isLightVariant = variant === 'light';

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (handleAuthFeatureClick) {
      handleAuthFeatureClick();
    } else {
      handleUpdateWishlist();
    }

    if (!isItemInWishlist) {
      setIsStartAnimation(true);
    }
  };

  useEffect(() => {
    if (!isItemInWishlist) return () => null;

    const timer = setTimeout(() => {
      setIsStartAnimation(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isItemInWishlist, isStartAnimation]);

  return (
    <S.WishlistContainer data-testid="WishlistContainer" disabled={isItemListLoading} onClick={handleClick}>
      <WishlistAnimation
        isActive={isItemInWishlist && isStartAnimation}
        isAnimationEnded={isItemInWishlist && !isStartAnimation}
        isAnimationNotStarted={!isItemInWishlist}
        variant={variant}
      />
      {!shouldHideStatus && (
        <Text
          color={isLightVariant ? STATIC_COLORS.base.white : STATIC_COLORS.teal['600']}
          size="body3"
          text={statusText}
          weight="medium"
        />
      )}
    </S.WishlistContainer>
  );
};

export default Wishlist;
