import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TLanguage } from '@lib/core/retailers/types';
import { setServiceLocale } from '@lib/core/service/slices';
import { languages } from '@lib/tools/locale/utils/consts';
import useOutsideClick from '@lib/tools/views/hooks/useOutsideClick';

import GBicon from '@components/web/src/assets/legacy/b2b/kiosk/icons/GB.svg';
import ITicon from '@components/web/src/assets/legacy/b2b/kiosk/icons/IT.svg';
import LanguageLists from '@components/web/src/components/LanguageSwitcher/LanguageList/LanguageLists';
import SelectedLanguage from '@components/web/src/components/LanguageSwitcher/SelectedLanguage/SelectedLanguage';
import * as S from '@components/web/src/components/LanguageSwitcher/styles';

export type ILanguageSwitcherType = 'select' | 'icon';

interface IProps {
  isDarkTheme?: boolean;
  languageSwitcherType: ILanguageSwitcherType;
  retailerLanguages: TLanguage[];
  locale: string;
}

const LanguageSwitcher: FC<IProps> = ({ isDarkTheme = false, retailerLanguages, languageSwitcherType, locale }) => {
  const dispatch = useDispatch();

  const [showLanguagesList, setShowLanguagesList] = useState(false);

  const handleOutsideClick = () => {
    setShowLanguagesList(false);
  };

  const ref = useRef();
  useOutsideClick(showLanguagesList ? ref : null, handleOutsideClick);

  const handleToggleLanguagesList = () => {
    setShowLanguagesList(!showLanguagesList);
  };

  const handleSelectLanguage = selectedLanguageCode => {
    if (selectedLanguageCode !== locale) {
      dispatch(setServiceLocale(selectedLanguageCode));
    }
    setShowLanguagesList(false);
  };

  if (!retailerLanguages || retailerLanguages?.length < 2) return null;
  return (
    <>
      {languageSwitcherType === 'select' ? (
        <S.LanguageSwitcherContainer ref={ref} data-testid="LanguageSwitcherContainer">
          <S.LanguageSwitcherWrapper
            $isActive={showLanguagesList}
            align="center"
            justify="space-between"
            onClick={handleToggleLanguagesList}
          >
            <SelectedLanguage
              activeLanguage={locale}
              isBlackVariant={isDarkTheme}
              showLanguageList={showLanguagesList}
            />
          </S.LanguageSwitcherWrapper>
          {showLanguagesList && <LanguageLists activeLanguage={locale} handleSelectedLanguage={handleSelectLanguage} />}
        </S.LanguageSwitcherContainer>
      ) : (
        <S.IconContainer>
          {retailerLanguages.map(({ code }) => {
            const isSelected = locale === code;
            const icon = code === languages.ITALIAN ? ITicon : GBicon;

            return (
              <S.LanguageSwitcherButton key={code} $isSelected={isSelected} onClick={() => handleSelectLanguage(code)}>
                <S.IconImage
                  $isSelected={isSelected}
                  alt={`${code} icon`}
                  data-testid={`${code}-${isSelected ? 'selected' : 'unselected'}`}
                  src={icon}
                />
              </S.LanguageSwitcherButton>
            );
          })}
        </S.IconContainer>
      )}
    </>
  );
};

export default LanguageSwitcher;
