import { FC, useState } from 'react';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { TProductCatalogOrdering } from '@lib/tools/filterManager/slices/productFilter';
import FilterContainer from '@lib/tools/filterManager/views/containers/FilterContainer';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';
import { IFilterProps, IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import ProductListComponent from '@components/web/src/components/Catalog/ProductList/ProductList';
import SortingSection from '@components/web/src/components/Catalog/SortingSection/SortingSection';
import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { STATIC_COLORS } from '@components/web/src/foundations';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import * as S from '@components/web/src/pages/widget/Catalog/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import SeeAllProductsButton from '@components/web/src/widget/Buttons/SeeAllProductsButton';
import MatchedProducts from '@components/web/src/widget/Catalog/MatchedProducts';

export interface IExtendedCatalogProps {
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  products: TProductInstance[];
  matchedProducts: TProductInstance[];
  catalogType: string;
  producerName?: string;
  isProductsLoaded: boolean;
  isProductsRequestLoading: boolean;
  shouldShowDownloadAppCard?: boolean;
  productCategory: TProductCategory;
  filterProps?: IFilterProps;
  itemsCount?: number;
  storeType: TRetailerLocationStoreType;
  locale: string;
  withOpenedProductList?: boolean;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  feedbackData?: IFeedbackData[];
  isFeedbackLoading?: boolean;
  isProductListLoading?: boolean;
  wishlistProductIds: string[];
  isEnableTastePathAddon: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isChangeOrderingEnabled?: boolean;
  activeOrdering?: TProductCatalogOrdering;
  isShowThirdPartyNewsletter: boolean;
  handleThirdPartyNewsletter: (value: boolean) => void;
  handleChangeOrdering?: (order: TProductCatalogOrdering) => void;
  handleUpdateWishlistProductList: (productId: string) => void;
  handleUpdateFeedback?: (feedback: TFeedback, productId: string) => void;
  handleAuthFeatureClick?: () => void;
}

const ExtendedCatalog: FC<IExtendedCatalogProps> = ({
  products,
  isProductsLoaded,
  matchedProducts,
  catalogType,
  storeType,
  producerName,
  productCategory = PRODUCT_CATEGORY_WINE,
  itemsCount,
  shouldShowDownloadAppCard,
  filterProps,
  isProductsRequestLoading,
  locale,
  withOpenedProductList = false,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  isEnableTastePathAddon,
  feedbackData = [],
  wishlistProductIds,
  isProductListLoading,
  discoveryQuiz,
  activeOrdering,
  isChangeOrderingEnabled = false,
  isShowThirdPartyNewsletter,
  handleThirdPartyNewsletter,
  handleChangeOrdering,
  handleProductsRequest,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  handleAuthFeatureClick,
}) => {
  const { isFilterOpened } = useCatalogFilter();
  const [isProductsListOpened, setIsProductsListOpened] = useState(withOpenedProductList);
  const isMatchedProductsAvailable = !!matchedProducts.length;
  const isProductsAvailable = !!products.length;
  const noProductsAvailable =
    !isProductsAvailable && !isMatchedProductsAvailable && isProductsLoaded && !isProductsRequestLoading;

  const handleToggleProductsList = () => {
    setIsProductsListOpened(!isProductsListOpened);
  };

  const { productCatalog: productCatalogMessagesWidget } = localeWidget;
  return (
    <S.ProductCatalogWrapper data-testid="ExtendedCatalog">
      {noProductsAvailable && <NoProductsAvailable />}
      {!isFilterOpened && (
        <>
          {isMatchedProductsAvailable && (
            <>
              <MatchedProducts
                catalogType={catalogType}
                discoveryQuiz={discoveryQuiz}
                feedbackData={feedbackData}
                handleAuthFeatureClick={handleAuthFeatureClick}
                handleProductsRequest={handleProductsRequest}
                handleUpdateFeedback={handleUpdateFeedback}
                handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                isEnableLocationMapAddon={isEnableLocationMapAddon}
                isEnableTastePathAddon={isEnableTastePathAddon}
                isEnableVusionAddon={isEnableVusionAddon}
                isProductListLoading={isProductListLoading}
                isProductsLoaded={isProductsLoaded}
                isProductsRequestLoading={!isProductsLoaded}
                isShowProductLocationAddon={isShowProductLocationAddon}
                locale={locale}
                matchedProducts={matchedProducts}
                productCategory={productCategory}
                storeType={storeType}
                wishlistProductIds={wishlistProductIds}
              />
              {isShowThirdPartyNewsletter && (
                <ThirdPartyNewsletter
                  handleThirdPartyNewsletter={handleThirdPartyNewsletter}
                  producerName={producerName}
                  withMarginTop={shouldShowDownloadAppCard}
                />
              )}
            </>
          )}

          {isMatchedProductsAvailable && isProductsAvailable && (
            <S.SeeAllProductsButtonWrapper>
              <SeeAllProductsButton handleClick={handleToggleProductsList} isOpenedState={isProductsListOpened} />
            </S.SeeAllProductsButtonWrapper>
          )}
          {isProductsListOpened && isProductsAvailable && (
            <S.StyledText
              color={STATIC_COLORS.base.black}
              size="body1"
              text={productCatalogMessagesWidget.soManyProducts}
              weight="semibold"
            />
          )}
        </>
      )}

      <FilterContainer
        {...filterProps}
        isSearchEnabled
        handleApply={handleProductsRequest}
        isLayoutHidden={!isProductsListOpened || isProductsRequestLoading}
        productCategory={productCategory}
      />

      {!isFilterOpened && isProductsListOpened && (
        <>
          <SortingSection
            activeOrdering={activeOrdering}
            handleChangeOrdering={handleChangeOrdering}
            isChangeOrderingEnabled={isChangeOrderingEnabled}
            isProductsRequestLoading={isProductsRequestLoading}
            itemsCount={itemsCount}
          />
          <ProductListComponent
            disablePagination={false}
            discoveryQuiz={discoveryQuiz}
            feedbackData={feedbackData}
            handleAuthFeatureClick={handleAuthFeatureClick}
            handleProductsRequest={handleProductsRequest}
            handleThirdPartyNewsletter={handleThirdPartyNewsletter}
            handleUpdateFeedback={handleUpdateFeedback}
            handleUpdateWishlistProductList={handleUpdateWishlistProductList}
            isEnableLocationMapAddon={isEnableLocationMapAddon}
            isEnableTastePathAddon={isEnableTastePathAddon}
            isEnableVusionAddon={isEnableVusionAddon}
            isMatchedProductsAvailable={isMatchedProductsAvailable}
            isProductListLoading={isProductListLoading}
            isProductsRequestLoading={isProductsRequestLoading}
            isShowProductLocationAddon={isShowProductLocationAddon}
            isShowThirdPartyNewsletter={isShowThirdPartyNewsletter}
            itemsCount={itemsCount}
            locale={locale}
            producerName={producerName}
            products={products}
            shouldShowDownloadAppCard={shouldShowDownloadAppCard}
            storeType={storeType}
            wishlistProductIds={wishlistProductIds}
          />
        </>
      )}

      {isProductsRequestLoading && !!isProductsLoaded && <Spinner />}
    </S.ProductCatalogWrapper>
  );
};

export default ExtendedCatalog;
