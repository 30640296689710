import { FC } from 'react';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import {
  LIMITED_CATALOG,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import GenericSpinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import { LimitedFilter } from '@components/web/src/widget/Catalog/LimitedFilter/LimitedFilter';
import * as S from '@components/web/src/widget/Catalog/styles';

interface IProps {
  matchedProducts: TProductInstance[];
  catalogType: string;
  isProductsRequestLoading: boolean;
  productCategory: TProductCategory;
  storeType: TRetailerLocationStoreType;
  locale: string;
  isProductsLoaded: boolean;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  isEnableTastePathAddon?: boolean;
  feedbackData?: IFeedbackData[];
  wishlistProductIds: string[];
  isProductListLoading?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback?: (feedback: TFeedback, productId: string) => void;
  handleAuthFeatureClick?: () => void;
}

const MatchedProducts: FC<IProps> = ({
  isProductsRequestLoading,
  matchedProducts,
  productCategory = PRODUCT_CATEGORY_COFFEE,
  catalogType,
  storeType,
  locale,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  isEnableTastePathAddon,
  wishlistProductIds = [],
  isProductListLoading,
  isProductsLoaded,
  feedbackData,
  discoveryQuiz,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  handleProductsRequest,
  handleAuthFeatureClick,
}) => {
  const { productCatalog } = localeWidget;

  const swiperHeadingTextByProduct = {
    [PRODUCT_CATEGORY_BEER]: productCatalog.pourBeer,
    [PRODUCT_CATEGORY_COFFEE]: productCatalog.pourCoffee,
    [PRODUCT_CATEGORY_WINE]: productCatalog.uncorkTheWineShort,
  };

  const isLimitedCatalog = catalogType === LIMITED_CATALOG;

  return (
    <>
      {!isProductsLoaded ? (
        <GenericSpinner />
      ) : (
        <S.SwiperSection
          $isSingleProduct={matchedProducts.length === 1}
          data-testid={`MatchedProducts-${productCategory}`}
        >
          <S.SwiperTitleSection>
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h5"
              text={swiperHeadingTextByProduct[productCategory]}
              weight="semibold"
            />
            {isLimitedCatalog && false && <LimitedFilter handleProductsRequest={handleProductsRequest} />}
          </S.SwiperTitleSection>
          {matchedProducts.length > 1 ? (
            <SwiperHOC>
              {isProductsRequestLoading
                ? [1, 2, 3].map(key => (
                    <ProductCard key={key} isLoading={isProductsRequestLoading} locale={locale} storeType={storeType} />
                  ))
                : matchedProducts.map((productInstanceData, index) => (
                    <ProductCard
                      key={index}
                      discoveryQuiz={discoveryQuiz}
                      feedback={feedbackFilter(feedbackData, productInstanceData)}
                      handleAuthFeatureClick={handleAuthFeatureClick}
                      handleUpdateFeedback={handleUpdateFeedback}
                      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                      isEnableLocationMapAddon={isEnableLocationMapAddon}
                      isEnableTastePathAddon={isEnableTastePathAddon}
                      isEnableVusionAddon={isEnableVusionAddon}
                      isLoading={false}
                      isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, productInstanceData)}
                      isProductListLoading={isProductListLoading}
                      isShowProductLocationAddon={isShowProductLocationAddon}
                      locale={locale}
                      mixpanelIndex={index}
                      mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                      productInstanceData={productInstanceData}
                      storeType={storeType}
                    />
                  ))}
            </SwiperHOC>
          ) : (
            matchedProducts.length === 1 && (
              <ProductCard
                isResponsive
                discoveryQuiz={discoveryQuiz}
                feedback={feedbackFilter(feedbackData, matchedProducts[0])}
                handleAuthFeatureClick={handleAuthFeatureClick}
                handleUpdateFeedback={handleUpdateFeedback}
                handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                isEnableLocationMapAddon={isEnableLocationMapAddon}
                isEnableTastePathAddon={isEnableTastePathAddon}
                isEnableVusionAddon={isEnableVusionAddon}
                isLoading={false}
                isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, matchedProducts[0])}
                isProductListLoading={isProductListLoading}
                isShowProductLocationAddon={isShowProductLocationAddon}
                locale={locale}
                mixpanelIndex={0}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                productInstanceData={matchedProducts[0]}
                storeType={storeType}
              />
            )
          )}
        </S.SwiperSection>
      )}
    </>
  );
};

export default MatchedProducts;
