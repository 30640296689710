import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import { actionChangeAgreements } from '@lib/core/service/slices/technical/agreements';
import { prependBasename } from '@lib/core/service/utils';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { useDisclosure } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { IModalOption } from '@components/web/src/app/Modals/modalTypes';
import SocialWelcomePage from '@components/web/src/pages/app/SocialWelcomePage/SocialWelcomePage';
import RegistrationProfilingModal from '@components/web/src/templates/Modals/RegistrationProfilingModal/RegistrationProfilingModal';

const SocialWelcomePageContainer = () => {
  const [isPrivacyProfilingPolicyChecked, setIsPrivacyProfilingPolicyChecked] = useState(false);
  const [isOptionalEmailPolicyChecked, setIsOptionalEmailPolicyChecked] = useState(false);
  const dispatch = useDispatch();
  const {
    socialWelcomePageContainer: { profilingDesc, profilingTitle },
  } = localeApp;

  const { appAgreements } = useTechnical();
  const { isUserAllowedProfiling } = appAgreements;

  const registrationPolicyOptions: IModalOption = {
    profiling: {
      description: profilingDesc,
      isEnabled: false,
      title: profilingTitle,
    },
  };

  const [
    isRegistrationProfilingModalOpen,
    { open: openRegistrationProfilingModal, close: closeRegistrationProfilingModal },
  ] = useDisclosure();

  const navigate = useNavigate();

  useEffect(() => {
    if (isOptionalEmailPolicyChecked) {
      dispatch(
        actionChangeAgreements({
          isUserAllowedUseEmailForMarketing: true,
        }),
      );
    }

    if (isOptionalEmailPolicyChecked && isPrivacyProfilingPolicyChecked && !isUserAllowedProfiling) {
      openRegistrationProfilingModal();
    }
  }, [isOptionalEmailPolicyChecked, isPrivacyProfilingPolicyChecked]);

  const handleRegistrationModalConfirmBtn = (values: Record<string, boolean>) => {
    if (!values.profiling) {
      setIsOptionalEmailPolicyChecked(false);
    }
    dispatch(actionChangeAgreements(values));
    closeRegistrationProfilingModal();
  };

  const handleRegistrationProfilingModalClose = () => {
    setIsOptionalEmailPolicyChecked(false);
    closeRegistrationProfilingModal();
  };

  const handleStartTestClick = () => {
    navigate(`${prependBasename(PAGES.vinhood.quiz.chooseTaste)}`);
  };

  const sendProps = {
    handleStartTestClick,
    isOptionalEmailPolicyChecked,
    isPrivacyProfilingPolicyChecked,
    openRegistrationProfilingModal,
    setIsOptionalEmailPolicyChecked,
    setIsPrivacyProfilingPolicyChecked,
  };

  return (
    <Fragment>
      <SocialWelcomePage {...sendProps} />
      <RegistrationProfilingModal
        handleConfirmButton={handleRegistrationModalConfirmBtn}
        hideModal={handleRegistrationProfilingModalClose}
        isModalOpen={isRegistrationProfilingModalOpen}
        options={registrationPolicyOptions}
      />
    </Fragment>
  );
};

export default SocialWelcomePageContainer;
