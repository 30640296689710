import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateFilterType } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_LOCATION,
  FILTER_TYPE_MOOD,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRODUCT_CATEGORY,
  FILTER_TYPE_RATING,
  FILTER_TYPE_STYLE,
} from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

import { ReactComponent as characteristicsIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_characteristics.svg';
import { ReactComponent as HideIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_minus.svg';
import { ReactComponent as moodIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_mood.svg';
import { ReactComponent as originIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_origin.svg';
import { ReactComponent as OpenIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_plus.svg';
import { ReactComponent as productFormatIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_product_format.svg';
import { ReactComponent as productCategoryIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_productType.svg';
import { ReactComponent as styleIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_style.svg';
import { ReactComponent as ratingIcon } from '@components/web/src/assets/legacy/icons/filter_rating_icon.svg';
import * as S from '@components/web/src/components/Catalog/Filter/ListItem/Sublist/styles';
import SublistItemsList from '@components/web/src/components/Catalog/Filter/ListItem/Sublist/SublistItemsList';
import SublistRatingsList from '@components/web/src/components/Catalog/Filter/ListItem/Sublist/SublistRatingsList';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export interface ISublistItem {
  active: boolean;
  name: string;
  value: string;
}

type Props = {
  title: string;
  filterType:
    | typeof FILTER_TYPE_STYLE
    | typeof FILTER_TYPE_MOOD
    | typeof FILTER_TYPE_ORIGIN
    | typeof FILTER_TYPE_CHARACTERISTICS
    | typeof FILTER_TYPE_PRODUCT_CATEGORY
    | typeof FILTER_TYPE_FORMAT
    | typeof FILTER_TYPE_LOCATION
    | typeof FILTER_TYPE_RATING;
  listContent: ISublistItem[];
  isUpperCase?: boolean;
  variant?: 'plain' | 'rounded';
  isRatingVariant: boolean;
};

const Sublist: FC<Props> = ({ variant = 'plain', title, listContent, filterType, isUpperCase, isRatingVariant }) => {
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const { sublist: { [filterType]: selectedNamesObj = {} } = {} } = useTypedSelector(state => state.productFilters);
  const isChecked = useMemo(() => Object.values(selectedNamesObj).some(value => value.isActive), [selectedNamesObj]);

  const onItemClick = data => dispatch(updateFilterType({ filterItem: { ...data, filterType } }));

  const iconsSet = {
    [FILTER_TYPE_CHARACTERISTICS]: characteristicsIcon,
    [FILTER_TYPE_FORMAT]: productFormatIcon,
    [FILTER_TYPE_LOCATION]: originIcon,
    [FILTER_TYPE_MOOD]: moodIcon,
    [FILTER_TYPE_ORIGIN]: originIcon,
    [FILTER_TYPE_PRODUCT_CATEGORY]: productCategoryIcon,
    [FILTER_TYPE_RATING]: ratingIcon,
    [FILTER_TYPE_STYLE]: styleIcon,
  };
  const CurrentIcon = iconsSet[filterType];

  return (
    <S.SublistWrapper data-testid="SublistWrapper">
      <S.SublistContainer
        aria-hidden
        $isOpened={isOpened}
        data-testid="SublistContainer"
        onClick={() => setIsOpened(prev => !prev)}
      >
        <Flexbox align="center" gap={12}>
          <S.Icon $isChecked={isChecked}>
            <CurrentIcon />
          </S.Icon>
          <Text
            color={isChecked ? STATIC_COLORS.teal['800'] : STATIC_COLORS.base.black}
            size="subtitle2"
            text={title}
            weight={isChecked ? 'semibold' : 'normal'}
          />
        </Flexbox>
        <S.SublistButton type="button">{isOpened ? <HideIcon /> : <OpenIcon />}</S.SublistButton>
      </S.SublistContainer>
      {isOpened &&
        (isRatingVariant ? (
          <SublistRatingsList listContent={listContent} selectedNamesObj={selectedNamesObj} onItemClick={onItemClick} />
        ) : (
          <SublistItemsList
            isUpperCase={isUpperCase}
            listContent={listContent}
            selectedNamesObj={selectedNamesObj}
            variant={variant}
            onItemClick={onItemClick}
          />
        ))}
    </S.SublistWrapper>
  );
};

export default Sublist;
