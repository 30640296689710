import { styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const HeaderFooterKioskContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: var(--color-secondary-100);
  box-shadow: ${STATIC_SHADOWS.xl};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const BodyContainer = styled(Flexbox)`
  flex: 1 1;
  width: 100%;
  max-width: ${SCREEN_SIZE.desktopKiosk};
`;
