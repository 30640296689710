import { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectRetailerAgreementsIndexedObj } from '@lib/core/retailers/selectors/retailer';
import { B2C_MODALS, HARDCODED_AGREEMENT_ID_TECHNICAL_STUFF } from '@lib/core/service/consts';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import {
  actionAcceptAllBasicAgreements,
  actionChangeAgreements,
  actionRejectAllBasicAgreements,
} from '@lib/core/service/slices/technical/agreements';
import { useUser } from '@lib/core/users/hooks';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';

import CookieModal from '@components/web/src/templates/Modals/CookieModal/CookieModal';
import CookiePolicyModal from '@components/web/src/templates/Modals/CookiePolicyModal/CookiePolicyModal';
import FoodPreferencesModal from '@components/web/src/templates/Modals/FoodPreferencesModal/FoodPreferencesModal';
import InterestsModal from '@components/web/src/templates/Modals/InterestsModal/InterestsModal';
import ManageModal from '@components/web/src/templates/Modals/ManageModal/ManageModal';
import PermissionModal from '@components/web/src/templates/Modals/PermissionModal/PermissionModal';
import PrivacyPolicyModal from '@components/web/src/templates/Modals/PrivacyPolicyModal/PrivacyPolicyModal';
import TermsConditionsModal from '@components/web/src/templates/Modals/TermsConditionsModal/TermsConditionsModal';

type Props = {
  shouldOpenModalsUnderHeader?: boolean;
  children: ReactElement;
};

const B2CModalsHOC: FC<Props> = ({ children, shouldOpenModalsUnderHeader }) => {
  const dispatch = useDispatch();

  const {
    closeAllModals,
    openModal,
    isCookieModalOpened,
    isCookiePolicyModalOpened,
    isFoodPreferencesModalOpened,
    isInterestsModalOpened,
    isLegalPolicyModalOpened,
    isManageModalOpened,
    isPermissionModalOpened,
    isPrivacyPolicyModalOpened,
  } = useModals();

  const { appAgreements } = useTechnical();
  const { isUserAllowedUseLocalStorage } = appAgreements;

  const { userId } = useUser();

  const {
    b2cModalsHoc: { deactivateAccountTitle, deactivateAccountDesc, deleteAccountTitle, deleteAccountDesc },
  } = localeApp;

  const accountOptions = {
    deactivateaccount: {
      description: deactivateAccountDesc,
      isEnabled: false,
      is_always_active: false,
      title: deactivateAccountTitle,
    },
    deleteaccount: {
      description: deleteAccountDesc,
      isEnabled: false,
      is_always_active: false,
      title: deleteAccountTitle,
    },
  };

  const {
    [HARDCODED_AGREEMENT_ID_TECHNICAL_STUFF]: { is_always_active: isTechnicalAgreementAlwaysActive = false } = {},
  } = useSelector(selectRetailerAgreementsIndexedObj);

  useEffect(() => {
    if (!isUserAllowedUseLocalStorage && !userId) {
      openModal(MODALS.COOKIE_MODAL);
    }
  }, []);

  const handleInformativeModalDefaultConfirmBtn = () => {
    dispatch(actionAcceptAllBasicAgreements());
    closeAllModals();
  };

  const handleCloseInformativeModalClick = () => {
    dispatch(actionRejectAllBasicAgreements());
    closeAllModals();
  };

  const handleInformativeModalManagePreferences = () => {
    closeAllModals();
    openModal(MODALS.PERMISSION_MODAL);
  };

  const handlePermissionModalGoBackBtn = () => {
    closeAllModals();
    if (!isTechnicalAgreementAlwaysActive) openModal(MODALS.COOKIE_MODAL);
  };

  const handlePermissionConfirmButton = (values: Record<string, boolean>) => {
    dispatch(actionChangeAgreements(values));
    closeAllModals();
  };

  const handleManageModalConfirmButton = values => {
    console.info(values);
  };

  const handleShowCookieModal = () => {
    openModal(B2C_MODALS.COOKIE_POLICY_MODAL);
  };

  return (
    <>
      <>
        {isLegalPolicyModalOpened && (
          <TermsConditionsModal
            hideModal={closeAllModals}
            isModalOpen={isLegalPolicyModalOpened}
            shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}
          />
        )}
        {isPrivacyPolicyModalOpened && (
          <PrivacyPolicyModal
            handleShowCookieModal={handleShowCookieModal}
            hideModal={closeAllModals}
            isModalOpen={isPrivacyPolicyModalOpened}
            shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}
          />
        )}
        {isCookiePolicyModalOpened && (
          <CookiePolicyModal
            hideModal={closeAllModals}
            isModalOpen={isCookiePolicyModalOpened}
            shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}
          />
        )}
        {isCookieModalOpened && (
          <CookieModal
            handleConfirm={handleInformativeModalDefaultConfirmBtn}
            handleManagePreferences={handleInformativeModalManagePreferences}
            hideModal={handleCloseInformativeModalClick}
            isModalOpen={isCookieModalOpened}
          />
        )}
        {isPermissionModalOpened && (
          <PermissionModal
            handleConfirmButton={handlePermissionConfirmButton}
            handleGoBack={handlePermissionModalGoBackBtn}
            hideModal={closeAllModals}
            isModalOpen={isPermissionModalOpened}
            shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}
          />
        )}
        {isFoodPreferencesModalOpened && (
          <FoodPreferencesModal hideModal={closeAllModals} isModalOpen={isFoodPreferencesModalOpened} />
        )}
        {isInterestsModalOpened && <InterestsModal hideModal={closeAllModals} isModalOpen={isInterestsModalOpened} />}
        {isManageModalOpened && (
          <ManageModal
            accountOptions={accountOptions}
            handleCancelButton={closeAllModals}
            handleConfirmButton={handleManageModalConfirmButton}
            hideModal={closeAllModals}
            isModalOpen={isManageModalOpened}
          />
        )}
      </>
      <>{children}</>
    </>
  );
};

export default B2CModalsHOC;
