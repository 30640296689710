import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const FilterToggleContainer = styled(Flexbox)<{ $isActive: boolean }>`
  height: 72px;
  padding: 16px;
  border-bottom: 1px solid ${STATIC_COLORS.warmGray[300]};
  background-color: ${({ $isActive }) => ($isActive ? `${STATIC_COLORS.teal[50]}` : STATIC_COLORS.warmGray[100])};
`;
