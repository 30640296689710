import { css, styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';

export const LanguageSwitcherContainer = styled.div`
  position: relative;
  min-width: 55px;
  max-width: fit-content;
  cursor: pointer;
`;

export const LanguageSwitcherWrapper = styled(Flexbox)<{ $isActive: boolean }>`
  width: 100%;
  padding: 8px 0 8px 8px;
  border-radius: 8px 8px 0 0;
  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${STATIC_COLORS.base.white};
    `}
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 72px;
`;

export const LanguageSwitcherButton = styled.button<{ $isSelected: boolean }>`
  all: unset;
  cursor: pointer;
  ${({ $isSelected }) =>
    !$isSelected &&
    css`
      background-color: inherit;
    `}
`;

export const IconImage = styled(Image)<{ $isSelected: boolean }>`
  ${({ $isSelected }) =>
    !$isSelected &&
    css`
      opacity: 0.3;
    `}
`;
