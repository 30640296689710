import { useSelector } from 'react-redux';

import { selectToastMessage } from '@lib/tools/toastMessage/selectors';
import { actionClearToastMessage } from '@lib/tools/toastMessage/slices';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useToastMessage = () => {
  const dispatch = useTypedDispatch();
  const toastMessages = useSelector(selectToastMessage);

  const handleClearToastMessage = () => {
    dispatch(actionClearToastMessage());
  };

  return {
    handleClearToastMessage,
    toastMessages,
  };
};
