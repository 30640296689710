import { TokenResponse } from '@react-oauth/google';
import { FC } from 'react';
import { AppleLoginProps } from 'react-apple-login';
import { animated, useTransition } from 'react-spring';

import { AuthBannerTypes } from '@lib/core/banners/consts';
import {
  RegisterPageScenarios,
  TRegisterPageScenarioState,
} from '@lib/core/service/slices/technical/registerPageScenario';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { ISocialLoginFacebook } from '@lib/tools/views/web/interfaces/loginSocial';

import AuthBanner from '@components/web/src/components/Authentication/AuthBanner/AuthBanner';
import RegistrationNavigation from '@components/web/src/components/Authentication/Registration/RegistrationNavigation/RegistrationNavigation';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import EmailRegistrationForm from '@components/web/src/organisms/Authentication/EmailRegistration/RegistrationEmailForm';
import SocialConnector from '@components/web/src/organisms/Authentication/SocialConnector/SocialConnector';
import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import * as S from '@components/web/src/templates/Login&Registration/LoginPage/styles';
import ResultAuthBanners from '@components/web/src/templates/Login&Registration/Registration/ResultAuthBanners/ResultAuthBanners';

interface IProps {
  isServiceInstanceFeatureEnabled: boolean;
  isDesignSetVinhoodExperience: boolean;
  isEmailAuthorization: boolean;
  isUserAllowedUseEmailForMarketing: boolean;
  authErrors: Record<string, string>;
  isSocialAuthTesting?: boolean;
  registerPageScenarioState: TRegisterPageScenarioState;
  isHideSkipAndGoToProductsAddon: boolean;
  locale?: string;
  onEmailAuthButtonClick: () => void;
  navigateToLoginPage: () => void;
  navigateToCatalog: () => void;
  handleChangeMarketingAgreementValue: (value: boolean) => void;
  handleFormSubmit: (data: RegistrationFormInputObject) => void;
  responseFacebook: (response: ISocialLoginFacebook) => void;
  responseApple: (response: AppleLoginProps) => void;
  responseGoogle: (response: TokenResponse) => void;
  onBackButtonClick?: () => void;
}

const RegistrationPage: FC<IProps> = ({
  isDesignSetVinhoodExperience,
  isUserAllowedUseEmailForMarketing,
  isEmailAuthorization,
  authErrors,
  isServiceInstanceFeatureEnabled,
  isHideSkipAndGoToProductsAddon,
  locale,
  registerPageScenarioState,
  onEmailAuthButtonClick,
  navigateToLoginPage,
  isSocialAuthTesting,
  navigateToCatalog,
  responseGoogle,
  responseFacebook,
  responseApple,
  handleFormSubmit,
  handleChangeMarketingAgreementValue,
  onBackButtonClick,
}) => {
  const {
    registrationPage,
    commonMessages: { back },
  } = localeCommon;

  const transitions = useTransition(isEmailAuthorization, {
    config: {
      duration: 300,
    },
    enter: {
      opacity: 1,
      y: '0%',
    },
    from: { opacity: 0, y: '-10%' },
    leave: { opacity: 0, y: '-8%' },
  });

  const { yesRight, ohNo } = localeApp.testResultPage;
  const {
    title: titleProductCardIconClick,
    description: descriptionProductCardIconClick,
    extraDescription: extraDescriptionProductCardIconClick,
  } = localeApp.registrationWithIcon;
  const { swiperData, scenario, description, extraDescription, locale: localeRegister } = registerPageScenarioState;

  const getCurrentData = (
    scenarioType,
  ): { currentTitle: ILocaleText; currentDescription: ILocaleText; currentExtraDescription: ILocaleText } => {
    let currentTitle;
    let currentDescription = description;
    let currentExtraDescription = extraDescription;

    switch (scenarioType) {
      case RegisterPageScenarios.ProductCardIconClick:
        currentTitle = titleProductCardIconClick;
        currentDescription = descriptionProductCardIconClick;
        currentExtraDescription = extraDescriptionProductCardIconClick;
        break;
      case RegisterPageScenarios.QuizAnswerClickFalse:
        currentTitle = ohNo;
        break;
      case RegisterPageScenarios.QuizAnswerClickTrue:
        currentTitle = yesRight;
        break;
      default:
    }

    return { currentDescription, currentExtraDescription, currentTitle };
  };

  const isResultVariant = [
    RegisterPageScenarios.AdjectiveClick,
    RegisterPageScenarios.CharacterClick,
    RegisterPageScenarios.QuizAnswerClickTrue,
    RegisterPageScenarios.QuizAnswerClickFalse,
    RegisterPageScenarios.ProductCardIconClick,
  ].includes(registerPageScenarioState.scenario);

  const isAnimated = registerPageScenarioState.scenario === RegisterPageScenarios.QuizAnswerClickTrue;

  const isResultVariantBannerClickScenario = registerPageScenarioState.scenario === RegisterPageScenarios.BannerClick;

  return (
    <S.AuthPageContainer data-testid={`AuthPageContainer-variant-${isResultVariant}`}>
      {(isResultVariant || isResultVariantBannerClickScenario) && (
        <BackButton
          arrowSize="short"
          handleClick={onBackButtonClick}
          style={{ justifyContent: 'flex-start', padding: '8px 16px' }}
          text={back}
        />
      )}
      {isResultVariant ? (
        <ResultAuthBanners
          description={getCurrentData(scenario).currentDescription}
          extraDescription={getCurrentData(scenario).currentExtraDescription}
          locale={localeRegister}
          scenario={scenario}
          showAnimation={isAnimated}
          swiperData={swiperData}
          title={getCurrentData(scenario).currentTitle}
        />
      ) : (
        <AuthBanner bannerType={isDesignSetVinhoodExperience ? AuthBannerTypes.experience : AuthBannerTypes.app} />
      )}
      <S.Content>
        {!isResultVariant && (
          <Text
            color={STATIC_COLORS.base.black}
            size="body1"
            text={isDesignSetVinhoodExperience ? registrationPage.headerSubTitle : registrationPage.headerSubTitleApp}
          />
        )}

        <SocialConnector
          isRegistrationPage
          handleEmailAuthButtonClick={onEmailAuthButtonClick}
          isEmailAuthorization={isEmailAuthorization}
          isSocialAuthTesting={isSocialAuthTesting}
          responseApple={responseApple}
          responseFacebook={responseFacebook}
          responseGoogle={responseGoogle}
        />
        {transitions((style, isOpen) => (
          <>
            {isOpen && (
              <animated.div style={style}>
                <EmailRegistrationForm
                  authErrors={authErrors}
                  handleChangeMarketingAgreementValue={handleChangeMarketingAgreementValue}
                  handleFormSubmit={handleFormSubmit}
                  isUserAllowedUseEmailForMarketing={isUserAllowedUseEmailForMarketing}
                  locale={locale}
                />
              </animated.div>
            )}
          </>
        ))}
        <RegistrationNavigation
          navigateToCatalog={navigateToCatalog}
          navigateToLoginPage={navigateToLoginPage}
          shouldDisplayCatalogLink={
            (isDesignSetVinhoodExperience && !isHideSkipAndGoToProductsAddon) || isServiceInstanceFeatureEnabled
          }
        />
      </S.Content>
    </S.AuthPageContainer>
  );
};

export default RegistrationPage;
