import { useSelector } from 'react-redux';

import { IRetailerLocation } from '@lib/core/retailers/types/retailerLocation';
import { selectExploreLocationList, selectIsExploreLocationListLoading } from '@lib/tools/explore/selectors';

type IExploreLocations = {
  exploreLocationList: IRetailerLocation[];
  isExploreLocationListLoading: boolean;
};

export const useExploreLocations = (): IExploreLocations => {
  const isExploreLocationListLoading = useSelector(selectIsExploreLocationListLoading);
  const exploreLocationList = useSelector(selectExploreLocationList);

  return {
    exploreLocationList,
    isExploreLocationListLoading,
  };
};
