import cn from 'classnames';
import { FC, useMemo } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_LOCATION,
  FILTER_TYPE_MOOD,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_PRODUCT_CATEGORY,
  FILTER_TYPE_PROMOTION_TOGGLE,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  FILTER_TYPE_WISHLIST,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import ToggleFilter from '@components/web/src/atoms/Filters/ToggleFilter/ToggleFilter';
import PriceRange from '@components/web/src/components/Catalog/Filter/ListItem/PriceRange/PriceRange';
import ShowOnly from '@components/web/src/components/Catalog/Filter/ListItem/ShowOnly/ShowOnly';
import Sublist from '@components/web/src/components/Catalog/Filter/ListItem/Sublist/Sublist';

type Props = {
  handleClose: () => void;
  handleApply: ({ isPagination }: { isPagination?: boolean }) => void;
  data: any;
  variant?: 'fullpage' | 'embedded';
  isJournalVariant?: boolean;
};

const FilterComponent: FC<Props> = ({
  handleClose,
  data = [],
  handleApply,
  variant = 'fullpage',
  isJournalVariant = false,
}) => {
  const { filterComponent, commonMessages } = localeCommon;

  const isVariantFullPage = variant === 'fullpage';

  const filtersTitles = {
    [FILTER_TYPE_CHARACTERISTICS]: filterComponent.characteristicsTitle,
    [FILTER_TYPE_FORMAT]: filterComponent.formatTitle,
    [FILTER_TYPE_LOCATION]: filterComponent.locationTitle,
    [FILTER_TYPE_MOOD]: filterComponent.moodTitle,
    [FILTER_TYPE_ORIGIN]: filterComponent.originTitle,
    [FILTER_TYPE_PRODUCT_CATEGORY]: filterComponent.productCategoryTitle,
    [FILTER_TYPE_RATING]: filterComponent.ratingTitle,
    [FILTER_TYPE_STYLE]: filterComponent.styleTitle,
  };

  const filters: JSX.Element[] = useMemo(
    () =>
      data.map((filterSectionData, index) => {
        switch (filterSectionData.filterType) {
          case FILTER_TYPE_CHARACTER_TOGGLE:
            return (
              <ToggleFilter
                key={index}
                inactiveSubtitle={filterComponent.toggleInactiveSubtitle}
                subtitle={filterComponent.toggleActiveSubtitle}
                title={filterComponent.toggleTitle}
                toggleType={FILTER_TYPE_CHARACTER_TOGGLE}
              />
            );
          case FILTER_TYPE_PROMOTION_TOGGLE:
            return (
              <ToggleFilter
                key={index}
                title={filterComponent.togglePromotionTitle}
                toggleType={FILTER_TYPE_PROMOTION_TOGGLE}
              />
            );
          case FILTER_TYPE_SHOW_FAVORITES:
            return <ShowOnly key={index} filterType={FILTER_TYPE_WISHLIST} title={filterComponent.wishlistTitle} />;
          case FILTER_TYPE_PRODUCT_CATEGORY:
          case FILTER_TYPE_ORIGIN:
          case FILTER_TYPE_CHARACTERISTICS:
          case FILTER_TYPE_FORMAT:
          case FILTER_TYPE_STYLE:
          case FILTER_TYPE_MOOD:
          case FILTER_TYPE_LOCATION:
          case FILTER_TYPE_RATING:
            return (
              <Sublist
                key={index}
                isRatingVariant={filterSectionData.filterType === FILTER_TYPE_RATING}
                title={filtersTitles[filterSectionData.filterType]}
                variant={variant === 'embedded' && 'rounded'}
                {...filterSectionData}
              />
            );
          case FILTER_TYPE_PRICE_RANGE:
            return isJournalVariant ? null : (
              <PriceRange key={index} title={filterComponent.priceRangeTitle} {...filterSectionData} />
            );
          default:
            return null;
        }
      }),
    [data],
  );

  return (
    <div className="filter-component-wrapper">
      <div className={cn('filter-component', { embedded: !isVariantFullPage })}>
        <div className="vinhood-filter-list">{filters}</div>
        <div className="page-buttons-container">
          <Button size="sm" text={commonMessages.cancelBtn} variant={VH_VARIANTS.LIGHT} onClick={handleClose} />
          <Button size="sm" text={filterComponent.applyBtn} onClick={() => handleApply({ isPagination: false })} />
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
