import { styled } from 'styled-components';

export const TestResultPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BannerWrapper = styled.div`
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
`;

export const SkeletonWrapper = styled.div`
  padding: 16px;
`;
