import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import { hardcodedListOfAgreementsIDs } from '@lib/core/service/slices/technical/agreements';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { selectUserAgreementsIndexedObj } from '@lib/core/users/selectors/user';
import {
  actionLogin,
  actionResetAuthSlice,
  facebookLoginCallback,
  googleLoginCallback,
  resetAuthError,
} from '@lib/core/users/slices/auth';
import RouteUtils from '@lib/tools/routes';
import { useRetailerDesignSet, useTypedSelector } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { LoginFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import LoginPage from '@components/web/src/templates/Login&Registration/LoginPage/LoginPage';

const LoginContainer: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const recaptchaRef: any = useRef();
  const [searchParams] = useSearchParams();
  const { isServiceInstanceFeatureEnabled } = useServiceInstance();
  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const { appAgreements, isCaptchaDisabled } = useTechnical();
  const { isUserAllowedUseThirdParty } = appAgreements;
  const { error, isLoading: isFetchingUser } = useTypedSelector(state => state.auth);

  const { isUserFullUserDataLoadedTemporaryHint, isFetchingUserData, isUserAuthenticated } = useUser();
  const isFromWidgetHomePage = location.state?.isFromWidgetHomePage;
  const isFromResultPage = location.state?.isFromResultPage;
  const isSocialAuthTesting = searchParams.get('is_social_test') === 'true';
  const [loginFormData, setLoginFormData] = useState({ email: '', password: '' });
  const [isEmailAuthorization, setIsEmailAuthorization] = useState(false);

  const [showTimer, setShowTimer] = useState(false);

  const { [`${hardcodedListOfAgreementsIDs[Agreements.isUserAllowedUseThirdParty]}`]: userThirdPartySelection } =
    useSelector(selectUserAgreementsIndexedObj);

  useEffect(() => {
    if (isUserFullUserDataLoadedTemporaryHint && !isFetchingUserData && isUserAuthenticated) {
      if (isServiceInstanceFeatureEnabled) {
        /** Service Instance navigation after form/social authenticating into the account and receiving all data */

        RouteUtils.navigateToWidgetServiceInstance(PAGES.vinhood.catalog);
        return;
      }

      if (isDesignSetVinhoodExperience) {
        navigate(prependBasename(userThirdPartySelection ? PAGES.vinhood.home : PAGES.vinhood.catalog));
      }
    }
    // dispatch(actionResetAuthSlice());
  }, [
    isDesignSetVinhoodExperience,
    isUserFullUserDataLoadedTemporaryHint,
    isFetchingUserData,
    isUserAllowedUseThirdParty,
    isUserAuthenticated,
  ]);

  useEffect(() => {
    if (error && !showTimer) {
      setShowTimer(true);
    }

    return () => setShowTimer(false);
  }, [error]);

  const handleFormSubmit = (data: LoginFormInputObject) => {
    const { email, password } = data;
    // form submitBtn click
    // sets state and executes the recaptcha
    setLoginFormData({ email, password });
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const handleLogin = async (captchaValue: string) => {
    // called after recaptcha successfully executes
    await dispatch(actionResetAuthSlice());
    if (!error) {
      await dispatch(
        actionLogin({
          captchaValue,
          email: loginFormData.email,
          isCaptchaDisabled,
          password: loginFormData.password,
        }),
      );
    } else {
      setShowTimer(true);
    }
  };

  const handleForgotPasswordClick = () => {
    navigate(prependBasename(PAGES.vinhood.resetPassword), { state: { ...location?.state } });
  };

  const handleGoBack = () => {
    if (isDesignSetVinhoodExperience) {
      navigate(prependBasename(isFromWidgetHomePage ? PAGES.vinhood.home : PAGES.vinhood.registration));
    } else if (isDesignSetVinhoodApp) {
      navigate(prependBasename(isFromResultPage ? PAGES.vinhood.quizResults.result : PAGES.vinhood.start));
    }
  };

  const handleEmailAuthButtonClick = () => {
    setIsEmailAuthorization(state => !state);
    dispatch(resetAuthError());
  };

  return (
    <>
      <LoginPage
        errors={error}
        handleEmailAuthButtonClick={handleEmailAuthButtonClick}
        handleForgotPasswordClick={handleForgotPasswordClick}
        handleFormSubmit={handleFormSubmit}
        handleGoBack={handleGoBack}
        handleLogin={handleLogin}
        isCaptchaDisabled={isCaptchaDisabled}
        isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
        isEmailAuthorization={isEmailAuthorization}
        isSocialAuthTesting={isSocialAuthTesting}
        recaptchaRef={recaptchaRef}
        responseApple={response => console.info(response)}
        responseFacebook={facebookLoginCallback(dispatch, false)}
        responseGoogle={googleLoginCallback(dispatch, false)}
        setShowTimer={setShowTimer}
        showTimer={showTimer}
      />
      {isFetchingUser && <Spinner />}
    </>
  );
};

export default LoginContainer;
