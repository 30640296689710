import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const DownloadAppCardWrapper = styled(Flexbox)`
  width: 100%;
  padding-inline: 18px;
  min-height: 80px;
  background: ${STATIC_COLORS.gray['50']};
  border-bottom: 0.5px solid ${STATIC_COLORS.gray['500']};
`;

export const VinhoodTextWrapper = styled(Flexbox)`
  min-width: 121px;
  flex-grow: 1;
`;

export const GradientText = styled(Text)`
  min-width: 108px;
  background: -webkit-${STATIC_GRADIENTS.primaryGradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
`;

export const LogoWrapper = styled(Flexbox)`
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  border-radius: 15px;
  background: ${STATIC_GRADIENTS.primaryGradient};
`;

export const CrossIconWrapper = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    stroke: ${STATIC_COLORS.gray['800']};
  }
`;
