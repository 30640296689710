import { useDispatch, useSelector } from 'react-redux';

import { selectLocationListData, selectLocationListLoading } from '@lib/core/users/selectors/locationList';
import { actionUpdateLocationList } from '@lib/core/users/slices/locationList';
import { IUpdateLocationListParams } from '@lib/core/users/types';

export const useLocationList = () => {
  const dispatch = useDispatch();

  const locationList = useSelector(selectLocationListData);
  const isLocationListLoading = useSelector(selectLocationListLoading);

  const handleUpdateLocationList = ({ retailerLocationSlug }: IUpdateLocationListParams) =>
    dispatch(actionUpdateLocationList({ retailerLocationSlug }));

  return { handleUpdateLocationList, isLocationListLoading, locationList };
};
