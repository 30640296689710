import { TProduct, TProductInstance, TProductLocationTag } from '@lib/core/products/types';
import { selectQuizViewAnswerTags } from '@lib/core/quizzes/selectors';
import { TQuizAnswerTag } from '@lib/core/quizzes/types';
import { store } from '@lib/core/service/store';
import {
  isQuizAnswerTagContextBestMatch,
  isQuizAnswerTagContextReferenceProduct,
} from '@lib/tools/shared/pmi/quizzes/filters';
import { CRAFTED, SENTIA } from '@lib/tools/shared/pmi/retailers/consts';

export const isProductDimensions = (gprl: TProductInstance): boolean =>
  gprl.product.attributes.is_dimensions === 'true';

export const isProductStandard = (gprl: TProductInstance): boolean => !isProductDimensions(gprl);

export const isProductSentia = (gprl: TProductInstance): boolean =>
  gprl.product.attributes.product_type === SENTIA && !isProductDimensions(gprl);

export const isProductLevia = (gprl: TProductInstance): boolean => gprl.product.attributes.product_type === 'levia';

export const isAssociatedTereaProduct = (gprl: TProductInstance, sentiaBestMatch: TProductInstance): boolean => {
  return gprl.product.identifier === sentiaBestMatch.product.attributes.associated_terea_identifier;
};

export const isProductCrafted = (gprl: TProductInstance): boolean => gprl.product.product_category === CRAFTED;

export const isGprlNotHidden = (gprl: TProductInstance): boolean =>
  !gprl.retailer_product_location_tags?.some(tag => tag.name === 'hidden');

export const isGprlReferenceProduct = (gprl: TProductInstance, tag: TQuizAnswerTag): boolean => {
  const referenceProductSlug = isQuizAnswerTagContextReferenceProduct(tag) ? tag.name : '';
  return gprl.product.slug === referenceProductSlug;
};

export const isGprlBestMatch = (gprl: TProductInstance): boolean => {
  const answerTags = selectQuizViewAnswerTags(store?.getState());
  return gprl.product.slug === answerTags.find(isQuizAnswerTagContextBestMatch)?.name;
};

export const isGprlSentiaTereaAssociation = (gprl: TProductInstance, sentiaBestMatch: TProductInstance): boolean => {
  return gprl.product.identifier === sentiaBestMatch?.product?.attributes?.sentia_terea_association;
};

export const isProductPearl = (product: TProduct) => product?.attributes.is_pearl === 'true';

export const isProductTagNicotineLevel = (tag: TProductLocationTag) => tag?.name.includes('veev-nicotine-');

export const isProductTagEuEssences = (tag: TProductLocationTag) => tag?.name === 'eu-essences';

export const isProductTagEuClassicTobacco = (tag: TProductLocationTag) => tag?.name === 'eu-classic-tobacco';

export const isProductTagHighMenthol = (tag: TProductLocationTag) => tag?.name === 'is_high_menthol';
