import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { LocaleUtils } from '@lib/tools/locale/utils';

export const RemoteAccessRow = (rowData: any) => {
  const { customId, link } = rowData?.row;

  return (
    <TableRow key={link}>
      <TableCell>{customId}</TableCell>
      <TableCell>{link}</TableCell>
    </TableRow>
  );
};

export const ScoreMatrixRow = ({
  row,
  rowIndex,
  editingCell,
  handleCellClick,
  handleSubmit,
  handleInputChange,
  isTyping,
  newInput,
  scoreColumns,
}) => (
  <TableRow key={rowIndex} hover tabIndex={-1}>
    {scoreColumns.map((column, columnIndex) => {
      const isEditing =
        editingCell &&
        editingCell.rowIndex === rowIndex &&
        editingCell.columnId === column.id &&
        !String(row[column.id]).startsWith('QQ') &&
        !String(row[column.id]).startsWith('QA');

      return (
        <TableCell key={columnIndex} align={column.align} onClick={() => handleCellClick(rowIndex, column.id)}>
          {isEditing ? (
            <Input
              style={{ color: 'black' }}
              value={isEditing && isTyping ? newInput : row[column.id]}
              onBlur={() => handleSubmit(row, column, columnIndex)}
              onChange={handleInputChange}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  handleSubmit(row, column, columnIndex);
                }
              }}
            />
          ) : (
            row[column.id]
          )}
        </TableCell>
      );
    })}
  </TableRow>
);

export const LoggingRow = ({ row }) => (
  <TableRow key={JSON.stringify(row)} className="log-item">
    <TableCell className="log-name">{row?.[0]}</TableCell>
    <TableCell className="log-name">{row?.[1]}</TableCell>
    <TableCell className="log-args">{row?.[2]}</TableCell>
  </TableRow>
);

export const LocaleRow = ({ row }) => {
  const textareaRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  const handleChange = e => {
    const newValue = e.target.value;
    setCursorPosition(e.target.selectionStart);
    LocaleUtils.updateTerm(row.context, newValue);
  };

  useEffect(() => {
    if (cursorPosition !== null && textareaRef.current) {
      textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [row.draftText, cursorPosition]);

  return (
    <TableRow key={row.context}>
      <TableCell>{row.context}</TableCell>
      <TableCell>{row.defaultText}</TableCell>
      <TableCell>
        <TextareaAutosize
          ref={textareaRef}
          className={row.draftText !== row.publishedText && row.draftText ? 'unpublished' : ''}
          id={row.context}
          placeholder="No translation"
          value={row.draftText}
          onChange={handleChange}
        />
      </TableCell>
    </TableRow>
  );
};

export const SnowballRow = props => {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const { history } = row;

  let answersListView = '';

  history.forEach((o, i) => {
    if (o.answer_text) {
      if (i) answersListView += ', ';
      answersListView += o.answer_text;
    }
  });

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={toggleOpen}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{answersListView}</TableCell>
        <TableCell align="right">{row.character}</TableCell>
        <TableCell align="right">{row.score}</TableCell>
      </TableRow>

      <TableRow
        sx={{
          color: '#ff9100',
        }}
      >
        <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse unmountOnExit in={open} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom component="div" variant="subtitle2">
                Traversed Path
              </Typography>
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Question ID</TableCell>
                    <TableCell>Question</TableCell>
                    <TableCell>Answer ID</TableCell>
                    <TableCell>Answer</TableCell>
                    <TableCell>Answer Tags</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map(historyRow => (
                    <TableRow key={historyRow.question_id}>
                      <TableCell scope="row">{historyRow.question_id}</TableCell>
                      <TableCell>{historyRow.question_text}</TableCell>
                      <TableCell align="right">{historyRow.answer_id}</TableCell>
                      <TableCell align="right">{historyRow.answer_text}</TableCell>
                      <TableCell>
                        {historyRow?.answer_tags?.map((answerTag, index) => (
                          <div key={index} style={{ marginBottom: '8px' }}>
                            <span style={{ display: 'inline-block', marginRight: '8px' }}>
                              <strong>Name:</strong> {answerTag.name}
                            </span>
                            <span style={{ display: 'inline-block' }}>
                              <strong>Context:</strong> {answerTag.context}
                            </span>
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
