import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import LoginContainer from '@lib/tools/shared/auths/views/containers/LoginContainer';
import RegistrationContainer from '@lib/tools/shared/auths/views/containers/RegistrationContainer';
import ResetPasswordContainer from '@lib/tools/shared/auths/views/containers/ResetPasswordContainer';
import ConfirmResetPasswordContainer from '@lib/tools/shared/auths/views/containers/SetPasswordContainer';
import LegalContainer from '@lib/tools/shared/legals/views/containers/LegalContainer';
import ProductPageContainer from '@lib/tools/shared/products/views/containers/ProductContainer';
import FoodHabitsContainer from '@lib/tools/shared/quizzes/views/containers/FoodHabitsContainer';
import QuizContainer from '@lib/tools/shared/quizzes/views/containers/QuizContainer';
import RecipesContainer from '@lib/tools/shared/recipes/views/containers/RecipeContainer';
import { PAGES } from '@lib/tools/views/urls';
import ErrorPageNotFound from '@lib/tools/views/web/components/ErrorPageNotFound';

import AgeAndGenderContainer from 'containers/AgeAndGenderContainer';
import TestResultPageContainer from 'containers/app/TestResultPageContainer';
import ExitContainer from 'containers/ExitContainer';
import { ExploreContainer } from 'containers/ExploreContainer';
import { ExploreListContainer } from 'containers/ExploreListContainer';
import ProductCatalogContainer from 'containers/ProductCatalogContainer';
import QuizChooseExpertiseContainer from 'containers/QuizChooseExpertiseContainer';
import RedirectContainer from 'containers/RedirectContainer';
import JournalPlacesContainer from 'containers/TasteId/Journal/JournalPlacesContainer';
import JournalProductsContainer from 'containers/TasteId/Journal/JournalProductsContainer';
import TasteIdLegalPageContainer from 'containers/TasteId/TasteIdLegalPageContainer';
import TasteIdPageContainer from 'containers/TasteId/TasteIdPageContainer';
import TasteIdSettingsPageContainer from 'containers/TasteId/TasteIdSettingsPageContainer';
import TasteIdTagsPageContainer from 'containers/TasteId/TasteIdTagsPageContainer';
import TastePathStartPageContainer from 'containers/TastePathStartPageContainer';
import HomeContainer from 'containers/widget/HomeContainer';
import {
  AuthRoutesAccess,
  CatalogRouteAccess,
  HomeRouteAccess,
  LegacyQuizRouteAccess,
  RedirectAnonymousAccess,
} from 'routes/AccessWeb';

const Router: FC = () => {
  const RouteGroup = (c, ...paths) => {
    return paths.map(p => <Route key={p} Component={c} path={p} />);
  };

  const retailerBasename = '/:lang/:retailer/loc/:location/p/:product';
  const retailerUrl = url => retailerBasename + url;

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={retailerBasename}>
          <Route Component={HomeRouteAccess}>
            <Route key="urlHome" Component={HomeContainer} path={retailerUrl(PAGES.vinhood.home)} />
          </Route>

          <Route
            key="urlTasteOrExpertSelection"
            Component={QuizChooseExpertiseContainer}
            path={retailerUrl(PAGES.vinhood.quiz.chooseExpert)}
          />

          <Route
            key="urlAgeAndGender"
            Component={AgeAndGenderContainer}
            path={retailerUrl(PAGES.vinhood.ageAndGender)}
          />

          <Route
            key="urlFoodHabits"
            Component={FoodHabitsContainer}
            path={retailerUrl(PAGES.vinhood.quiz.chooseTaste)}
          />

          {RouteGroup(QuizContainer, retailerUrl(`/quiz/:quizType/`))}
          {RouteGroup(TestResultPageContainer, retailerUrl(PAGES.vinhood.quizResults.result))}

          <Route key="urlRecipes" Component={RecipesContainer} path={retailerUrl(PAGES.vinhood.recipeSearch)} />

          <Route Component={CatalogRouteAccess}>
            <Route key="urlCatalog" Component={ProductCatalogContainer} path={retailerUrl(PAGES.vinhood.catalog)} />
          </Route>

          <Route key="urlTasteId" Component={TasteIdPageContainer} path={retailerUrl(PAGES.vinhood.tasteId.tasteId)} />

          <Route key="urlExplore" Component={ExploreContainer} path={retailerUrl(PAGES.vinhood.explore)} />

          <Route key="urlExploreList" Component={ExploreListContainer} path={retailerUrl(PAGES.vinhood.exploreList)} />

          <Route Component={RedirectAnonymousAccess}>
            <Route
              key="urlTasteIdProductsJournal"
              Component={JournalProductsContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdProductsJournal)}
            />
            <Route
              key="urlTasteIdPlacesJournal"
              Component={JournalPlacesContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdPlacesJournal)}
            />
            <Route
              key="urlTasteIdLegal"
              Component={TasteIdLegalPageContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdLegal)}
            />
            <Route
              key="urlTasteIdTags"
              Component={TasteIdTagsPageContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdTags)}
            />
            <Route
              key="urlTasteIdSettings"
              Component={TasteIdSettingsPageContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdSettings)}
            />
          </Route>

          <Route key="urlProduct" Component={ProductPageContainer} path={retailerUrl(PAGES.vinhood.product)} />

          {/* User */}
          <Route Component={AuthRoutesAccess}>
            <Route
              key="urlRegistration"
              Component={RegistrationContainer}
              path={retailerUrl(PAGES.vinhood.registration)}
            />

            <Route key="urlLogin" Component={LoginContainer} path={retailerUrl(PAGES.vinhood.signIn)} />

            <Route
              key="urlResetPassword"
              Component={ResetPasswordContainer}
              path={retailerUrl(PAGES.vinhood.resetPassword)}
            />

            <Route
              key="urlConfirmResetPassword"
              Component={ConfirmResetPasswordContainer}
              path={retailerUrl(PAGES.vinhood.confirmPasswordReset)}
            />
          </Route>

          <Route
            key="urlTastePathStartPart"
            Component={TastePathStartPageContainer}
            path={retailerUrl(PAGES.vinhood.tastePathStartPart)}
          />

          {/* Misc */}
          <Route key="urlLegal" Component={LegalContainer} path={retailerUrl(PAGES.vinhood.legal.tabs)} />
          <Route key="urlExit" Component={ExitContainer} path={retailerUrl(PAGES.vinhood.exit)} />
          <Route key="urlRedirect" Component={RedirectContainer} path={retailerUrl(PAGES.vinhood.redirect)} />

          <Route Component={LegacyQuizRouteAccess}>
            <Route key="urlErrorNotFound" Component={ErrorPageNotFound} path={retailerUrl('/*')} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
