import { TProductCategory } from '@lib/core/products/types';
import { RootState } from '@lib/core/service/types/appStateType';
import { selectKioskUserProductCategory, selectUserRole } from '@lib/core/users/selectors/user';
import { USER_ROLE_KIOSK } from '@lib/core/users/utils/consts';
import { languages } from '@lib/tools/locale/utils/consts';
import RouteUtils from '@lib/tools/routes';

/**
 * @returns The value of the isDevToolsEnabled property or the defaultValue.
 */
export const selectIsDevToolsEnabled = (state: RootState, defaultValue = false): boolean =>
  state.service.isDevToolsEnabled || defaultValue;

/**
 * @returns a boolean value to mock the hosting site and iframe behavior with a mock 1080x1920 wrapper
 */
export const selectIsHostMocked = (state: RootState, defaultValue = false): boolean =>
  state.service.isHostMocked || defaultValue;

/**
 * @returns the edit mode for translations. Enables additional behavior on `LocaleFragment`
 */
export const selectIsLocaleEditMode = (state: RootState): boolean => state.service.isLocaleEditMode;

/**
 * @returns the first product category of the retailer. Used as default.
 */
export const selectServiceProductCategory = (
  state: RootState,
  defaultValue = RouteUtils.getProductCategory(),
): TProductCategory => {
  const userRole = selectUserRole(state);

  if (userRole === USER_ROLE_KIOSK) return selectKioskUserProductCategory(state, defaultValue);
  return state?.service?.productCategory || (defaultValue as TProductCategory);
};

/**
 * @returns A boolean indicating whether the taste path start page should be displayed.
 */
export const selectShouldDisplayTastePathStartPage = (state: RootState, defaultValue = false): boolean =>
  state?.service?.shouldDisplayTastePathStartPage || defaultValue;

/**
 * @returns A boolean indicating whether the Taste Path tooltip modal should be displayed.
 */
export const selectShouldHideTastePathTooltipModal = (state: RootState, defaultValue = false): boolean =>
  state?.service?.shouldHideTastePathTooltipModal || defaultValue;

export const selectAppRoute = (state: RootState, defaultValue = ''): string => state?.service?.route || defaultValue;

export const selectAppPreviousRoute = (state: RootState, defaultValue = ''): string =>
  state?.service?.previousRoute || defaultValue;

/**
 * @returns the current language of the application
 */
export const selectLocale = (state: RootState, defaultValue = languages.ENGLISH): string =>
  state?.service?.lang || defaultValue;

/**
 * @returns A boolean indicating whether b2c app SplashLoading screen is shown.
 */

export const selectIsSplashScreenPageShown = (state: RootState, defaultValue = false): boolean =>
  state?.service?.isSplashScreenPageShown || defaultValue;

/**
 * @returns A boolean indicating whether Guide to Taste Id Page on Widget is shown.
 */

export const selectIsTasteIdGuideShown = (state: RootState, defaultValue = false): boolean =>
  state?.service?.isTasteIdGuideShown || defaultValue;

/**
 * @returns A boolean indicating whether Download App Card is to be hidden.
 */

export const selectShouldHideDownloadAppCard = (state: RootState, defaultValue = false): boolean =>
  state?.service?.shouldHideDownloadAppCard || defaultValue;

/**
 * @returns A boolean indicating whether Login Prompt on Widget is hidden.
 */

export const selectShouldHideLoginPrompt = (state: RootState, defaultValue = false): boolean =>
  state?.service?.shouldHideLoginPrompt || defaultValue;

/**
 * @returns A string for fidelityId received when being redirected to mobile kiosk.
 */

export const selectFidelityCardIdFromKiosk = (state: RootState, defaultValue = ''): string =>
  state?.service?.fidelityCardIdFromKiosk || defaultValue;
