import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { fetchExperiencesLists } from '@lib/core/experiences/slices/experienceCatalogSlice';
import { TProductInstance } from '@lib/core/products/types';
import { getLimitedProductsListData, getProductsListData } from '@lib/core/products/utils';
import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { retailerLocationDataApiUrlCreator } from '@lib/core/retailers/slices/urls';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import request from '@lib/core/service/requests/request';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import { useFeedback, useLocationList, useUser, useWishlist } from '@lib/core/users/hooks';
import { isLocationInWishlistFilter } from '@lib/core/users/utils/filters';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { parseExploreLocation } from '@lib/tools/explore/utils/parseExploreLocation';
import {
  LIMITED_CATALOG,
  PRODUCT_CATEGORY_QUERY,
  RETAILER_LOCATION_ID_URL_PARAM,
  RETAILER_SLUG_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import GenericSpinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { IMapLocation } from '@components/web/src/organisms/Cards/MapCard/MapCard';
import { ExplorePage } from '@components/web/src/templates/ExplorePage/ExplorePage';

export const ExploreContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [exploreLocationData, setExploreLocationData] = useState(null);
  const [isExploreLocationDataLoading, setIstExploreLocationDataLoading] = useState(false);

  const { productCategory, locale } = useApp();
  const { retailerSlug: defaultRetailerSlug, isRetailerLoading } = useRetailer();
  const { retailerLocationId: defaultRetailerLocationId } = useRetailerLocation();
  const { isUserAuthenticated, userCharacters, isFetchingUserData, isUserHasAnyCharacter } = useUser();
  const userCharacterIdentifier = userCharacters.find(character =>
    isCharacterByServiceProductCategory(character),
  )?.identifier;
  const { discoveryQuiz } = useDiscoveryQuizData();
  const { wishlistProductIds, isProductListLoading, handleUpdateWishlistProductList } = useWishlist();
  const { feedbackData, handleUpdateFeedback } = useFeedback();
  const { locationList, isLocationListLoading, handleUpdateLocationList } = useLocationList();

  const retailerLocationIdUrlParam = searchParams.get(RETAILER_LOCATION_ID_URL_PARAM);
  const retailerSlugUrlParam = searchParams.get(RETAILER_SLUG_URL_PARAM);

  const retailerLocationId = retailerLocationIdUrlParam || defaultRetailerLocationId;
  const retailerSlug = retailerSlugUrlParam || defaultRetailerSlug;

  const isCurrentRetailer =
    (!retailerLocationIdUrlParam && !retailerSlugUrlParam) || retailerLocationIdUrlParam === defaultRetailerLocationId;

  const {
    exploreLocationStoreType,
    exploreLocationLogo,
    exploreLocationName,
    exploreLocationId,
    exploreLocationSlug,
    exploreLocationWebsite,
    exploreLocationPhone,
    exploreLocationEmail,
    exploreLocationDescription,
    exploreLocationImages,
    exploreLocationAddress,
    exploreLocationCoordinates,
    exploreLocationProducerId,
    exploreLocationTasteMatch,
    exploreLocationCatalogType,
    associatedRetailerLocation,
  } = parseExploreLocation(exploreLocationData);

  const mapLocationList: IMapLocation[] = useMemo(
    () =>
      [
        exploreLocationCoordinates && {
          address: exploreLocationAddress,
          coordinates: exploreLocationCoordinates,
          isCurrentLocation: true,
        },
      ].filter(Boolean),
    [exploreLocationCoordinates],
  );

  const exploreLocationTasteMatchValue =
    exploreLocationTasteMatch && typeof exploreLocationTasteMatch[productCategory] === 'number'
      ? exploreLocationTasteMatch[productCategory]
      : null;

  const {
    experiencesData: { results: experienceData },
    isExperienceRequestLoading,
  } = useTypedSelector(state => state.experienceCatalog);

  const randomLimitedExperienceData = useMemo(
    () => getMultipleUniqueRandomItemsFromArray(experienceData, 3),
    [experienceData],
  );

  const randomExploreLocationImage = useMemo(
    () => getMultipleUniqueRandomItemsFromArray(exploreLocationImages, 1)[0],
    [exploreLocationImages],
  );

  const [productInstanceData, setProductInstanceData] = useState<TProductInstance | null>(null);
  const [isProductInstanceDataLoading, setIsProductInstanceDataLoading] = useState(false);

  useEffect(() => {
    if (exploreLocationData) {
      MixpanelTracker.events.exploreSectionView({
        locationId: exploreLocationId,
        locationName: exploreLocationName,
        locationSlug: exploreLocationSlug,
        tasteMatchLevel: exploreLocationTasteMatchValue,
      });
    }
  }, [exploreLocationData]);

  useEffect(() => {
    if (!exploreLocationData && !isExploreLocationDataLoading) {
      const getExploreLocationData = async () => {
        setIstExploreLocationDataLoading(true);
        const response = await request(retailerLocationDataApiUrlCreator({ retailerLocationId, retailerSlug }));

        setExploreLocationData(response);
        setIstExploreLocationDataLoading(false);
      };

      if (!exploreLocationData) {
        getExploreLocationData();
      }
    }
  }, [exploreLocationData]);

  useEffect(() => {
    const fetchProductData = async () => {
      if (productInstanceData) return;

      setIsProductInstanceDataLoading(true);

      const isLimitedCatalogType = exploreLocationCatalogType === LIMITED_CATALOG;

      const params = {
        [GPRL_CHARACTER_QUERY]: userCharacterIdentifier,
        [PRODUCT_CATEGORY_QUERY]: productCategory,
        ...(isLimitedCatalogType && { [ASSOCIATED_RETAILER_SLUG_QUERY]: associatedRetailerLocation?.slug }),
        limit: 1,
        offset: 0,
      };

      if (isLimitedCatalogType) {
        const res = await getLimitedProductsListData(params);
        const productData = res.first_priority?.[0] || res.second_priority?.[0] || res.third_priority?.[0];
        setProductInstanceData(productData);
      } else {
        const res = await getProductsListData(params);
        const productData = res.results[0];
        setProductInstanceData(productData);
      }

      setIsProductInstanceDataLoading(false);
    };

    if (isCurrentRetailer) {
      fetchProductData();
    }
  }, [isCharacterByServiceProductCategory]);

  useEffect(() => {
    if (!isExperienceRequestLoading && exploreLocationProducerId && isCurrentRetailer) {
      dispatch(fetchExperiencesLists({ producer: exploreLocationProducerId, productCategory }));
    }
  }, [productCategory, exploreLocationProducerId]);

  const isLoading =
    isProductInstanceDataLoading ||
    isFetchingUserData ||
    isExperienceRequestLoading ||
    isExploreLocationDataLoading ||
    isRetailerLoading;

  const navigateToCatalogPage = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const navigateToExploreListPage = () => navigate(prependBasename(PAGES.vinhood.exploreList));

  return (
    <>
      {isLoading ? (
        <GenericSpinner />
      ) : (
        <ExplorePage
          discoveryQuiz={discoveryQuiz}
          experienceData={randomLimitedExperienceData}
          feedbackData={feedbackData}
          handleUpdateFeedback={handleUpdateFeedback}
          handleUpdateLocationList={handleUpdateLocationList}
          handleUpdateWishlistProductList={handleUpdateWishlistProductList}
          isCompactVariant={!isCurrentRetailer}
          isLocationInWishlist={isLocationInWishlistFilter(locationList, exploreLocationId)}
          isLocationListLoading={isLocationListLoading}
          isProductListLoading={isProductListLoading}
          isUserAuthenticated={isUserAuthenticated}
          isUserHasAnyCharacter={isUserHasAnyCharacter}
          locale={locale}
          mapLocationList={mapLocationList}
          navigateToCatalogPage={navigateToCatalogPage}
          navigateToExploreListPage={navigateToExploreListPage}
          navigateToQuizSelectionPage={navigateToQuizSelectionPage}
          productCategory={productCategory}
          productInstanceData={productInstanceData}
          retailerLocationAddress={exploreLocationAddress}
          retailerLocationDescription={exploreLocationDescription}
          retailerLocationEmail={exploreLocationEmail}
          retailerLocationId={exploreLocationId}
          retailerLocationImage={randomExploreLocationImage}
          retailerLocationLogo={exploreLocationLogo}
          retailerLocationName={exploreLocationName}
          retailerLocationPhone={exploreLocationPhone}
          retailerLocationSlug={exploreLocationSlug}
          retailerLocationTasteMatchValue={exploreLocationTasteMatchValue}
          retailerLocationWebsite={exploreLocationWebsite}
          storeType={exploreLocationStoreType}
          wishlistProductIds={wishlistProductIds}
        />
      )}
    </>
  );
};
