/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getHeaderAppLogoUrl } from '@app/native/src/helpers/headerApp';
import SocialRegistrationPromptContainer from '@app/web/src/containers/SocialRegistrationPromptContainer';

import { catalogDownloadAppBanner } from '@lib/core/banners/hooks';
import { isCharacterByProductCategory, isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { GPRL_CHARACTER_QUERY, GPRL_PROMOTIONS_QUERY, isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { useProductPreferences } from '@lib/core/service/hooks/useProductPreferences';
import { useScrollToTop } from '@lib/core/service/hooks/useScrollToTop';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import {
  setIsTasteIdGuideShown,
  setShouldHideDownloadAppCard,
  setSplashScreenPageShown,
} from '@lib/core/service/slices';
import { fetchPromotionProducts, resetPromotionProducts } from '@lib/core/service/slices/promotionProductsSlice';
import { prependBasename } from '@lib/core/service/utils';
import { useFeedback, useUser, useWishlist } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { USER_ROLE_ANONYMOUS } from '@lib/core/users/utils/consts';
import { isAppInIframe } from '@lib/tools/comms/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { useModals } from '@lib/tools/modals/hooks';
import RouteUtils from '@lib/tools/routes';
import {
  LANGUAGE_DETECTION_METHOD,
  MODALS,
  PRODUCT_CATEGORY_QUERY,
  PROMOTION_LABEL_SLUG,
} from '@lib/tools/shared/helpers/consts';
import { useToastMessage } from '@lib/tools/toastMessage/hooks';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { HeaderAppProps } from '@components/web/src/app/HeaderApp/HeaderApp';
import { IFooterAppLoggedProps } from '@components/web/src/atoms/Footers/FooterAppLogged/FooterAppLogged';
import { HeaderFooterApp, HeaderFooterKiosk, HeaderFooterWidget } from '@components/web/src/atoms/HeaderFooter';
import RootElement from '@components/web/src/atoms/RootElement/rootElement';

type ISendHocProps = {
  footerProps: IFooterAppLoggedProps & { showGearIcon?: boolean; shouldHideFooter?: boolean };
  headerProps: HeaderAppProps;
  isAuthenticated: boolean;
  isAuthFooter?: boolean;
  isSplashScreenPageShown: boolean;
};

// ? Separate view (header/footer) logic and api-store logic
const HocWrapper = ({
  children,
  isHeaderShown = true,
  showSearch = true,
  showNotification = false,
  showGearIcon = true,
}) => {
  useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { retailerTags, retailerLanguages, isAnonymousRetailerAccess } = useRetailer();
  const { retailerLocationProducerId } = useRetailerLocation();
  const { handleResetKioskUserSession } = useKioskRoot();
  const { isCookieModalOpened, openModal } = useModals();
  const { isDisableHomeAddon, isExternalCharacterPage, isEnableAppBanner } = useAddons();

  const {
    userImage,
    userRole,
    isUserKioskAdmin,
    isUserAuthenticated,
    userCharacters,
    isUserHasCharacterForCurrentPC,
    isUserHasAnyCharacter,
  } = useUser();

  const { toastMessages, handleClearToastMessage } = useToastMessage();

  const currentUserCharacterId =
    userCharacters.find(character => isCharacterByServiceProductCategory(character))?.identifier || '';
  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const {
    productCategory,
    isSplashScreenPageShown,
    locale,
    isTasteIdGuideShown,
    shouldHideDownloadAppCard,
    shouldHideLoginPrompt,
  } = useApp();
  const { fidelityCardId } = useFidelityCard();
  const { isServiceInstanceFeatureEnabled, serviceInstanceProductCategory } = useServiceInstance();
  const { appAgreements } = useTechnical();
  const { isUserAllowedUseLocalStorage } = appAgreements;

  const isHomePage = RouteUtils.getPage().includes(PAGES.vinhood.home);
  const isWelcomePage = RouteUtils.getPage().includes(PAGES.vinhood.welcome);
  const isTestResultPage = RouteUtils.getPage().includes(PAGES.vinhood.quizResults.result);
  const isRedirectPage = RouteUtils.getPage().includes(PAGES.vinhood.redirect);
  const isWidgetExpertQuizSelection = RouteUtils.getPage().includes(PAGES.vinhood.quiz.chooseExpert);
  const isCatalogPage = RouteUtils.getPage().includes(PAGES.vinhood.catalog);
  const isProductPage = RouteUtils.getPage().includes(PAGES.vinhood.product);
  const isJournalPage = [PAGES.vinhood.tasteId.tasteIdProductsJournal, PAGES.vinhood.tasteId.tasteIdPlacesJournal].some(
    page => RouteUtils.getPage().includes(page),
  );

  useProductPreferences({ isForDataControl: true });

  const isNaLanguageDetectionMethod = retailerTags?.languageDetectionMethod === LANGUAGE_DETECTION_METHOD.na;
  const [isShowLanguageSwitcher, setShowLanguageSwitcher] = useState(false);

  const { feedbackData, isFeedbackLoading } = useFeedback();
  // TODO: remove useWishlist and useProductList after full start using useProductList
  const { wishlistData, isProductListLoading } = useWishlist();

  useEffect(() => {
    if (isApplicationKiosk) setShowLanguageSwitcher(isHomePage);

    /** For widget only in iframe, we detect and set the language automatically in vinhoodExperience library
     * using detectLanguage function. If the method is not available (na) we display the language switcher
     * on the widget */
    if (isDesignSetVinhoodExperience)
      setShowLanguageSwitcher(isAppInIframe ? isHomePage && isNaLanguageDetectionMethod : isHomePage);
  }, [isApplicationKiosk, isDesignSetVinhoodExperience, isHomePage, isNaLanguageDetectionMethod]);

  useEffect(() => {
    /** If Service Instance (SI) feature is enabled:
     * Anon user:
     * - with character for SI PC navigate to App Registration page
     * - without character for SI PC navigate to App Start page
     * Auth user:
     * - with character for SI PC navigate to Widget Policy Acceptance page
     * - without character for SI PC navigate to Widget Home page
     */

    if (!isServiceInstanceFeatureEnabled) return;

    const userHasCharacterForServiceInstanceProductCategory = userCharacters.some(character =>
      isCharacterByProductCategory(character, serviceInstanceProductCategory),
    );

    if (!isUserAuthenticated && userHasCharacterForServiceInstanceProductCategory) {
      navigate(prependBasename(PAGES.vinhood.registration));
    } else if (isUserAuthenticated) {
      RouteUtils.navigateToWidgetServiceInstance(
        userHasCharacterForServiceInstanceProductCategory ? PAGES.vinhood.catalog : PAGES.vinhood.home,
      );
    }
  }, [isServiceInstanceFeatureEnabled]);

  useEffect(() => {
    if (isApplicationKiosk && fidelityCardId) {
      /** For kiosk users with fidelity cards:
       * Changing loyalty card updates data
       * Changing user character checks availability of promotional products
       * Feature only works if promotional products are available for user's current character */
      dispatch(resetPromotionProducts());

      if (currentUserCharacterId) {
        dispatch(
          fetchPromotionProducts({
            [GPRL_CHARACTER_QUERY]: currentUserCharacterId,
            [GPRL_PROMOTIONS_QUERY]: PROMOTION_LABEL_SLUG,
            [PRODUCT_CATEGORY_QUERY]: productCategory,
            offset: 0,
          }),
        );
      }
    }
  }, [fidelityCardId, currentUserCharacterId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setSplashScreenPageShown());
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const shouldShowTasteIdGuide =
    isUserAuthenticated &&
    !isTasteIdGuideShown &&
    [...feedbackData, ...wishlistData].length > 0 &&
    (!isProductListLoading || !isFeedbackLoading);

  const handleSetTasteIdPageShown = () =>
    [...feedbackData, ...wishlistData].length > 0 &&
    (!isProductListLoading || !isFeedbackLoading) &&
    dispatch(setIsTasteIdGuideShown(true));

  useEffect(() => {
    let timer;
    if (shouldShowTasteIdGuide) {
      timer = setTimeout(handleSetTasteIdPageShown, 10000);
    }
    return () => clearTimeout(timer);
  }, [shouldShowTasteIdGuide]);

  if (isDesignSetVinhoodApp) {
    const onHeaderIconClick = () => navigate(getHeaderAppLogoUrl());

    const showLanguageSwitcher = !isTestResultPage && userRole === USER_ROLE_ANONYMOUS;

    const sendHocProps: ISendHocProps = {
      footerProps: {
        isFooterDisabled: isDesignSetVinhoodApp ? !isUserHasAnyCharacter : !isUserHasCharacterForCurrentPC,
        profileImageUrl: userImage,
        shouldHideFooter: (isDesignSetVinhoodApp && isTestResultPage && !isUserAuthenticated) || isJournalPage,
        showGearIcon,
      },
      headerProps: {
        // check role and refactor again later if needed
        isHeaderShown: isHeaderShown && !isJournalPage,
        is_notification_enabled: !!userRole && showNotification,
        is_search_enabled: !!userRole && showSearch,
        is_show_language: showLanguageSwitcher,
        locale,
        onHeaderIconClick,
        retailerLanguages,
      },
      isAuthFooter: isUserAuthenticated && !isJournalPage,
      isAuthenticated: !!userRole,
      isSplashScreenPageShown,
    };

    return (
      <RootElement>
        <HeaderFooterApp shouldOpenModalsUnderHeader={isCookieModalOpened && showLanguageSwitcher} {...sendHocProps}>
          {children}
        </HeaderFooterApp>
      </RootElement>
    );
  }

  if (isDesignSetVinhoodExperience) {
    const isStartPageWithUnconfirmedLegal =
      !isUserAllowedUseLocalStorage && isAppInIframe && (isHomePage || isWidgetExpertQuizSelection);
    const isNavigateUserToTest = isDisableHomeAddon && isUserAuthenticated && isCatalogPage;
    const isEnableRedirectionToApp = isUserAuthenticated || isUserHasCharacterForCurrentPC || isCatalogPage;
    const isShowServiceInstanceContainer = !isAppInIframe && retailerTags?.headerLogo;

    const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner && isCatalogPage;

    const handleHideDownloadAppClick = () => dispatch(setShouldHideDownloadAppCard());

    const handleDownloadAppClick = () => {
      const { identifier, text, link, positions } = catalogDownloadAppBanner;
      MixpanelTracker.events.bannerClick(identifier, text, link, positions[0]);
      openModal(MODALS.DOWNLOAD_APP_MODAL);
    };

    const isRegistrationPromptShown =
      !shouldHideLoginPrompt && !isUserAuthenticated && (isCatalogPage || isProductPage);

    return isRedirectPage ? (
      <>{children}</>
    ) : (
      <RootElement>
        <HeaderFooterWidget
          handleClearToastMessage={handleClearToastMessage}
          handleDownloadAppClick={handleDownloadAppClick}
          handleHideDownloadAppClick={handleHideDownloadAppClick}
          handleSetTasteIdPageShown={handleSetTasteIdPageShown}
          isAnonymousWidgetAccess={isAnonymousRetailerAccess}
          isDarkTheme={isStartPageWithUnconfirmedLegal}
          isEnableRedirectionToApp={isEnableRedirectionToApp}
          isFixedBody={isJournalPage}
          isHideGearIcon={isAppInIframe}
          isNavigateUserToTest={isNavigateUserToTest}
          isShowLanguageSwitcher={isShowLanguageSwitcher}
          isShowServiceInstanceContainer={isShowServiceInstanceContainer}
          isUserAuthenticated={isUserAuthenticated}
          locale={locale}
          profileImageUrl={userImage}
          retailerLanguages={retailerLanguages}
          shouldHideCatalogLink={isExternalCharacterPage}
          shouldHideExploreLink={!retailerLocationProducerId}
          shouldHideHomeLink={isDisableHomeAddon}
          shouldHideTasteIdLink={!isUserHasCharacterForCurrentPC}
          shouldShowDownloadAppCard={shouldShowDownloadAppCard}
          shouldShowTasteIdGuide={shouldShowTasteIdGuide}
          toastMessages={toastMessages}
        >
          {isRegistrationPromptShown && <SocialRegistrationPromptContainer />}
          {children}
        </HeaderFooterWidget>
      </RootElement>
    );
  }

  if (isApplicationKiosk) {
    return isWelcomePage || !isUserKioskAdmin ? (
      <>{children}</>
    ) : (
      <RootElement>
        <HeaderFooterKiosk
          isKioskHomePage={isHomePage && isApplicationKiosk}
          isShowDetachFidelityCard={!!fidelityCardId}
          isShowLanguageSwitcher={isShowLanguageSwitcher}
          locale={locale}
          retailerLanguages={retailerLanguages}
          onDetachFidelityCardClick={() => handleResetKioskUserSession({ showFidelityMessage: true })}
        >
          {children}
        </HeaderFooterKiosk>
      </RootElement>
    );
  }

  return <>{children}</>;
};

export default HocWrapper;
