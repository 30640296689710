import { useEffect, useMemo, useState } from 'react';

import { useCharacters } from '@lib/core/characters/hooks';
import { TCharacter } from '@lib/core/characters/types';
import { charactersByProductCategory } from '@lib/core/characters/utils';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { parseCharacterInfo } from '@lib/core/characters/utils/parseCharacterInfo';
import { TProductInstance } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { shuffleArray } from '@lib/core/service/utils';
import { useFeedback, useLocationList, useUser, useWishlist } from '@lib/core/users/hooks';
import { IFeedbackData } from '@lib/core/users/slices/feedbacks';
import { ILocation } from '@lib/core/users/types';
import { parseCharacterAdjectiveData } from '@lib/tools/characterAdjective';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { USER_NO_TAGS_PREFERENCES_DATA } from '@lib/tools/shared/helpers/consts';
import { useAddons, useRetailerDesignSet, useTypedSelector } from '@lib/tools/views/hooks';

import { ICharacterInfoCardProps } from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';
import { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import { ICharacterAdjectiveData } from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';

const useTasteIdData = () => {
  const [isProductsByCategoryLoading, setProductsByCategoryLoading] = useState(false);

  const { personality, typeOf } = localeCommon.tasteId;
  const { characters } = useCharacters();
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { userCharactersIds, isFetchingUserData, userCharacters } = useUser();
  const userCharactersIndexedByProductCategory = charactersByProductCategory(userCharacters);
  const { locale, productCategory } = useApp();
  const { retailerLocationProductCategories } = useRetailerLocation();
  const { isTasteIdResultPageAddon } = useAddons();
  const { feedbackData, isFeedbackLoading } = useFeedback();
  const { wishlistData, isProductListLoading } = useWishlist();
  const { locationList, isLocationListLoading } = useLocationList();

  const tagsPreferencesState = useTypedSelector(state => state.productPreferences.tagsPreferencesData);

  const userCategories = retailerLocationProductCategories.filter(
    category => category in userCharactersIndexedByProductCategory,
  );
  const productCategories = !isDesignSetVinhoodApp || isTasteIdResultPageAddon ? [productCategory] : userCategories;

  const locationsByCategoryData: Record<string, ILocation[]> = useMemo(() => {
    if (!locationList.length) return {};

    return productCategories.reduce(
      (acc, category) => {
        acc[category] = locationList.filter(location =>
          location.retailer_location.product_categories.some(pc => pc.name === category),
        );
        return acc;
      },
      {} as Record<string, ILocation[]>,
    );
  }, [locationList]);

  const transformFeedbackData = (feedback: IFeedbackData[]) => feedback.map(item => item.gprl);

  const ratedProductsData = useMemo(() => {
    const transformedFeedback = transformFeedbackData(feedbackData);
    return [...wishlistData, ...transformedFeedback];
  }, [feedbackData, wishlistData]);

  const productsByCategoryData = useMemo(() => {
    const categorizedProducts = ratedProductsData.reduce(
      (acc, item) => {
        const pc = item?.product?.product_category;

        if (!acc[pc]) acc[pc] = [];
        acc[pc].push(item);
        return acc;
      },
      {} as { [key: string]: TProductInstance[] },
    );

    const limitedCategorizedProducts = Object.keys(categorizedProducts).reduce(
      (acc, category) => {
        const shuffledItems = shuffleArray(categorizedProducts[category]);
        const uniqueItems = [];

        shuffledItems.forEach(item => {
          if (
            !uniqueItems.some(existingItem => existingItem.identifier === item.identifier) &&
            uniqueItems.length < 4
          ) {
            uniqueItems.push(item);
          }
        });

        acc[category] = uniqueItems;
        return acc;
      },
      {} as { [key: string]: TProductInstance[] },
    );

    return limitedCategorizedProducts;
  }, [isProductsByCategoryLoading]);

  useEffect(() => {
    if ((feedbackData.length > 0 || wishlistData.length > 0) && Object.keys(productsByCategoryData).length === 0) {
      setProductsByCategoryLoading(true);
    } else {
      setProductsByCategoryLoading(false);
    }
  }, [feedbackData, wishlistData, productsByCategoryData]);

  const translatedUserCharacters: Record<string, TCharacter> = useMemo(() => {
    if (!characters.length || !userCharactersIds.length || !locale) return null;

    const filteredCharacters = characters.filter(character => isCharacterByIdentifiers(character, userCharactersIds));

    return productCategories.reduce((acc, category) => {
      acc[category] = filteredCharacters.find(character => character.product_category === category);
      return acc;
    }, {});
  }, [characters, userCharactersIds, locale]);

  const characterAdjectiveData: Record<string, ICharacterAdjectiveData> = useMemo(
    () => parseCharacterAdjectiveData(translatedUserCharacters, locale, productCategories),
    [translatedUserCharacters],
  );

  const characterInfoCardsData: Record<string, ICharacterInfoCardProps[]> = useMemo(() => {
    if (!translatedUserCharacters || !locale) return null;

    return productCategories.reduce((acc, category) => {
      acc[category] = parseCharacterInfo({
        characterData: translatedUserCharacters[category],
        locale,
        productCategory: category,
      });
      return acc;
    }, {});
  }, [translatedUserCharacters]);

  const characterDetailCardsData: Record<string, IReadMoreCardProps[]> = useMemo(() => {
    if (!translatedUserCharacters || !locale) return null;

    return productCategories.reduce((acc, category) => {
      const character = translatedUserCharacters[category];
      acc[category] = [
        {
          cardTitle: personality,
          description: character?.character_description,
          productCategory: character?.product_category,
          title: character?.character_caption.replace(/ and /g, ' & '),
        },
        {
          cardTitle: typeOf,
          description: character?.technical_description,
          minimumCharacters: 135,
          productCategory: character?.product_category,
          title: character?.technical_caption,
        },
      ];
      return acc;
    }, {});
  }, [translatedUserCharacters, locale]);

  const userPreferences = useMemo(() => {
    const allPreferencesValues = Object.values(tagsPreferencesState);
    if (allPreferencesValues.length > 0) {
      return allPreferencesValues?.filter(
        preference => preference.slug !== USER_NO_TAGS_PREFERENCES_DATA && preference.isEnabled,
      );
    }
    return [];
  }, [tagsPreferencesState]);

  const isTasteIdDataLoading =
    !translatedUserCharacters ||
    !characterAdjectiveData ||
    !characterDetailCardsData ||
    !characterInfoCardsData ||
    isProductsByCategoryLoading ||
    isFetchingUserData ||
    isFeedbackLoading ||
    isProductListLoading ||
    isLocationListLoading;

  return {
    characterAdjectiveData,
    characterDetailCardsData,
    characterInfoCardsData,
    isTasteIdDataLoading,
    locationsByCategoryData,
    productCategories,
    productsByCategoryData,
    ratedProductsData,
    translatedUserCharacters,
    userPreferences,
  };
};

export default useTasteIdData;
