/* eslint-disable sort-keys-fix/sort-keys-fix */
import { defineMessages } from '@formatjs/intl';

export const localeApp = {
  homePage: defineMessages({
    locationTitleText: {
      defaultMessage: 'Are you in a location plugged with Vinhood?',
      id: 'vinhood.b2c.myhome.locationTitleText',
    },
    locationSubtitleText: {
      defaultMessage: `Activate the location's product catalog.`,
      id: 'vinhood.b2c.myhome.locationSubtitleText',
    },
    locationScanCodeText: {
      defaultMessage: 'Scan Vinhood QR Code ',
      id: 'vinhood.b2c.myhome.locationScanCodeText',
    },
    articleSwiperText: {
      defaultMessage: 'Breakthrough of the day',
      id: 'vinhood.b2c.myhome.ArticleSwiperText',
    },
    articleBtn: {
      defaultMessage: 'Read all articles',
      id: 'vinhood.b2c.myhome.articleBtnText',
    },
    characterCardSituationalSubtitleText: {
      defaultMessage:
        'Looking for advice? ||Discover the perfect solution for a **gift**, a **recipe**, or a **special occasion**!',
      id: 'vinhood.b2c.myhome.characterCardSituationalSubtitleText',
    },
    characterCardSituationalText: {
      defaultMessage: 'A product for every occasion',
      id: 'vinhood.b2c.myhome.characterCardSituationalText',
    },
    edutainmentSectionBanner: {
      defaultMessage: `Let's start!`,
      id: 'vinhood.b2c.myhome.edutainmentSectionBanner',
    },
    vinhoodAcademySubtitle: {
      defaultMessage: `Discover your taste and much more thanks to **Vinhood's guided taste trails.**`,
      id: 'vinhood.b2c.myhome.vinhoodAcademySubtitle',
    },
    vinhoodAcademyBtn: {
      defaultMessage: `Taste Trials`,
      id: 'vinhood.b2c.myhome.vinhoodAcademyBtn',
    },
    vinhoodAcademyTitle: {
      defaultMessage: `Vinhood Academy`,
      id: 'vinhood.b2c.myhome.vinhoodAcademyTitle',
    },
    productSectionDescription: {
      defaultMessage: `They are not superheroes, but **they are super for your Taste.** ||Explore each day's products we have selected for you and your journey through the world of Taste.`,
      id: 'vinhood.b2c.myhome.productSectionDescription',
    },
    productSectionTitle: {
      defaultMessage: `The unmissables of the day`,
      id: 'vinhood.b2c.myhome.productSectionTitle',
    },
  }),
  seeLaterCardComponent: defineMessages({
    titleText: {
      defaultMessage: 'Ehi!',
      id: 'vinhood.b2c.seeLaterCard.titleText',
    },
    subtitleText: {
      defaultMessage: 'See you tomorrow, huh!',
      id: 'vinhood.b2c.seeLaterCard.subtitleText',
    },
    descriptionText: {
      defaultMessage: 'You will find new **products** and a thousand **surprises!**',
      id: 'vinhood.b2c.seeLaterCard.seeLaterDescriptionText',
    },
  }),
  accessModal: defineMessages({
    btnText: {
      defaultMessage: "Let's go",
      id: 'vinhood.b2c.accessModal.btnText',
    },
    descriptionText1: {
      defaultMessage: 'And that day is today! Your Taste Identity is ready: make good use of it.',
      id: 'vinhood.b2c.accessModal.descriptionText1',
    },
    descriptionText2: {
      defaultMessage:
        'Now you can explore a whole new world of products, recommendations, and taste experiences that are right for you.',
      id: 'vinhood.b2c.accessModal.descriptionText2',
    },
    descriptionText3: {
      defaultMessage: 'All you have to do is remember to open the app when you need it.',
      id: 'vinhood.b2c.accessModal.descriptionText3',
    },
    headingText: {
      defaultMessage: 'One day all of this will be yours',
      id: 'vinhood.b2c.accessModal.headingText',
    },
  }),
  articleCard: defineMessages({
    ctaBtn: {
      defaultMessage: 'Give it a peek!',
      id: 'vinhood.b2c.articleCard.ctaBtn',
    },
    notLoggedCtaBtnText: {
      defaultMessage: 'Register to unlock this content',
      id: 'vinhood.b2c.articleCard.notLoggedCtaBtnText',
    },
  }),
  articleCatalog: defineMessages({
    showMeMore: {
      defaultMessage: 'Show me more',
      id: 'vinhood.b2c.articleCatalog.showMeMore',
    },
    titleHeading: {
      defaultMessage: 'What You Could Read',
      id: 'vinhood.b2c.articleCatalog.titleHeading',
    },
  }),
  articlePage: defineMessages({
    backButtonToArticleCatalogPageText: {
      defaultMessage: `Return to Content`,
      id: 'vinhood.b2c.articlePage.backButtonToArticleCatalogPageText',
    },
    backButtonToHomePageText: {
      defaultMessage: `Back to home`,
      id: 'vinhood.b2c.articlePage.backButtonToHomePageText',
    },
    interestedInText: {
      defaultMessage: `You may also be interested in`,
      id: 'vinhood.b2c.articlePage.interestedInText',
    },
    showMeMore: {
      defaultMessage: 'Show me more',
      id: 'vinhood.b2c.articlePage.showMeMore',
    },
  }),
  articleSearchPage: defineMessages({
    backButtonText: {
      defaultMessage: 'All Things You Can Do',
      id: 'vinhood.b2c.articleSearchCatalog.backButtonText',
    },
    experiencesTitle: {
      defaultMessage: 'Experiences',
      id: 'vinhood.b2c.articleSearchCatalog.experiencesTitle',
    },
    interestingFactsTitle: {
      defaultMessage: 'Interesting Facts and Education',
      id: 'vinhood.b2c.articleSearchCatalog.interestingFactsTitle',
    },
    pairingsTitle: {
      defaultMessage: 'Pairings',
      id: 'vinhood.b2c.articleSearchCatalog.pairingsTitle',
    },
    producerStoriesTitle: {
      defaultMessage: 'The Producers’ Stories',
      id: 'vinhood.b2c.articleSearchCatalog.producerStoriesTitle',
    },
    reviewsTitle: {
      defaultMessage: 'Reviews',
      id: 'vinhood.b2c.articleSearchCatalog.reviewsTitle',
    },
    searchCountText: {
      defaultMessage: 'matching',
      id: 'vinhood.b2c.articleSearchCatalog.searchCountText',
    },
    tasteStudyTitle: {
      defaultMessage: 'Taste Study',
      id: 'vinhood.b2c.articleSearchCatalog.tasteStudyTitle',
    },
    tutorialsTitle: {
      defaultMessage: 'Tutorials',
      id: 'vinhood.b2c.articleSearchCatalog.tutorialsTitle',
    },
  }),
  articleSearchText: defineMessages({
    filterButtonText: {
      defaultMessage: 'Filters',
      id: 'vinhood.b2c.articleSearch.filterButtonText',
    },
    searchText: {
      defaultMessage: 'Search',
      id: 'vinhood.b2c.articleSearch.searchText',
    },
  }),
  b2cEvents: defineMessages({
    eventCTAText: {
      defaultMessage: 'Reserve a seat',
      id: 'vinhood.b2c.myhome.eventCTAText',
    },
    eventHomePageDescription: {
      defaultMessage: 'Tic, Tac! Only you are missing!',
      id: 'vinhood.b2c.myhome.eventHomePageDescription',
    },
    eventHomePageTitle: {
      defaultMessage: "The tasting you've been looking for",
      id: 'vinhood.b2c.myhome.eventHomePageTitle',
    },
  }),
  productCatalogPage: defineMessages({
    backButtonText: {
      defaultMessage: 'Back',
      id: 'vinhood.b2c.productCatalog.backButtonText',
    },
    backToCatalog: {
      defaultMessage: 'Product Catalog',
      id: 'vinhood.b2c.productCatalog.backToCatalog',
    },
    catalogPageTitle: {
      defaultMessage: 'What You Could Taste',
      id: 'vinhood.b2c.productCatalog.catalogPageTitle',
    },
    catalogPageTitleSituational: {
      defaultMessage: 'Our suggestions that solve your need',
      id: 'vinhood.b2c.productCatalog.catalogPageTitleSituational',
    },
    productTabAllTitle: {
      defaultMessage: 'All',
      id: 'vinhood.b2c.productCatalog.productTabAll',
    },
    productTabBeerTitle: {
      defaultMessage: 'Beer',
      id: 'vinhood.b2c.productCatalog.productTabBeer',
    },
    productTabCoffeeTitle: {
      defaultMessage: 'Coffee',
      id: 'vinhood.b2c.productCatalog.productTabCoffee',
    },
    productTabWineTitle: {
      defaultMessage: 'Wine',
      id: 'vinhood.b2c.productCatalog.productTabWine',
    },
    bannerTitle: {
      defaultMessage: `{productCategory, select, wine {{productCategoryText} catalog} beer {{productCategoryText} catalog} coffee {{productCategoryText} catalog} other{}}`,
      id: 'vinhood.b2c.productPage.catalog.bannerTitle',
    },
    headerDescription: {
      defaultMessage: `{productCategory, select, other {Mature and well structured {productCategoryText}}}`,
      id: 'vinhood.b2c.productPage.catalog.headerDescription',
    },
    headerHeading: {
      defaultMessage: `How you like it`,
      id: 'vinhood.b2c.productPage.catalog.headerHeading',
    },
  }),
  b2cModalsHoc: defineMessages({
    deactivateAccountDesc: {
      defaultMessage: `Reversible choice: you can reactivate it at any time.`,
      id: 'vinhood.b2c.permissionstartpage.deactivateAccountDesc',
    },
    deactivateAccountTitle: {
      defaultMessage: `Deactivate account =/`,
      id: 'vinhood.b2c.permissionstartpage.deactivateAccountTitle',
    },
    deleteAccountDesc: {
      defaultMessage: `Irreversible choice: your test and your preferences will be lost forever.`,
      id: 'vinhood.b2c.permissionstartpage.deleteAccountDesc',
    },
    deleteAccountTitle: {
      defaultMessage: `Delete account =(`,
      id: 'vinhood.b2c.permissionstartpage.deleteAccountTitle',
    },
    profilingDesc: {
      defaultMessage:
        'When you come across content that interests you, it’s not magic: it’s thanks to the information that you provide to us! ||This information helps the algorithm to build a profile that matches who you are. To better manage it, we enlist the help of third-party tools.',
      id: 'vinhood.b2c.permissionstartpage.profilingDesc',
    },
    profilingTitle: {
      defaultMessage: `Profiling`,
      id: 'vinhood.b2c.permissionstartpage.profilingTitle',
    },
    technicalDesc: {
      defaultMessage: `Essential for making sure that the entire service works. These data can't be deactivated. But don't worry, they don't include personal information.`,
      id: 'vinhood.b2c.permissionstartpage.technicalDesc',
    },
    technicalTitle: {
      defaultMessage: `Technical Stuff`,
      id: 'vinhood.b2c.permissionstartpage.technicalTitle',
    },
  }),
  testResultPage: defineMessages({
    characterAdjectiveNone: {
      defaultMessage: 'None',
      id: 'vinhood.b2c.testResultPage.characterAdjectiveNone',
    },
    characterAdjectivePoor: {
      defaultMessage: 'Poor',
      id: 'vinhood.b2c.testResultPage.characterAdjectivePoor',
    },
    characterAdjectiveFair: {
      defaultMessage: 'Fair',
      id: 'vinhood.b2c.testResultPage.characterAdjectiveFair',
    },
    characterAdjectiveAverage: {
      defaultMessage: 'Average',
      id: 'vinhood.b2c.testResultPage.characterAdjectiveAverage',
    },
    characterAdjectiveGood: {
      defaultMessage: 'Good',
      id: 'vinhood.b2c.testResultPage.characterAdjectiveGood',
    },
    characterAdjectiveExcellent: {
      defaultMessage: 'Excellent',
      id: 'vinhood.b2c.testResultPage.characterAdjectiveExcellent',
    },
    characterTitleText: {
      defaultMessage:
        'Your {productCategory, select, wine {{ productCategoryText }} beer {{ productCategoryText }} coffee {{ productCategoryText }} other {}} #Character is',
      id: 'vinhood.b2c.testResultPage.characterTitleText',
    },
    authenticatedCharacterTitleText: {
      defaultMessage:
        'My {productCategory, select, wine {{ productCategoryText }} beer {{ productCategoryText }} coffee {{ productCategoryText }} other {}} #Character is',
      id: 'vinhood.b2c.testResultPage.authenticatedCharacterTitleText',
    },
    titleText: {
      defaultMessage:
        '#{characterName} {productCategory, select, wine {{ productCategoryPluralText }} beer {{ productCategoryPluralText }} coffee {{ productCategoryPluralText }} other {}} are',
      id: 'vinhood.b2c.testResultPage.titleText',
    },
    btnText: {
      defaultMessage: 'Learn more',
      id: 'vinhood.b2c.testResultPage.btnText',
    },
    likeProText: {
      defaultMessage: 'Taste like a pro!',
      id: 'vinhood.b2c.testResultPage.likeProText',
    },
    discoverText: {
      defaultMessage: 'Discover your perfect products',
      id: 'vinhood.b2c.testResultPage.discoverText',
    },
    honeText: {
      defaultMessage: 'Hone your taste with fun quizzes and games',
      id: 'vinhood.b2c.testResultPage.honeText',
    },
    diveText: {
      defaultMessage: 'Dive into the world of wine, beer, coffee, and beyond!',
      id: 'vinhood.b2c.testResultPage.diveText',
    },
    quizTitleText: {
      defaultMessage: 'Challenge your knowledge about',
      id: 'vinhood.b2c.testResultPage.quizTitleText',
    },
    quizTrueText: {
      defaultMessage: 'True',
      id: 'vinhood.b2c.testResultPage.quizTrueText',
    },
    quizFalseText: {
      defaultMessage: 'False',
      id: 'vinhood.b2c.testResultPage.quizFalseText',
    },
    yesRight: {
      defaultMessage: `You're right!`,
      id: 'vinhood.b2c.testResultPage.yesRight',
    },
    ohNo: {
      defaultMessage: 'Oh no...',
      id: 'vinhood.b2c.testResultPage.ohNo',
    },
    productSectionTitle: {
      defaultMessage:
        '{productCategory, select, wine {Ideal {productCategoryText}} beer {Ideal {productCategoryText}} coffee {Ideal {productCategoryText}} other {}} for you:',
      id: 'vinhood.b2c.testResultPage.productSectionTitle',
    },
    productSectionModalTitle: {
      defaultMessage:
        '{productCategory, select, wine {Save your {productCategoryPluralText}} beer {Save your {productCategoryPluralText}} coffee {Save your {productCategoryPluralText}} other {}}',
      id: 'vinhood.b2c.testResultPage.productSectionModalTitle',
    },
    productSectionDescription: {
      defaultMessage:
        '{productCategory, select, wine {You just tried your soulmate {productCategoryText}? ||Start saving the record of all your best {productCategoryPluralText}} beer {You just tried your soulmate {productCategoryText}? ||Start saving the record of all your best {productCategoryPluralText}} coffee {You just tried your soulmate {productCategoryText}? ||Start saving the record of all your best {productCategoryPluralText}} other {}}',
      id: 'vinhood.b2c.testResultPage.productSectionDescription',
    },
    productSectionModalExtraDescription: {
      defaultMessage: 'And learn about them!',
      id: 'vinhood.b2c.testResultPage.productSectionModalExtraDescription',
    },
    registrationBannerTitle: {
      defaultMessage: 'Create a Vinhood account, freely!',
      id: 'vinhood.b2c.testResultPage.registrationBannerTitle',
    },
    registrationBannerBtn: {
      defaultMessage: 'Continue',
      id: 'vinhood.b2c.testResultPage.registrationBannerBtn',
    },
  }),
  b2cSearchModal: defineMessages({
    buttonText: {
      defaultMessage: 'Search',
      id: 'vinhood.b2c.searchModal.buttonText',
    },
    inputFieldLabel: {
      defaultMessage: 'What are you looking for?',
      id: 'vinhood.b2c.searchModal.inputFieldLabel',
    },
  }),
  characterCardMissedLarge: defineMessages({
    buttonText: {
      defaultMessage: `Take the taste test now`,
      id: 'vinhood.b2c.charactercardmissedtestlarge.buttonText',
    },
    descriptionText: {
      defaultMessage: 'Take the taste test to complete your taste profile on all currently available products.',
      id: 'vinhood.b2c.charactercardmissedtestlarge.descriptionText',
    },
    subtitleText: {
      defaultMessage: `You are missing the {productCategory, select, wine{{productCategoryText}} beer{{productCategoryText}} coffee{{productCategoryText}} other{}} experience!`,
      id: 'vinhood.b2c.charactercardmissedtestlarge.subtitleText',
    },
    titleText: {
      defaultMessage: `Discover more!`,
      id: 'vinhood.b2c.charactercardmissedtestlarge.titleText',
    },
  }),
  characterCardMissedSmall: defineMessages({
    buttonText: {
      defaultMessage: `Start the test`,
      id: 'vinhood.b2c.charactercardmissedtestsmall.buttonText',
    },
    subtitleFirst: {
      defaultMessage: `To unlock this `,
      id: 'vinhood.b2c.charactercardmissedtestsmall.subtitleFirst',
    },
    subtitleSecond: {
      defaultMessage: ` experience`,
      id: 'vinhood.b2c.charactercardmissedtestsmall.subtitleSecond',
    },
    title: {
      defaultMessage: `It doesn’t take much`,
      id: 'vinhood.b2c.charactercardmissedtestsmall.title',
    },
  }),
  characterModal: defineMessages({
    characterTasteDesc: {
      defaultMessage:
        '{productCategory, select, wine {{sectionDescription} {productCategoryPluralText}} beer {{sectionDescription} {productCategoryPluralText}} coffee {{sectionDescription} {productCategoryPluralText}} other{}}',
      id: 'vinhood.b2c.characterModal.characterTasteDesc',
    },
    essenceSectionDesc: {
      defaultMessage:
        '{productCategory, select, wine {{sectionDescription} {productCategoryPluralText}} beer {{sectionDescription} {productCategoryPluralText}} coffee {{sectionDescription} {productCategoryPluralText}} other{}}',
      id: 'vinhood.b2c.characterModal.essenceSectionDesc',
    },
    essenceTitle: {
      defaultMessage: 'In a nutshell',
      id: 'vinhood.b2c.characterModal.essenceTitle',
    },
    sectionDescription: {
      defaultMessage: 'Read the details to better understand the',
      id: 'vinhood.b2c.characterModal.sectionDescription',
    },
    tasteTitle: {
      defaultMessage: 'How to taste it',
      id: 'vinhood.b2c.characterModal.tasteTitle',
    },
  }),
  closeComponent: defineMessages({
    closeText: {
      defaultMessage: 'Close',
      id: 'vinhood.b2c.modal.footer.closeText',
    },
  }),
  cookiePolicyModal: defineMessages({
    amendmentsDescription: {
      defaultMessage:
        'This Cookie Policy is valid from the effective date. The Controller may amend and/or supplement this cookie Policy, also as a result of amended and/or supplemented legislation.',
      id: 'vinhood.b2c.cookiePolicyModal.amendmentsDescription',
    },
    amendmentsTitle: {
      defaultMessage: '**3. Amendments and Updates**',
      id: 'vinhood.b2c.cookiePolicyModal.amendmentsTitle',
    },
    cookieAnalyticsDescription: {
      defaultMessage:
        'The cookie analytics in this App are directly installed by Us, but by third parties and they are used on the App for collecting statistical information, in an aggregate and non-aggregate form, on the number of user accessing the App and on how they visit the App itself. ||We use the following third party cookie analytics:',
      id: 'vinhood.b2c.cookiePolicyModal.cookieAnalyticsDescription',
    },
    cookieAnalyticsText: {
      defaultMessage: 'Third-party Cookie Analytics',
      id: 'vinhood.b2c.cookiePolicyModal.cookieAnalyticsText',
    },
    cookieAnalyticsTitle: {
      defaultMessage: '**Cookie Analytics**',
      id: 'vinhood.b2c.cookiePolicyModal.cookieAnalyticsTitle',
    },
    cookieDurationText: {
      defaultMessage: 'Up to a maximum of 5 years',
      id: 'vinhood.b2c.cookiePolicyModal.cookieDurationText',
    },
    cookiePurposeDescriptionText: {
      defaultMessage:
        'Obtaining aggregate statistics from browsing data to understand how user interact with the service (and thus improve its features?).',
      id: 'vinhood.b2c.cookiePolicyModal.cookiePurposeDescriptionText',
    },
    description: {
      defaultMessage:
        '**TASTE S.r.l.** is a company under Italian law, based in Milan, Piazza della Repubblica 32, Vinhood (hereinafter the **“Owner”** or **“we’** or “**VINHOOD**”, contactable at the email address: privacy@vinhood.com, as a data controller, hereby provides you with the following information concerning the use of cookies on VINHOOD’s mobile Application (hereinafter **App**).',
      id: 'vinhood.b2c.cookiePolicyModal.description',
    },
    disableCookieInfoText: {
      defaultMessage:
        'However, if you choose to disable these cookies, it does not mean that you will no longer see ads on the App, only that the banners you display may not match your interests or preferences. ||All profiling cookies are installed by third parties, acting as independent controllers (third-party cookies). Here is a list of all third-party profiling cookies currently used on the App, showing the link to the information pages created by their developers:',
      id: 'vinhood.b2c.cookiePolicyModal.disableCookieInfoText',
    },
    durationText: {
      defaultMessage: 'Duration',
      id: 'vinhood.b2c.cookiePolicyModal.durationText',
    },
    heading: {
      defaultMessage: 'Cookie Policy',
      id: 'vinhood.b2c.cookiePolicyModal.heading',
    },
    mixpanelText: {
      defaultMessage: 'Mixpanel',
      id: 'vinhood.b2c.cookiePolicyModal.mixpanelText',
    },
    profilingCookiePurposeText: {
      defaultMessage:
        'Providing the user with customized experiences based on their use of our services and their behavior within the application',
      id: 'vinhood.b2c.cookiePolicyModal.profilingCookiePurposeText',
    },
    profilingCookiesText: {
      defaultMessage:
        'For further details, carefully read the third-party cookie policies, following the link shown in the above table. ||**Profiling cookies** ||Profiling cookies are used to create your profile based on your preferences and interests, as shown during web browsing, so that we can display customized content to you. As provided by current privacy legislation, your prior consent is required to install these cookies. Whenever you give your consent to install cookies using this method, we will track consent via a technical cookie for this purpose. In this way, the banner with the cookie notice will not appear again on your future visits to the App. ||You are free to block the installation of profiling cookies at any time, without affecting your ability to visit the App and enjoy its content by clicking on the gear icon in the application footer.',
      id: 'vinhood.b2c.cookiePolicyModal.profilingCookiesText',
    },
    profilingCookiesTitleText: {
      defaultMessage: 'Third-party profiling cookies:',
      id: 'vinhood.b2c.cookiePolicyModal.profilingCookiesTitleText',
    },
    profilingDurationText: {
      defaultMessage: '12 months',
      id: 'vinhood.b2c.cookiePolicyModal.profilingDurationText',
    },
    purposeCookieDescription: {
      defaultMessage:
        'Several cookies are needed for allowing a user to browse the App and use its features (**technical cookies**). Others are used to obtain statistical information, in an aggregate or non-aggregate form, on the number of user that access the App and how the App is used (**cookie analytics**). Finally, others are used to track your profile, so that advertising may be displayed on the App that matches your interests, in that it is consistent with your tastes and your habits as a consumer (**profiling cookies**). ||On the App, cookies or other technical, analytical and profiling tracking tools are used.',
      id: 'vinhood.b2c.cookiePolicyModal.purposeCookieDescription',
    },
    purposeCookieText: {
      defaultMessage: '**c) Based of the purposes:**',
      id: 'vinhood.b2c.cookiePolicyModal.purposeCookieText',
    },
    purposeTitleText: {
      defaultMessage: 'Purpose',
      id: 'vinhood.b2c.cookiePolicyModal.purposeTitleText',
    },
    serviceNameText: {
      defaultMessage: 'Service name',
      id: 'vinhood.b2c.cookiePolicyModal.serviceNameText',
    },
    technicalCookiesDescription: {
      defaultMessage:
        'Technical cookies are those that Vinhood uses only for allowing you to visit the App and to use it. These may further break down into: ||(i) Browser cookies: these guarantee normal browsing and enjoyment of the App, allowing you, for example, to make a purchase or sign-in for accessing private areas. These are normally session cookies that are automatically deleted when you close the web browser; ||(ii) Functionality cookies: these allow you to browse, based on a series of selected features (for example, language or country of origin) for enhancing the service provided on the App. These are persistent cookies that are stored on the computer you use also after closing the web browser, until the expiry date set for each cookie (normally from two months to two years) is reached or until you decide to remove them. ||Technical cookies are not used for purposes other than those described above. ||You can disable proprietary and third-party technical cookies by changing your browser settings. However, you should be aware that by removing or blocking these cookies, several of the websites you visit (or the relevant features) may not work properly or efficiently.',
      id: 'vinhood.b2c.cookiePolicyModal.technicalCookiesDescription',
    },
    technicalCookiesTitle: {
      defaultMessage: '**Technical cookies**',
      id: 'vinhood.b2c.cookiePolicyModal.technicalCookiesTitle',
    },
    vinhoodUsedCookiesTitle: {
      defaultMessage: '**2. Cookies used by VINHOOD:**',
      id: 'vinhood.b2c.cookiePolicyModal.vinhoodUsedCookiesTitle',
    },
    whatAreCookiesDescription: {
      defaultMessage:
        'Cookies are small text strings that the App you visit sends to your device (for example, computer, smartphone, tablet), where they are stored and then transmitted back to the same App on your next visit. ||Cookies used on this App may be identified based on the following guidelines:',
      id: 'vinhood.b2c.cookiePolicyModal.whatAreCookiesDescription',
    },
    whatAreCookiesText: {
      defaultMessage: '**1. What are cookies and what cookie categories we use**',
      id: 'vinhood.b2c.cookiePolicyModal.whatAreCookiesText',
    },
    whatTimeOfCookieDescription: {
      defaultMessage:
        '(i) Session cookies: these cookies store the information required during a browsing session and they are deleted immediately after the session is closed, or after the browser is closed; ||(ii) Persistent cookies: these cookies are stored on your hard drive and the App reads them each time you visit it. A persistent cookie has a fixed expiry date, after which it will stop working.',
      id: 'vinhood.b2c.cookiePolicyModal.whatTimeOfCookieDescription',
    },
    whatTimeOfCookieText: {
      defaultMessage: '**b) Based on the time they persist and remain active:**',
      id: 'vinhood.b2c.cookiePolicyModal.whatTimeOfCookieText',
    },
    whoManageCookieDescription: {
      defaultMessage:
        '(i) Proprietary or first-party cookies: these are cookies directly installed by Us via the App; ||(ii) Third-party cookies: these are installed via the App by other third parties respect to Us;',
      id: 'vinhood.b2c.cookiePolicyModal.whoManageCookieDescription',
    },
    whoManageCookieText: {
      defaultMessage: '**a) Based on who manages the cookies:**',
      id: 'vinhood.b2c.cookiePolicyModal.whoManageCookieText',
    },
  }),
  createCharacterTasteDataText: defineMessages({
    beerGlassDescription: {
      defaultMessage: 'Use a',
      id: 'vinhood.b2c.character.tasteData.beerGlassDescription',
    },
    classicCoffeeText: {
      defaultMessage: 'Classic',
      id: 'vinhood.b2c.character.tasteData.classicCoffeeText',
    },
    foodPariringDescription: {
      defaultMessage: 'Ideal to pair with',
      id: 'vinhood.b2c.character.tasteData.foodPariringDescription',
    },
    foodPariringText: {
      defaultMessage: 'Food pairing',
      id: 'vinhood.b2c.character.tasteData.foodPariringText',
    },
    glassText: {
      defaultMessage: 'Glass',
      id: 'vinhood.b2c.character.tasteData.glassText',
    },
    orText: {
      defaultMessage: 'or',
      id: 'vinhood.b2c.character.tasteData.orText',
    },
    specialCoffeeText: {
      defaultMessage: 'Special',
      id: 'vinhood.b2c.character.tasteData.specialCoffeeText',
    },
    temperatureDescription: {
      defaultMessage: 'Serve at',
      id: 'vinhood.b2c.character.tasteData.temperatureDescription',
    },
    temperatureText: {
      defaultMessage: 'Temperature',
      id: 'vinhood.b2c.character.tasteData.temperatureText',
    },
    wineGlassDescription: {
      defaultMessage: 'Use the',
      id: 'vinhood.b2c.character.tasteData.wineGlassDescription',
    },
    wineGlassText: {
      defaultMessage: 'Wine Glass',
      id: 'vinhood.b2c.character.tasteData.wineGlassText',
    },
  }),
  edutainmentFormPage: defineMessages({
    backBtn: {
      defaultMessage: 'Previous',
      id: 'vinhood.b2c.edutainmentFormPage.backBtn',
    },
    headingTitle: {
      defaultMessage: 'Exploring is amusing',
      id: 'vinhood.b2c.edutainmentFormPage.headingTitle',
    },
  }),
  edutainmentPage: defineMessages({
    description: {
      defaultMessage: 'Enjoy the quizzes to discover all the Characters.',
      id: 'vinhood.b2c.edutainment.description',
    },
    heading: {
      defaultMessage: 'Exploring is amusing.',
      id: 'vinhood.b2c.edutainment.heading',
    },
  }),
  experienceCatalog: defineMessages({
    showMeMore: {
      defaultMessage: 'Show me more',
      id: 'vinhood.b2c.experienceCatalog.showMeMore',
    },
    titleHeading: {
      defaultMessage: 'What Can You Do',
      id: 'vinhood.b2c.experienceCatalog.titleHeading',
    },
  }),
  experienceSearchPage: defineMessages({
    backButtonText: {
      defaultMessage: 'All Things You Can Do',
      id: 'vinhood.b2c.experienceSearchCatalog.catalogPageTitle',
    },
    coursesTitle: {
      defaultMessage: 'Courses',
      id: 'vinhood.b2c.experienceSearchCatalog.coursesTitle',
    },
    eventsTitle: {
      defaultMessage: 'Events',
      id: 'vinhood.b2c.experienceSearchCatalog.eventsTitle',
    },
    experiencesTitle: {
      defaultMessage: 'Experiences',
      id: 'vinhood.b2c.experienceSearchCatalog.experiencesTitle',
    },
    onlineActivitiesTitle: {
      defaultMessage: 'Online Activities',
      id: 'vinhood.b2c.experienceSearchCatalog.onlineActivitiesTitle',
    },
    onlineClassesTitle: {
      defaultMessage: 'Online Classes',
      id: 'vinhood.b2c.experienceSearchCatalog.onlineClassesTitle',
    },
    overnightStaysTitle: {
      defaultMessage: 'Overnight Stays',
      id: 'vinhood.b2c.experienceSearchCatalog.overnightStaysTitle',
    },
    searchCountText: {
      defaultMessage: 'matching',
      id: 'vinhood.b2c.experienceSearch.searchCountText',
    },
    tastingsTitle: {
      defaultMessage: 'Tastings',
      id: 'vinhood.b2c.experienceSearchCatalog.tastingsTitle',
    },
  }),
  experienceCard: defineMessages({
    readMoreBtnText: {
      defaultMessage: 'Read more',
      id: 'vinhood.b2c.experienceCard.readMoreBtnText',
    },
    notLoggedBtnText: {
      defaultMessage: 'Register to unlock this content',
      id: 'vinhood.b2c.experienceCard.notLoggedBtnText',
    },
  }),
  experienceSearch: defineMessages({
    filterButtonText: {
      defaultMessage: 'Filters',
      id: 'vinhood.b2c.experienceSearch.filterButtonText',
    },
    searchText: {
      defaultMessage: 'Search',
      id: 'vinhood.b2c.experienceSearch.searchText',
    },
  }),
  interestsModal: defineMessages({
    description: {
      defaultMessage: `The quality of your experience on this app is directly proportional to the amount of information that you provide to us. The more precise you are, the better your profile and all of the subsequent content will be. That's why you're seeing all of these additional options: they're not essential, but they're really useful.`,
      id: 'vinhood.b2c.foodIntrestsModal.description',
    },
    heading: {
      defaultMessage: 'Interests',
      id: 'vinhood.b2c.foodIntrestsModal.heading',
    },
  }),
  foodPreferencesModal: defineMessages({
    description: {
      defaultMessage: `We know that food preferences are personal, and they'll stay that way. But knowing what they are helps us to offer you a better experience. If you choose to leave something out, we'll do our best not to consider it for you anymore. But if you include it, the app will suggest related products, content, and experiences.`,
      id: 'vinhood.b2c.foodPreferencesModal.description',
    },
    heading: {
      defaultMessage: 'Food Preferences',
      id: 'vinhood.b2c.foodPreferencesModal.heading',
    },
  }),
  footerApp: defineMessages({
    articlesText: {
      defaultMessage: 'Articles',
      id: 'vinhood.b2c.footer.articlesText',
    },
    experiencesText: {
      defaultMessage: 'Experiences',
      id: 'vinhood.b2c.footer.experiencesText',
    },
    trainingsText: {
      defaultMessage: 'Trainings',
      id: 'vinhood.b2c.footer.trainingsText',
    },
    homeText: {
      defaultMessage: 'Home',
      id: 'vinhood.b2c.footer.homeText',
    },
    productsText: {
      defaultMessage: 'Products',
      id: 'vinhood.b2c.footer.productsText',
    },
    profileText: {
      defaultMessage: 'Profile',
      id: 'vinhood.b2c.footer.profileText',
    },
    tasteIdText: {
      defaultMessage: 'Taste Id',
      id: 'vinhood.b2c.footer.tasteIdText',
    },
  }),
  globalSearchNoResult: defineMessages({
    informationEventsSuggestions: {
      defaultMessage: 'Events',
      id: 'vinhood.b2c.globalSearchNoResult.informationEventsSuggestions',
    },
    informationInsightsSuggestions: {
      defaultMessage: 'Insights, News, and Special Content',
      id: 'vinhood.b2c.globalSearchNoResult.informationInsightsSuggestions',
    },
    informationSuggestionsTitle: {
      defaultMessage: 'Search Informations',
      id: 'vinhood.b2c.globalSearchNoResult.informationSuggestionsTitle',
    },
    noMatchesFound: {
      defaultMessage: 'Sorry, no matches were found.',
      id: 'vinhood.b2c.globalSearchNoResult.noMatchesFoundText',
    },
    suggestion: {
      defaultMessage: 'Please try a new search or use our suggestions below:',
      id: 'vinhood.b2c.globalSearchNoResult.suggestionText',
    },
    suggestionWithProductCategory: {
      defaultMessage: `#{characterName} {productCategoryPluralText}`,
      id: 'vinhood.b2c.globalSearchNoResult.suggestionWithProductCategory',
    },
  }),
  globalSearchPage: defineMessages({
    pageHeading: {
      defaultMessage: 'Results for:',
      id: 'vinhood.b2c.globalSearchPage.pageHeading',
    },
    productsTabText: {
      defaultMessage: 'Products',
      id: 'vinhood.b2c.globalSearchPage.productsTabText',
    },
  }),
  hintPage: defineMessages({
    backButtonText: {
      defaultMessage: 'Back to home',
      id: 'vinhood.b2c.myhome.backButtonText',
    },
    bannerSectionDescText: {
      defaultMessage: 'Choose like a true pro, at the supermarket and in your place of the heart.',
      id: 'vinhood.b2c.myhome.bannerSectionDescText',
    },
    bannerSectionHeadingText: {
      defaultMessage: 'A pocket tip',
      id: 'vinhood.b2c.myhome.bannerSectionHeadingText',
    },
  }),
  hintSection: defineMessages({
    hintCTAText: {
      defaultMessage: 'Check all tips',
      id: 'vinhood.b2c.myhome.hintCTAText',
    },
  }),
  homePageSkeleton: defineMessages({
    experienceHeadingText: {
      defaultMessage: 'Outstanding **experiences** that are perfect for you',
      id: 'vinhood.b2c.myhome-skeleton.experienceHeadingText',
    },
    mainHeaderText: {
      defaultMessage: 'Why pre-taste when you can taste?',
      id: 'vinhood.b2c.myhome-skeleton.mainHeaderText',
    },
    productSwiperText: {
      defaultMessage: "**Products** that you'll enjoy",
      id: 'vinhood.b2c.myhome-skeleton.productSwiperText',
    },
  }),
  manageModal: defineMessages({
    buttonCancelText: {
      defaultMessage: 'Cancel',
      id: 'vinhood.b2c.manageModal.buttonCancelTex',
    },
    buttonConfirmText: {
      defaultMessage: 'Confirm Your Choice',
      id: 'vinhood.b2c.manageModal.buttonConfirmText',
    },
    description: {
      defaultMessage:
        'If you want to take a break, you can put your account on hold. ||If, on the other hand, this is a goodbye, you can delete it forever.',
      id: 'vinhood.b2c.manageModal.description',
    },
    title: {
      defaultMessage: 'Deactivate or Delete Your Vinhood Account',
      id: 'vinhood.b2c.manageModal.title',
    },
  }),
  cookieModal: defineMessages({
    closeText: {
      defaultMessage: `Reject all optional`,
      id: 'vinhood.b2c.cookieModal.closeText',
    },
    confirmBtn: {
      defaultMessage: `Accept All`,
      id: 'vinhood.b2c.cookieModal.confirmBtn',
    },
    cookieText: {
      defaultMessage: `Feel free to read the full [linkTo=cookie-page]cookie policy.[linkTo=cookie-page]`,
      id: 'vinhood.b2c.cookieModal.cookieText',
    },
    descriptionShort: {
      defaultMessage: `If you select **accept all** you give the green light to everything, if you select **manage your preferences** you can make some modifications.`,
      id: 'vinhood.b2c.cookieModal.descriptionShort',
    },
    managePreferencesBtnText: {
      defaultMessage: `Manage Your Preferences`,
      id: 'vinhood.b2c.cookieModal.rejectBtn',
    },
    title: {
      defaultMessage: `Policy`,
      id: 'vinhood.b2c.cookieModal.title',
    },
    preTitle: {
      defaultMessage: `{productCategory, select, wine {Discover your Taste Identity and the perfect {productCategoryPluralText} for you.} coffee {Discover your Taste Identity and the perfect {productCategoryPluralText} for you.} beer {Discover your Taste Identity and the perfect {productCategoryPluralText} for you.} other {Discover your Taste Identity!}}`,
      id: 'vinhood.b2c.cookieModal.preTitle',
    },
  }),

  languageBtnGroup: defineMessages({
    englishLanguageText: {
      defaultMessage: 'English',
      id: 'vinhood.b2c.profile.settings.englishLanguageText',
    },
    italianLanguageText: {
      defaultMessage: 'Italian',
      id: 'vinhood.b2c.profile.settings.italianLanguageText',
    },
    languageTitle: {
      defaultMessage: 'Language',
      id: 'vinhood.b2c.profile.settings.languageTitle',
    },
  }),
  legalComponent: defineMessages({
    conditionsCardButtonText: {
      defaultMessage: 'Read the Terms and Conditions',
      id: 'vinhood.b2c.legalPage.conditionsCardButtonText',
    },
    conditionsCardDescriptionText: {
      defaultMessage:
        'The last step in learning everything there is to know about how we process your information and promise to manage it. The last step to eternal awareness.',
      id: 'vinhood.b2c.legalPage.conditionsCardDescriptionText',
    },
    conditionsCardTitle: {
      defaultMessage: 'Terms and Conditions (you made it!)',
      id: 'vinhood.b2c.legalPage.conditionsCardTitle',
    },
    cookieCardButtonText: {
      defaultMessage: 'Read the Cookie Policy',
      id: 'vinhood.b2c.legalPage.cookieCardButtonText',
    },
    cookieCardDescriptionText: {
      defaultMessage:
        'To personalize the content and experiences that we offer you, we collect information on how and when you use our app. This makes it possible for us and our collaborators to improve your experience.',
      id: 'vinhood.b2c.legalPage.cookieyCardDescriptionText',
    },
    cookieCardTitle: {
      defaultMessage: 'Cookies Policy (the sister of the Privacy Policy)',
      id: 'vinhood.b2c.legalPage.cookieCardTitle',
    },
    legalDescription: {
      defaultMessage:
        'What information do we collect? Why? There’s no need for an existential crisis. Here you’ll find out everything you need to know about how your information is handled. If later you regret your choice or change your mind, come back to this page to make any adjustments.',
      id: 'vinhood.b2c.legalPage.legalDescription',
    },
    legalTitle: {
      defaultMessage: 'This is Your Control Room',
      id: 'vinhood.b2c.legalPage.legalTitle',
    },
    permissionCardButtonText: {
      defaultMessage: 'Your Preferences',
      id: 'vinhood.b2c.legalPage.permissionCardButtonText',
    },
    permissionCardDescriptionText: {
      defaultMessage:
        'The fact that you’re here means that you have the utmost trust in us. We owe you and want to reciprocate. ||Don’t worry about your information: we’d give up the last glass of wine to protect it.',
      id: 'vinhood.b2c.legalPage.permissionCardDescriptionText',
    },
    permissionCardTitle: {
      defaultMessage: 'Permissions and Privacy',
      id: 'vinhood.b2c.legalPage.permissionCardTitle',
    },
    privacyCardButtonText: {
      defaultMessage: 'Read the Privacy Policy',
      id: 'vinhood.b2c.legalPage.privacyCardButtonText',
    },
    privacyCardDescriptionText: {
      defaultMessage:
        'Vinhood has adopted a Privacy Policy in order to ensure transparency in the way your personal data is processed. ||To know how and why we collect, save, and sometimes use the information, give it a read. Even before going to bed.',
      id: 'vinhood.b2c.legalPage.privacyCardDescriptionText',
    },
    privacyCardTitle: {
      defaultMessage: 'Privacy Policy (the part everyone skips over)',
      id: 'vinhood.b2c.legalPage.privacyCardTitle',
    },
  }),
  modalOptionItem: defineMessages({
    alwaysActiveText: {
      defaultMessage: 'ALWAYS ACTIVE',
      id: 'vinhood.b2c.functionalmodal.alwaysActiveText',
    },
    toggleDisabledText: {
      defaultMessage: 'NOT ACTIVE',
      id: 'vinhood.b2c.functionalmodal.toggleDisabledText',
    },
    toggleEnabledText: {
      defaultMessage: 'ACTIVE',
      id: 'vinhood.b2c.functionalmodal.toggleEnabledText',
    },
  }),
  tagsModal: defineMessages({
    heading: {
      defaultMessage: 'What to Know Before Choosing',
      id: 'vinhood.b2c.mydriverforchoicemodal.heading',
    },
    headingDescription: {
      defaultMessage:
        'Here you can choose the topics that interest you. That way your profile will be more complete and your experience even more consistent with it.',
      id: 'vinhood.b2c.mydriverforchoicemodal.headingDescription',
    },
  }),
  tagsTabContainer: defineMessages({
    headingDescription: {
      defaultMessage: `You'll find your tags here. They're the values that shape your taste experience. Update them every now and then!`,
      id: 'vinhood.b2c.profile.myTags.headingDescription',
    },
    headingTitle: {
      defaultMessage: 'Buyer Principles',
      id: 'vinhood.b2c.profile.myTags.headingTitle',
    },
    subHeadingDescription: {
      defaultMessage:
        'Here you can choose the topics that interest you. That way your profile will be more complete and your experience even more consistent with it.',
      id: 'vinhood.b2c.profile.myTags.subHeadingDescription',
    },
    subHeadingTitle: {
      defaultMessage: 'What to Know Before Choosing',
      id: 'vinhood.b2c.profile.myTags.subHeadingTitle',
    },
  }),
  perfectForText: defineMessages({
    perfectForTitleText: {
      defaultMessage: 'The Perfect Situation',
      id: 'vinhood.b2c.productPage.tasting.perfectForTitleText',
    },
  }),
  permissionStartPage: defineMessages({
    btnBack: {
      defaultMessage: 'Back',
      id: 'vinhood.b2c.permissionstartpage.btnBack',
    },
    btnConfirm: {
      defaultMessage: 'Save and continue',
      id: 'vinhood.b2c.permissionstartpage.btnConfirm',
    },
    cookieOptionTitle: {
      defaultMessage: 'Cookies for Every Taste',
      id: 'vinhood.b2c.permissionstartpage.cookieOptionTitle',
    },
    headerDescription: {
      defaultMessage: `At Vinhood there are people who, like you right now, find themselves filling out these things every single day. ||So your privacy is our absolute priority. We thank you in advance for the time that you'll take to read this policy. It's not something everyone does. ||The more information you give us, the more complete your experience will be. ||And since here experience is everything, this is a crucial moment! ||The information has to do with your choices and your device. Not you, not your privacy. That's sacred. Still not convinced? That's too bad. Your experience could end up being somewhat limited.`,
      id: 'vinhood.b2c.permissionstartpage.headerDescription',
    },
    headerTitle: {
      defaultMessage: 'When it comes to your information, you decide',
      id: 'vinhood.b2c.permissionstartpage.headerTitle',
    },
  }),
  preferencesModal: defineMessages({
    buttonCancelText: {
      defaultMessage: 'Cancel',
      id: 'vinhood.b2c.functionalmodal.buttonCancelTex',
    },
    buttonConfirmText: {
      defaultMessage: 'Save and Continue',
      id: 'vinhood.b2c.functionalmodal.buttonConfirmText',
    },
    cookieTitle: {
      defaultMessage: 'Cookies for Every Taste',
      id: 'vinhood.b2c.functionalmodal.cookieTitle',
    },
    modalHeaderDescription: {
      defaultMessage:
        'Still here? At Vinhood there are people who, like you right now, find themselves filling out these things every single day. So your privacy is our absolute priority. We thank you in advance for the time that you’ll take to read this policy. It’s not something everyone does. The more information you give us, the more complete your experience will be. And since here experience is everything, this is a crucial moment! ||The information has to do with your choices and your device. Not you, not your privacy. That’s sacred. Still not convinced? That’s too bad. Your experience could end up being somewhat limited. But we love you anyway.',
      id: 'vinhood.b2c.functionalmodal.modalHeaderDescription',
    },
    modalHeaderTitle: {
      defaultMessage: 'Manage Permissions and Privacy',
      id: 'vinhood.b2c.functionalmodal.modalHeaderTitle',
    },
    privacyOptionDescription: {
      defaultMessage:
        'You can directly unsubscribe from the newsletter at any time by pressing the Unsubscribe button.',
      id: 'vinhood.b2c.functionalmodal.privacyOptionDescription',
    },
    privacyTitle: {
      defaultMessage: 'Privacy Preferences',
      id: 'vinhood.b2c.functionalmodal.privacyTitle',
    },
  }),
  privacyPolicyAcceptance: defineMessages({
    emailPolicyCheckboxText: {
      defaultMessage:
        'I would like Vinhood to send me e-mails and content based on my profile, my actions, the pages I visit, and my geographical position.',
      id: 'vinhood.b2c.privacyPolicyAcceptance.emailPolicyCheckboxText',
    },

    privacyPolicyCheckboxText: {
      defaultMessage:
        'I accept the [linkTo=privacy]Privacy Policy[linkTo=privacy] and want to receive the newsletter and other notifications from Vinhood.',
      id: 'vinhood.b2c.privacyPolicyAcceptance.privacyPolicyCheckboxText',
    },
  }),
  privacyPolicyModal: defineMessages({
    automatedDecisionHeading: {
      defaultMessage: 'Use automated decision-making processes',
      id: 'vinhood.b2c.privacyPolicy.automatedDecisionHeading',
    },
    automatedDecisionSub1: {
      defaultMessage:
        'As previously explained, we use low-level profiling of our user for the purpose of providing you with a customized service.',
      id: 'vinhood.b2c.privacyPolicy.automatedDecisionSub1',
    },
    automatedDecisionSub2: {
      defaultMessage:
        'Profiling is based on parameters, such as taste #characters, knowledge of products and actions taken on the VINHOOD App and the results of your taste test.',
      id: 'vinhood.b2c.privacyPolicy.automatedDecisionSub2',
    },
    automatedDecisionSub3: {
      defaultMessage:
        'Taste profiling is done using our algorithms that use predictive skills for identifying, for example, your #character in relation to a certain product category (e.g. wine, coffee, beer and bitter liqueurs, etc.) and for providing you with tips in line with your preferences about a certain product.',
      id: 'vinhood.b2c.privacyPolicy.automatedDecisionSub3',
    },
    automatedDecisionSub4: {
      defaultMessage:
        'Following such analyses, you may use the VINHOOD App services that are personalized for you and also receive personalized communications from us that we think are in line with your tastes and preferences (#character).',
      id: 'vinhood.b2c.privacyPolicy.automatedDecisionSub4',
    },
    automatedDecisionSub5: {
      defaultMessage:
        'We have successfully conducted a balancing test for the purpose of determining that processing linked to your categorization is conducted legally based on VINHOOD’s legitimate interest for obtaining maximum efficiency in creating a single “taste passport” and in marketing activities.',
      id: 'vinhood.b2c.privacyPolicy.automatedDecisionSub5',
    },
    automatedDecisionSub6: {
      defaultMessage:
        'We would like to remind you, on the other hand, that if you consent to the processing of your personal data for profiling purposes in order to send you personalized commercial communications based on the data we collect about you, possibly also via cookies, you may revoke your consent to such processing at any time: in this case, we will delete or anonymize the data collected for this purpose, so that you may no longer be identified by them, and you will no longer receive profiled communications.',
      id: 'vinhood.b2c.privacyPolicy.automatedDecisionSub6',
    },
    automatedDecisionSub7: {
      defaultMessage:
        'To such end, we have drawn up a data protection impact assessment as per Article 35 of the Regulation to ensure that profiling does not have a significant negative impact on you.',
      id: 'vinhood.b2c.privacyPolicy.automatedDecisionSub7',
    },
    cookiePolicyLinkText: {
      defaultMessage: 'cookie policy.',
      id: 'vinhood.b2c.privacyPolicy.cookiePolicyLinkText',
    },
    dataControllerDesc1: {
      defaultMessage:
        'VINHOOD is the data controller of personal information (“Controller”) in the name of its legal representative pro tempore based in Milan, Piazza della Repubblica 32, who can be contacted at the e-mail address [privacy@vinhood.com](mailto:privacy@vinhood.com).',
      id: 'vinhood.b2c.privacyPolicy.dataControllerDesc1',
    },
    dataControllerDesc2: {
      defaultMessage:
        'In performing its activities, VINHOOD, as “Controller”, processes personal data in compliance with prevailing legislative provisions (Article 13 of Regulation (EU) 2016/679, “Regulation”) and Data Protection legislation applicable, adopting principles based on fairness, legality and transparency, protecting your privacy and your rights, in accordance with the principles shown in this document.',
      id: 'vinhood.b2c.privacyPolicy.dataControllerDesc2',
    },
    dataControllerHeading: {
      defaultMessage: 'Data Controller',
      id: 'vinhood.b2c.privacyPolicy.dataControllerHeading',
    },
    dataSharingContextHeading: {
      defaultMessage: 'With whom and in which contexts do we share your data',
      id: 'vinhood.b2c.privacyPolicy.dataSharingContextHeading',
    },
    dataSharingContextSub1: {
      defaultMessage:
        'Your personal data will be processed by parties which have been duly appointed processors or processing agents, authorized to perform such tasks and which have adopted security measures specifically for ensuring your privacy and for preventing unlawful access by third parties or unauthorized personnel. These include third-party payment service providers that we use to allow you to purchase our experiences.',
      id: 'vinhood.b2c.privacyPolicy.dataSharingContextSub1',
    },
    dataSharingContextSub2: {
      defaultMessage:
        'Your personal data, whenever provided, and always with your consent, as well as the limits strictly linked to the obligations, the tasks and the purposes outlined above, may be shared with companies, organizations or individuals other than VINHOOD.',
      id: 'vinhood.b2c.privacyPolicy.dataSharingContextSub2',
    },
    dataSharingContextSub3: {
      defaultMessage:
        'Your personal data may be shared with Competent Authorities for the purposes of preventing, investigating or prosecuting crimes, in compliance with legal provisions governing such matters.',
      id: 'vinhood.b2c.privacyPolicy.dataSharingContextSub3',
    },
    dataSharingContextSub4: {
      defaultMessage:
        'The updated list of all Processors is available at the VINHOOD offices and may be requested at the following e-mail address: [privacy@vinhood.com](mailto:privacy@vinhood.com). This list may be supplemented and/or updated as required.',
      id: 'vinhood.b2c.privacyPolicy.dataSharingContextSub5',
    },
    dataWeCollectDesc1: {
      defaultMessage:
        'Data provided and shared on VINHOOD social media pages: We collect and process personal data directly provided by you and shared on social media pages belonging to VINHOOD (Facebook and Instagram, etc.) and for whose management you should refer to the third parties providing such services. We collect and process, for example, for the sole purpose of improving our communication on social media and for giving you a customized experience, the following data: likes, comments, images and, in general, all content and information that may be posted within this context.',
      id: 'vinhood.b2c.privacyPolicy.dataWeCollectDesc1',
    },
    dataWeCollectHeading: {
      defaultMessage: 'Data we collect automatically when you use our services',
      id: 'vinhood.b2c.privacyPolicy.dataWeCollectHeading',
    },
    dataWeCollectSub: {
      defaultMessage:
        'We use so-called cookies for collecting some data. To find out more and to learn about the various types of cookies and to manage your preferences, please read the relevant',
      id: 'vinhood.b2c.privacyPolicy.dataWeCollectSub',
    },
    dataWeDontProcessDesc1: {
      defaultMessage:
        'Special category data: We will never ask for, and we never process your data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data and biometric data for the purpose of uniquely identifying a natural person, as well as data regarding sex life and sexual orientation.',
      id: 'vinhood.b2c.privacyPolicy.dataWeDontProcessDesc1',
    },
    dataWeDontProcessDesc2: {
      defaultMessage: `Children's data: our services, including the App, are not intended for children and VINHOOD, therefore, does not intend to collect children's personal data.`,
      id: 'vinhood.b2c.privacyPolicy.dataWeDontProcessDesc2',
    },
    dataWeDontProcessHeading: {
      defaultMessage: 'Data that we do not process',
      id: 'vinhood.b2c.privacyPolicy.dataWeDontProcessHeading',
    },
    dataWeProcessDesc1: {
      defaultMessage:
        'Data regarding your preferences (for example, food that you like to match with a certain type of produce; fruit or vegetables that you like, etc.):',
      id: 'vinhood.b2c.privacyPolicy.dataWeProcessDesc1',
    },
    dataWeProcessDesc2: {
      defaultMessage:
        'When you take a test on the VINHOOD App (taste test, test for experts or matching with other products) and obtain a #character corresponding to your taste profile, we collect your answers.',
      id: 'vinhood.b2c.privacyPolicy.dataWeProcessDesc2',
    },
    dataWeProcessDesc3: {
      defaultMessage:
        'To find out more about your tastes, we may occasionally ask you for additional information as part of our initiatives. For example, we might ask you if you are a beginner or an expert on a specific product, ask for your comments and feedback on our tests, products and events, or ask if you are interested in other products. The data is used to provide you with an experience that is constantly enhanced, and to ensure your enjoyment.',
      id: 'vinhood.b2c.privacyPolicy.dataWeProcessDesc3',
    },
    dataWeProcessDesc4: {
      defaultMessage:
        'Personal and contact data used to create your taste profile, become part of the VINHOOD world and to make the most of our tools and services: the basic data you provide us with to create this profile is: first name, last name, email. You may also create your profile using social media. This, based on the consents you have given to the social media that you are using, gives us the possibility to have access to additional personal data to the information required to register for the VINHOOD App.',
      id: 'vinhood.b2c.privacyPolicy.dataWeProcessDesc4',
    },
    dataWeProcessDesc5: {
      defaultMessage:
        'Personal and contact details for sending promotional communications regarding our Services: when you voluntarily sign up to receive our newsletter or give your consent to receiving marketing and promotional communications from VINHOOD regarding our services, for the sole purpose of sending you such communications by e-mail or postal mail, we collect and process the following data that you give us: name, last name and e-mail.',
      id: 'vinhood.b2c.privacyPolicy.dataWeProcessDesc5',
    },
    dataWeProcessHeading: {
      defaultMessage: 'Which data we process only if you provide them to us on a voluntary basis',
      id: 'vinhood.b2c.privacyPolicy.dataWeProcessHeading',
    },
    decidePersonalDataHeading: {
      defaultMessage: 'You decide whether to provide us with your personal data',
      id: 'vinhood.b2c.privacyPolicy.decidePersonalDataHeading',
    },
    decidePersonalDataSub1: {
      defaultMessage:
        'Giving your Personal Data for using the services, for creating a personal profile, for managing contact requests, for sending marketing/promotional communications, for telling you about our events/workshops, to be disclosed to third-party partners is always optional. However, you should be aware that failure to give your data may adversely affect the chance to use some Services, for example, you may not create a personal profile.',
      id: 'vinhood.b2c.privacyPolicy.decidePersonalDataSub1',
    },
    decidePersonalDataSub2: {
      defaultMessage:
        'You may revoke your consent to the sending of marketing/promotional communications, profiling and the sharing of your data with our business partners at any time and without providing any reason. The easiest way of doing this is by clicking the “unsubscribe” link which can be found in every newsletter or communication that you receive, or by editing your preferences directly from the VINHOOD App where you can choose if you want to receive promotional communications and what kind they will be. Or, alternatively, you may send a message to: [privacy@vinhood.com](mailto:privacy@vinhood.com). If you withdraw your consent to receiving sales/promotional communications, this will not affect the validity of the communications sent to you up to that time.',
      id: 'vinhood.b2c.privacyPolicy.decidePersonalDataSub2',
    },
    endDescription: {
      defaultMessage:
        'This Privacy Policy was published in July 2022. Any updates will always be published on this page.',
      id: 'vinhood.b2c.privacyPolicy.endDescription',
    },
    exerciseRightsHeading: {
      defaultMessage: 'How you can exercise your rights',
      id: 'vinhood.b2c.privacyPolicy.exerciseRightsHeading',
    },
    exerciseRightsSub: {
      defaultMessage:
        'To exercise the abovementioned rights, you may send a message to the e-mail address: [privacy@vinhood.com](mailto:privacy@vinhood.com), showing in the message header the wording “Privacy - exercising rights”.',
      id: 'vinhood.b2c.privacyPolicy.exerciseRightsSub',
    },
    heading: {
      defaultMessage: 'Privacy Policy',
      id: 'vinhood.b2c.privacyPolicy.heading',
    },
    headingDescription: {
      defaultMessage: `TASTE S.r.l. is a company incorporated and operating under Italian law based in Milan, Piazza della Repubblica 32, (“VINHOOD”), owner of the website [www.vinhood.com](http://www.vinhood.com), mobile applications or other solutions that you will find on the VINHOOD website or at third parties. This privacy policy refers to the processing of your data when you use the VINHOOD mobile App (hereinafter referred to as VINHOOD App).`,
      id: 'vinhood.b2c.privacyPolicy.description',
    },
    methodProcessDataHeading: {
      defaultMessage: 'What is the method used for processing your data',
      id: 'vinhood.b2c.privacyPolicy.methodProcessDataHeading',
    },
    methodProcessDataSub1: {
      defaultMessage:
        'Processing of your data is performed using both hardcopy supports and data transmission technology, with the aid of modern IT systems and using manual methods, only by authorized persons who have been expressly appointed for this task.',
      id: 'vinhood.b2c.privacyPolicy.methodProcessDataSub1',
    },
    methodProcessDataSub2: {
      defaultMessage:
        'Processing will be performed adopting rationales and organizational forms that are strictly linked to the obligations and tasks or other purposes that we have explained in detail in the preceding points.',
      id: 'vinhood.b2c.privacyPolicy.methodProcessDataSub2',
    },
    methodProcessDataSub3: {
      defaultMessage:
        'We use precise technical and organizational measures for protecting your data from any tampering, loss and destruction and for preventing unauthorized access. Security measures are constantly monitored and improved, also based on advancements in technology.',
      id: 'vinhood.b2c.privacyPolicy.methodProcessDataSub3',
    },
    nonEuDataTransferDesc1: {
      defaultMessage:
        'Ensuring that the country to which Data provided by you will be sent guarantees an adequate level of protection, as provided under article 45 of the Regulation;',
      id: 'vinhood.b2c.privacyPolicy.nonEuDataTransferDesc1',
    },
    nonEuDataTransferDesc2: {
      defaultMessage:
        'Monitoring and making transfers only in the event that we, or the Processor, can provide appropriate safeguards for enforcing your rights and effective legal remedies (in accordance with article 46 of the Regulation).',
      id: 'vinhood.b2c.privacyPolicy.nonEuDataTransferDesc2',
    },
    nonEuDataTransferHeading: {
      defaultMessage: 'The transfer of personal data to non-EU countries',
      id: 'vinhood.b2c.privacyPolicy.nonEuDataTransferHeading',
    },
    nonEuDataTransferSub1: {
      defaultMessage: 'All processing of your data is performed in Italy and in European Union Member States.',
      id: 'vinhood.b2c.privacyPolicy.nonEuDataTransferSub1',
    },
    nonEuDataTransferSub2: {
      defaultMessage: 'Whenever a need may rise to transfer your data to non-EU countries, we are committed to:',
      id: 'vinhood.b2c.privacyPolicy.nonEuDataTransferSub2',
    },
    privacyImportantToUsDesc1: {
      defaultMessage:
        'We are aware that by using our services and entrusting us with your data you are placing your trust in us. The responsibility and duty to protect your data is our top priority.',
      id: 'vinhood.b2c.privacyPolicy.privacyImportantToUsDesc1',
    },
    privacyImportantToUsDesc2: {
      defaultMessage:
        'Please read our Privacy Policy, which is applicable each time you access and browse the “ VINHOOD App” and whenever you decide to use our services.',
      id: 'vinhood.b2c.privacyPolicy.privacyImportantToUsDesc2',
    },
    privacyImportantToUsDesc3: {
      defaultMessage:
        'This policy allows you to better understand which data and information we collect, why we collect them and how to update, manage, export and erase the data and information you have provided us.',
      id: 'vinhood.b2c.privacyPolicy.privacyImportantToUsDesc3',
    },
    privacyImportantToUsHeading: {
      defaultMessage: 'Your privacy is important to us',
      id: 'vinhood.b2c.privacyPolicy.privacyImportantToUsHeading',
    },
    retainYourDataDesc1: {
      defaultMessage:
        'Your personal data provided for signing up on the App and for using the relevant services, after ten years from the time the registration’s deletion;',
      id: 'vinhood.b2c.privacyPolicy.retainYourDataDesc1',
    },
    retainYourDataDesc2: {
      defaultMessage:
        'Your personal data processed for the purpose of sending information of a business and marketing nature, invites to our events/workshops, as well as for taking part in surveys and market research, including personalized studies, based on your profiling, after five years from the time of collection, or up to the time of any withdrawal of consent or objection which you may send expressly at any time.',
      id: 'vinhood.b2c.privacyPolicy.retainYourDataDesc2',
    },
    retainYourDataHeading: {
      defaultMessage: 'For how long do we process and retain your data',
      id: 'vinhood.b2c.privacyPolicy.retainYourDataHeading',
    },
    retainYourDataSub1: {
      defaultMessage: 'We warrant to erase from our systems:',
      id: 'vinhood.b2c.privacyPolicy.retainYourDataSub1',
    },
    retainYourDataSub2: {
      defaultMessage:
        'In any event, we warrant to base data processing on principles of adequacy and minimization (we only process data which we actually need to meet the processing purposes previously explained), and we will check annually the need for retaining data for a period longer than required for satisfying the purposes for which data were collected and processed.',
      id: 'vinhood.b2c.privacyPolicy.retainYourDataSub2',
    },
    retainYourDataSub3: {
      defaultMessage:
        'We may retain your data for the purposes of legal compliance or for ascertaining, exercising or defending a right in court.',
      id: 'vinhood.b2c.privacyPolicy.retainYourDataSub3',
    },
    retainYourDataSub4: {
      defaultMessage:
        'Once the purposes have been satisfied for which your personal data were collected and processed, we will take suitable measures to render them anonymous to prevent identification of the data subject, however, without prejudice to the option of using personal data in anonymous form.',
      id: 'vinhood.b2c.privacyPolicy.retainYourDataSub4',
    },
    whatWeUseDataForDesc1: {
      defaultMessage:
        'To create your personal profile on the VINHOOD App: we use your data so that you can create your personal profile via the VINHOOD App, our services or by using an existing social media account. The legal basis for processing is set out in article 6, para. (1)(b) of the Regulation, for performing pre-contractual measures to which the data subject is a party.',
      id: 'vinhood.b2c.privacyPolicy.whatWeUseDataForDesc1',
    },
    whatWeUseDataForDesc2: {
      defaultMessage: `To send you marketing/promotional communications and invitations to our events/workshops based on your #character (taste passport): your personal data is used, if you have given your explicit consent, to send you invitations and for telling you about our events, for sending you promotional communications about our activities (including new studies and surveys, etc.) to recommend products linked to your #character and to send you market research and statistical studies. The legal basis for processing is set out in article 6(a) of the Regulation, that is, the data subject's consent. You may revoke your consent at any time. The communications that we will send you are based on low-level profiling, which allows us to send you communications that are consistent with your “taste passport” (go to the section named “USE OF AUTOMATED DECISION-MAKING PROCESS”) to find out more. The legal basis for low-level profiling is article 6, para. 1, f) of the Regulation, that is, a legitimate interest of the Controller.`,
      id: 'vinhood.b2c.privacyPolicy.whatWeUseDataForDesc2',
    },
    whatWeUseDataForDesc3: {
      defaultMessage:
        'Sharing your data with business partners to be able to send marketing communications: your personal data will be shared, only if you have given your explicit consent, with our business partners, identified from time to time explicitly in the consent request, in order to receive communications and updates on the products, events and initiatives of these companies. The legal basis for processing is set out in article 6(a) of the Regulation, that is, the data subject’s consent. You may revoke your consent at any time.',
      id: 'vinhood.b2c.privacyPolicy.whatWeUseDataForDesc3',
    },
    whatWeUseDataForDesc4: {
      defaultMessage: `Creating your personalized profile based on your #character for the purpose of sending personalized communications and newsletters based on your #character, your habits, and your location: for sending personalized marketing communications, we create and categorize your personal profile based on the #character. Profiling is also carried out through the use of proprietary and third-party cookies, which allow us to evaluate your preferences based on your activity on the VINHOOD App. `,
      id: 'vinhood.b2c.privacyPolicy.whatWeUseDataForDesc4',
    },
    whatWeUseDataForDesc4Continue: {
      defaultMessage: `Lawfulness for processing is set out in article 6 a) of the Regulation, that is, the data subject's consent. You may revoke your consent at any time.`,
      id: 'vinhood.b2c.privacyPolicy.whatWeUseDataForDesc4Continue',
    },
    whatWeUseDataForHeading: {
      defaultMessage: 'What we use your data for',
      id: 'vinhood.b2c.privacyPolicy.whatWeUseDataForHeading',
    },
    whatWeUseDataForSub: {
      defaultMessage: 'We collect your data for the following purposes:',
      id: 'vinhood.b2c.privacyPolicy.whatWeUseDataForSub',
    },
    yourRightsDesc1: {
      defaultMessage: 'Right to information;',
      id: 'vinhood.b2c.privacyPolicy.yourRightsDesc1',
    },
    yourRightsDesc2: {
      defaultMessage: 'Right to access of the Data Subject;',
      id: 'vinhood.b2c.privacyPolicy.yourRightsDesc2',
    },
    yourRightsDesc3: {
      defaultMessage: 'Right to rectification;',
      id: 'vinhood.b2c.privacyPolicy.yourRightsDesc3',
    },
    yourRightsDesc4: {
      defaultMessage: 'Right to erasure (right to be forgotten);',
      id: 'vinhood.b2c.privacyPolicy.yourRightsDesc4',
    },
    yourRightsDesc5: {
      defaultMessage: 'Right to restriction of processing;',
      id: 'vinhood.b2c.privacyPolicy.yourRightsDesc5',
    },
    yourRightsDesc6: {
      defaultMessage: 'Right to data portability;',
      id: 'vinhood.b2c.privacyPolicy.yourRightsDesc6',
    },
    yourRightsDesc7: {
      defaultMessage: 'Right to objection;',
      id: 'vinhood.b2c.privacyPolicy.yourRightsDesc7',
    },
    yourRightsDesc8: {
      defaultMessage: 'Right to object to automated decision-making processes, including profiling.',
      id: 'vinhood.b2c.privacyPolicy.yourRightsDesc8',
    },
    yourRightsHeading: {
      defaultMessage: 'Your rights: you can find out about and manage your data at any time',
      id: 'vinhood.b2c.privacyPolicy.yourRightsHeading',
    },
    yourRightsSub1: {
      defaultMessage: 'You are guaranteed the following rights under article 15 et seq. of the Regulation:',
      id: 'vinhood.b2c.privacyPolicy.yourRightsSub1',
    },
    yourRightsSub2: {
      defaultMessage:
        'If you feel that the processing of your personal data has been performed in an unlawful manner, you may lodge a complaint with one of the supervisory authorities responsible for monitoring data protection compliance. In Italy, a complaint may be lodged with the Garante per la Protezione dei Dati Personali (Italian Data Protection Authority) ([http://www.garanteprivacy.it/](http://www.garanteprivacy.it/)).',
      id: 'vinhood.b2c.privacyPolicy.yourRightsSub2',
    },
  }),
  characterCard: defineMessages({
    title: {
      defaultMessage: '{productCategoryText} which character is #{characterName}',
      id: 'vinhood.b2c.characterCard.title',
    },
    discoverMore: {
      defaultMessage: 'Discover more',
      id: 'vinhood.b2c.characterCard.discoverMore',
    },
    btn: {
      defaultMessage: '{productCategoryText} trials',
      id: 'vinhood.b2c.characterCard.btn',
    },
  }),
  profilePageContainer: defineMessages({
    legalTabText: {
      defaultMessage: 'Legal',
      id: 'vinhood.b2c.profile.tabs.legalTabText',
    },
    settingsTabText: {
      defaultMessage: 'Settings',
      id: 'vinhood.b2c.profile.tabs.settingsTabText',
    },
    tagsTabText: {
      defaultMessage: 'Tags',
      id: 'vinhood.b2c.profile.tabs.myTagsTabText',
    },
    tasteTabText: {
      defaultMessage: 'Taste',
      id: 'vinhood.b2c.profile.tabs.tasteTabText',
    },
  }),
  registrationContainer: defineMessages({
    profilingDesc: {
      defaultMessage: `When you come across content that interests you, it's not magic. It's this right here. The information you provide helps the algorithm to build a profile that matches who you are. To better manage it, we enlist the help of third-party tools with greater expertise than us.`,
      id: 'vinhood.b2c.registrationProfilingModal.profilingDesc',
    },
    profilingTitle: {
      defaultMessage: `Profiling`,
      id: 'vinhood.b2c.registrationProfilingModal.profilingTitle',
    },
  }),
  profilePictureSelection: defineMessages({
    profileHeaderText: {
      defaultMessage: 'Profile Photo',
      id: 'vinhood.b2c.settingPage.profileHeaderText',
    },
    profileImageInfoText: {
      defaultMessage: '.jpg or .png - max 5Mb',
      id: 'vinhood.b2c.settingPage.profileImageInfoText',
    },
    profileImageInvalidErrorText: {
      defaultMessage: "The uploaded image is not the correct format. Let's try again?",
      id: 'vinhood.b2c.settingPage.profileImageInvalidErrorText',
    },
    profileLargeImageErrorText: {
      defaultMessage: 'The uploaded image is too large. Try again.',
      id: 'vinhood.b2c.settingPage.profileErrorText',
    },
  }),
  registrationCtaModal: defineMessages({
    btnText: {
      defaultMessage: 'Sign up now',
      id: 'vinhood.b2c.registrationCTAModal.btnText',
    },
    titleText: {
      defaultMessage: 'Unlock your highly personalized training plan.',
      id: 'vinhood.b2c.registrationCTAModal.titleText',
    },
  }),
  registrationWithIcon: defineMessages({
    title: {
      defaultMessage: 'Rate, Save, Note & much more...',
      id: 'vinhood.b2c.registrationWithIcon.title',
    },
    description: {
      defaultMessage: 'Save this product in your Taste ID, take your personal notes and rate the product',
      id: 'vinhood.b2c.registrationWithIcon.description',
    },
    extraDescription: {
      defaultMessage: 'Create an account now',
      id: 'vinhood.b2c.registrationWithIcon.extraDescription',
    },
  }),
  registrationProfilingModal: defineMessages({
    btnConfirm: {
      defaultMessage: 'Save and continue',
      id: 'vinhood.b2c.registrationProfilingModal.btnConfirm',
    },
    cancelBtn: {
      defaultMessage: 'Cancel',
      id: 'vinhood.b2c.registrationProfilingModal.cancelBtn',
    },
    headerTitle: {
      defaultMessage:
        "If you want to receive personalized notifications, then you must first agree to the profiling in the Cookies section. It's just a formality",
      id: 'vinhood.b2c.registrationProfilingModal.headerTitle',
    },
  }),
  renderModalOptions: defineMessages({
    errorPreferenceText: {
      defaultMessage:
        'If you want to receive personalized notifications, then you must first agree to the profiling in the Cookies section. It’s just a formality',
      id: 'vinhood.b2c.preferenceModal.errorPreferenceText',
    },
  }),
  searchResult: defineMessages({
    resultCardBtn: {
      defaultMessage: 'Give it a peek!',
      id: 'vinhood.b2c.searchResultCard.resultCardBtn',
    },
  }),
  productPage: defineMessages({
    beCoolCardBtnText: {
      defaultMessage: 'Contact the producer',
      id: 'vinhood.b2c.productPage.beCoolCard.beCoolCardBtnText',
    },
    beCoolCardTitle: {
      defaultMessage: 'This one looks good',
      id: 'vinhood.b2c.productPage.beCoolCard.beCoolCardTitle',
    },
    tabCharacteristicsText: {
      defaultMessage: 'Characteristics',
      id: 'vinhood.b2c.productPage.tabCharacteristicsText',
    },
    tabTastingText: {
      defaultMessage: 'Tasting',
      id: 'vinhood.b2c.productPage.tabTastingText',
    },
    chefRecommendsSubtitleText: {
      defaultMessage:
        '{ productCategory, select, wine {The Perfect Pairings for this } beer {The Perfect Pairings for this } coffee {The Perfect Pairings for this } other {}}{productCategoriesText}',
      id: 'vinhood.b2c.productPage.tasting.chefRecommendsSubtitleText',
    },
    chefRecommendsTitleText: {
      defaultMessage: 'The Chef Recommends',
      id: 'vinhood.b2c.productPage.tasting.chefRecommendsTitleText',
    },
    coffeePreparationsSubtitleText: {
      defaultMessage: 'Read the details to better understand the coffee',
      id: 'vinhood.b2c.productPage.tasting.coffeePreparationsSubtitleText',
    },
    coffeePreparationsTitleText: {
      defaultMessage: 'Preparations',
      id: 'vinhood.b2c.productPage.tasting.coffeePreparationsTitleText',
    },
    recipePairedClassicText: {
      defaultMessage: 'Paired With a Classic',
      id: 'vinhood.b2c.productPage.tasting.recipePairedClassicText',
    },
    sustainabilitySubtitleText: {
      defaultMessage:
        '{ productCategory, select, wine {Why This} beer {Why This} coffee {Why This} other{}} {productCategoriesText} is Special',
      id: 'vinhood.b2c.productPage.tasting.sustainabilitySubtitleText',
    },
    sustainabilityTitleText: {
      defaultMessage: 'Giving Credit Where Credit is Due',
      id: 'vinhood.b2c.productPage.tasting.sustainabilityTitleText',
    },
    essenceCardTitle: {
      defaultMessage:
        '{productCategory, select, wine {The ABCs of this } beer {The ABCs of this } coffee {The ABCs of this } other{}}{productCategoriesText}',
      id: 'vinhood.b2c.productPage.characteristics.essenceCardTitleText',
    },
    essenceCardSubtitle: {
      defaultMessage: 'Read About Its Characteristics',
      id: 'vinhood.b2c.productPage.characteristics.essenceCardSubtitleText',
    },
    identityCardTitle: {
      defaultMessage: 'Unique Features',
      id: 'vinhood.b2c.productPage.characteristics.identityCardTitleText',
    },
    identityCardSubtitle: {
      defaultMessage:
        '{ productCategory, select, wine {Facts about this} beer {Facts about this} coffee {Facts about this} other {}} {productCategoriesText}',
      id: 'vinhood.b2c.productPage.characteristics.identityCardSubtitleText',
    },
  }),

  socialWelcomePage: defineMessages({
    buttonText: {
      defaultMessage: 'Start the test!',
      id: 'vinhood.b2c.welcome.buttonText',
    },
    headerDescriptionText: {
      defaultMessage:
        'The full experience is just around the corner, but we need to start with the **Taste Test** to recommend you personalized products, experiences and content.',
      id: 'vinhood.b2c.welcome.headerDescriptionText',
    },
    headerTitleText: {
      defaultMessage: 'The World of Taste is just a click away!',
      id: 'vinhood.b2c.welcome.headerTitleText',
    },
  }),
  socialWelcomePageContainer: defineMessages({
    profilingDesc: {
      defaultMessage: `When you come across content that interests you, it's not magic. It's this right here. The information you provide helps the algorithm to build a profile that matches who you are. To better manage it, we enlist the help of third-party tools with greater expertise than us.`,
      id: 'vinhood.b2c.registrationProfilingModal.profilingDesc',
    },
    profilingTitle: {
      defaultMessage: `Profiling`,
      id: 'vinhood.b2c.registrationProfilingModal.profilingTitle',
    },
  }),
  startPage: defineMessages({
    btnTakeTest: {
      defaultMessage: 'START THE TEST',
      id: 'vinhood.b2c.startpage.btnTakeTest',
    },
    subtitleText: {
      defaultMessage: `Science fiction? No, it's right here.`,
      id: 'vinhood.b2c.startpage.subtitleText',
    },
    title: {
      defaultMessage: 'There is a test that helps you find the best products for you.',
      id: 'vinhood.b2c.startpage.title',
    },
    loginLinkText: {
      defaultMessage: `Log in!`,
      id: `vinhood.b2c.startpage.login`,
    },
  }),
  tasteTabContainer: defineMessages({
    sectionDescription: {
      defaultMessage: `You'll find your #Characters here. They determine a substantial part of your taste experience. Manage them carefully!`,
      id: 'vinhood.b2c.profile.taste.sectionDescription',
    },
    sectionTitle: {
      defaultMessage: 'Taste',
      id: 'vinhood.b2c.profile.taste.sectionTitle',
    },
  }),
  settings: defineMessages({
    accountSubTitle: {
      defaultMessage: 'Exit From the Device',
      id: 'vinhood.b2c.profile.settings.accountSubTitle',
    },
    accountTitle: {
      defaultMessage: 'Account',
      id: 'vinhood.b2c.profile.settings.accountTitle',
    },
    exitBtnText: {
      defaultMessage: 'Exit',
      id: 'vinhood.b2c.profile.settings.exitBtnText',
    },
    foodPreferencesSubTitle: {
      defaultMessage: 'Manage your Customary Behaviour',
      id: 'vinhood.b2c.profile.settings.foodPreferencesSubTitle',
    },
    foodPreferencesTitle: {
      defaultMessage: 'Food Preferences',
      id: 'vinhood.b2c.profile.settings.foodPreferencesTitle',
    },
    interestsSubTitle: {
      defaultMessage: 'I would like content about',
      id: 'vinhood.b2c.profile.settings.interestsSubTitle',
    },
    interestsTitle: {
      defaultMessage: 'Interests',
      id: 'vinhood.b2c.profile.settings.interestsTitle',
    },
    manageBtnText: {
      defaultMessage: 'Manage',
      id: 'vinhood.b2c.profile.settings.manageBtnText',
    },
    manageTextDescription: {
      defaultMessage: 'You can permanently delete your Vinhood account, or temporarily deactivate it. You never know.',
      id: 'vinhood.b2c.profile.settings.manageTextDescription',
    },
    settingsSubTitle: {
      defaultMessage: 'Keep Your Profile Updated',
      id: 'vinhood.b2c.profile.settings.settingsSubTitle',
    },
    settingsTitle: {
      defaultMessage: 'Settings',
      id: 'vinhood.b2c.profile.settings.settingsTitle',
    },
  }),
  termsConditionsModal: defineMessages({
    appContentPropertyRightsDesc1: {
      defaultMessage:
        'App content, including, but not limited to, the #characters, the tests, the works, the audio tracks and videos, the images, the photographs, the music, the documents, the drawings, the figures, the logos and any and all other material, in whatever form, published on the App, including the menus, the web pages, the graphics, the colors, the formats, the tools, the characters and the App’s web design, the diagrams, the layout, the methods, the processes, the features and the software are protected by copyright and VINHOOD and any other holders of intellectual property rights. It is prohibited to reproduce, modify, duplicate, copy, distribute, sell and otherwise exploit the images and any content of the App unless VINHOOD has given prior written consent. It is equally prohibited to use any of the App’s content for commercial and/or advertising purposes.',
      id: 'vinhood.b2c.termsConditions.appContentPropertyRightsDesc1',
    },
    appContentPropertyRightsDesc2: {
      defaultMessage:
        'All the distinctive signs that distinguish the products/services published on the App are the registered trademarks of their respective owners and they are used by VINHOOD for the sole purpose of marking, describing and advertising the products/services published/on sale on the App.',
      id: 'vinhood.b2c.termsConditions.appContentPropertyRightsDesc2',
    },
    appContentPropertyRightsDesc3: {
      defaultMessage:
        'Any use whatsoever of the aforesaid distinctive signs that is not in compliance with law and, as such, is not authorized, is prohibited. It is in no way permitted to use any distinctive sign appearing on the App for exploiting the distinctive character for profit or unlawful gain, or reputation of such signs, or, in any way causing harm to such signs and their owners.',
      id: 'vinhood.b2c.termsConditions.appContentPropertyRightsDesc3',
    },
    appContentPropertyRightsDesc4: {
      defaultMessage:
        'Under no circumstances can the user tamper with, change, modify or adapt the App or the material made available by VINHOOD. The App is offered as is. Commercial use of the software, the App and/or any other content is strictly prohibited. The User may not bypass the technical limitations in the software; translate, decode, decompile or unbundle the software, nor attempt to trace the Software source code or create derivative works based on the Software and publish the software for allowing its duplication by others and rent, sub-license or lend the Software.',
      id: 'vinhood.b2c.termsConditions.appContentPropertyRightsDesc4',
    },
    appContentPropertyRightsHeading: {
      defaultMessage: '4. App content and intellectual property rights',
      id: 'vinhood.b2c.termsConditions.appContentPropertyRightsHeading',
    },
    exclusionLiabilityDesc1: {
      defaultMessage:
        'The App is provided as it is, without any guarantee of result or user satisfaction. The test results and/or the taste passport are merely indications VINHOOD is in no way responsible for the eventual interruptions in the provision of the App and/or service due to causes not directly attributable and/or beyond its technical control, such as malfunctions of the telematic or telephone network.',
      id: 'vinhood.b2c.termsConditions.exclusionLiabilityDesc1',
    },
    exclusionLiabilityDesc2: {
      defaultMessage:
        'Due to the personal nature of tastes, VINHOOD cannot in any way be held responsible for user satisfaction.',
      id: 'vinhood.b2c.termsConditions.exclusionLiabilityDesc2',
    },
    exclusionLiabilityDesc3: {
      defaultMessage:
        'VINHOOD is entitled to change, suspend and/or interrupt delivery of the App, at any time, in full or in part, at its sole discretion, also definitively, with no prior notice and without such action giving rise to any claim whatsoever for damages and/or reimbursement claimed from VINHOOD.',
      id: 'vinhood.b2c.termsConditions.exclusionLiabilityDesc3',
    },
    exclusionLiabilityDesc4: {
      defaultMessage:
        'VINHOOD will not recognize any guarantee other than that which is granted under these Standard Terms and Conditions: it is understood that the user uses the App under his/her full and sole liability and that use of the App is required to comply with all instructions given by VINHOOD.',
      id: 'vinhood.b2c.termsConditions.exclusionLiabilityDesc4',
    },
    exclusionLiabilityDesc5: {
      defaultMessage:
        'The user is authorized to use the App solely for personal use, not collectively, or for profit, and assumes full responsibility for any unauthorized use.',
      id: 'vinhood.b2c.termsConditions.exclusionLiabilityDesc5',
    },
    exclusionLiabilityDesc6: {
      defaultMessage:
        'VINHOOD does not guarantee in any way the accuracy and/or the actuality of the App’s content. VINHOOD will not be held responsible for any damages that may result to user from the use of the information and content included on the App.',
      id: 'vinhood.b2c.termsConditions.exclusionLiabilityDesc6',
    },
    exclusionLiabilityHeading: {
      defaultMessage: '5. Exclusion of liability',
      id: 'vinhood.b2c.termsConditions.exclusionLiabilityHeading',
    },
    finalProvisionDesc1: {
      defaultMessage: 'The Standard Terms and Conditions are governed by Italian law.',
      id: 'vinhood.b2c.termsConditions.finalProvisionDesc1',
    },
    finalProvisionDesc2: {
      defaultMessage:
        'Any dispute that may arise in relation to the Standard Terms and Conditions will be referred for adjudication to the Court and venue indicated by the User/consumer as his/her residence or domicile, whenever located within the territory of the Italian State.',
      id: 'vinhood.b2c.termsConditions.finalProvisionDesc2',
    },
    finalProvisionDesc3: {
      defaultMessage: 'Whenever user are non-consumers, the Courts of Milan will have sole jurisdiction.',
      id: 'vinhood.b2c.termsConditions.finalProvisionDesc3',
    },
    finalProvisionHeading: {
      defaultMessage: '6. Final provisions, applicable law and venue with jurisdiction',
      id: 'vinhood.b2c.termsConditions.finalProvisionHeading',
    },
    generalInfoAndScopeDesc1: {
      defaultMessage: 'These Standard Terms and Conditions apply to all services offered by VINHOOD through the App.',
      id: 'vinhood.b2c.termsConditions.generalInfoAndScopeDesc1',
    },
    generalInfoAndScopeDesc2: {
      defaultMessage:
        'The Standard Terms and Conditions may be changed at any point. Any amendments and/or new terms and conditions will be in force from the time they are published on the App. For this purpose, we invite all user to regularly access the App and check the most recent Standard Terms and Conditions.',
      id: 'vinhood.b2c.termsConditions.generalInfoAndScopeDesc2',
    },
    generalInfoAndScopeDesc3: {
      defaultMessage: 'Applicable Standard Terms and Conditions are those in force at the time the service is used.',
      id: 'vinhood.b2c.termsConditions.generalInfoAndScopeDesc3',
    },
    generalInfoAndScopeDesc4: {
      defaultMessage:
        'Whenever the user does not intend to accept the changes to the Standard Terms and Conditions, he/she is entitled to withdraw, giving notice by sending a recorded mail letter with return receipt or e-mail providing 14 days advance notice.',
      id: 'vinhood.b2c.termsConditions.generalInfoAndScopeDesc4',
    },
    generalInfoAndScopeDesc5: {
      defaultMessage:
        'Continued use of the service after the period shown in the previous paragraph has expired will be considered as an expression of the intent to accept the Standard Terms and Conditions.',
      id: 'vinhood.b2c.termsConditions.generalInfoAndScopeDesc5',
    },
    generalInfoAndScopeDesc6: {
      defaultMessage:
        'These Standard Terms and Conditions do not govern the sale of goods and/or the provision of services by parties other than VINHOOD that may be present on the App via links, banners or any other hypertext links. Before entering into commercial transactions with said parties, the user is strongly advised to check their terms and conditions of sale. VINHOOD is not liable in any way whatsoever for the provision of services and/or the sale of goods by such parties. VINHOOD does not check and/or monitor websites which can be consulted via said links. Therefore, VINHOOD is not liable for the content of said websites or for any errors and/or omissions and/or breaches of laws by such websites.',
      id: 'vinhood.b2c.termsConditions.generalInfoAndScopeDesc6',
    },
    generalInfoAndScopeHeading: {
      defaultMessage: '1. General information and scope of application',
      id: 'vinhood.b2c.termsConditions.generalInfoAndScopeHeading',
    },
    generalInfoAndScopeSubHeading: {
      defaultMessage:
        'We advise you to print a copy of the Standard Terms and Conditions and/or store them on a durable medium.',
      id: 'vinhood.b2c.termsConditions.generalInfoAndScopeSubHeading',
    },
    heading: {
      defaultMessage: 'Terms and Conditions',
      id: 'vinhood.b2c.termsConditions.heading',
    },
    headingDescription: {
      defaultMessage: `The present standard terms and conditions (hereinafter “Standard Terms and Conditions”) govern the access to and the use of the Vinhood app (hereinafter the “App”) provided by TASTE s.r.l based in Milan, Piazza della Repubblica 32, VAT No. IT09388380967 (hereinafter “VINHOOD”) through the App.`,
      id: 'vinhood.b2c.termsConditions.description',
    },
    registrationDesc1: {
      defaultMessage:
        'You can decide to create a personal profile for registering your #character and creating your taste portfolio. In this case, you need to register on the App, by creating a VINHOOD account. Failure to accept the Standard Terms and Conditions will mean that you may not register on the App.',
      id: 'vinhood.b2c.termsConditions.registrationDesc1',
    },
    registrationDesc2: {
      defaultMessage:
        'It is free to register for the App. To register, the user must complete the registration form, entering first name, last name, e-mail address and a password (“Registration Credentials”). Registration is confirmed via an e-mail sent to the e-mail address provided by the user. You can also create a profile by using your Facebook profile, your Google Account or your Apple Account.',
      id: 'vinhood.b2c.termsConditions.registrationDesc2',
    },
    registrationDesc3: {
      defaultMessage:
        'Registration credentials must be used exclusively by the user and they may not be given to others. The user is required to promptly notify VINHOOD if unauthorized use of Registration Credentials is suspected. Registration Credentials can be changed by the user at any time, by accessing the App in the user profile section.',
      id: 'vinhood.b2c.termsConditions.registrationDesc3',
    },
    registrationDesc4: {
      defaultMessage:
        'The user guarantees that the Registration Credentials provided during the registration procedure in the App are complete, correct and accurate. The user accepts to indemnify and hold VINHOOD harmless from any and all claims for damages or fines arising out of and/or in any way in connection with a breach by the user of the App registration rules. The user is solely responsible for accessing the App using the Registration Credentials and responds directly for any and all loss or harm caused to VINHOOD or to third parties due to improper use and loss and misappropriation by others or failure to adequately protect the non-disclosure of Registration Credentials. All operations carried out using the Registration Credentials are considered to have been carried out by the user to whom the Credentials refer.',
      id: 'vinhood.b2c.termsConditions.registrationDesc4',
    },
    registrationDesc5: {
      defaultMessage: 'Services are provided in Italian and English.',
      id: 'vinhood.b2c.termsConditions.registrationDesc5',
    },
    registrationDesc6: {
      defaultMessage:
        'VINHOOD is entitled, at its discretion, to refuse the registration of any user, for security purposes. VINHOOD also reserves the right to block a user’s account if they act in violation of these Standard Terms and Conditions or applicable law.',
      id: 'vinhood.b2c.termsConditions.registrationDesc6',
    },
    registrationDesc7: {
      defaultMessage:
        'The user may at any time delete his/her VINHOOD registration by sending an e-mail to the address: [info@VINHOOD.com](mailto:info@VINHOOD.com).',
      id: 'vinhood.b2c.termsConditions.registrationDesc7',
    },
    registrationHeading: {
      defaultMessage: '2. Registration',
      id: 'vinhood.b2c.termsConditions.registrationHeading',
    },
    serviceSuspensionDesc1: {
      defaultMessage:
        'VINHOOD is entitled to temporarily suspend the service, without prior notice and delivery of the App and its services for the time strictly required for technical work necessary and/or opportune for improving the quality of such services.',
      id: 'vinhood.b2c.termsConditions.serviceSuspensionDesc1',
    },
    serviceSuspensionDesc2: {
      defaultMessage:
        'VINHOOD may, at any time, interrupt the provision of service, for any justified security reasons or breaches of data protection, notifying the user accordingly.',
      id: 'vinhood.b2c.termsConditions.serviceSuspensionDesc2',
    },
    serviceSuspensionHeading: {
      defaultMessage: '3. Service Suspension',
      id: 'vinhood.b2c.termsConditions.serviceSuspensionHeading',
    },
    whatisVhHeading: {
      defaultMessage: 'What is Vinhood?',
      id: 'vinhood.b2c.termsConditions.whatisVhHeading',
    },
    whatisVhHeadingDesc: {
      defaultMessage:
        '“VINHOOD” is an App that helps you have fun, learn about and deepen your relationship with the World of Taste. How do we do this? We provide you with a range of customized tools and experiences available in our App, such as tests, games and other content (hereinafter “Services”) which, based on a number of characteristics, such as, type of product, the #character of your taste profile, behavior, your degree of product knowledge and your interests are used to help you explore and interact with the World of Taste in an enjoyable, knowledgeable and customized way and, above all, within everybody’s reach. We help you create your Taste profile passport that you will find useful each time you interact with the world of taste. Therefore, you may use your Taste profile each time you need to, both on websites and on third-party VINHOOD partner platforms.',
      id: 'vinhood.b2c.termsConditions.whatisVhHeadingDesc',
    },
    whatisVhHeadingDesc1: {
      defaultMessage:
        'You may take different types of tests, based on scientific algorithms, for identifying your Taste profile on various product categories, as a starting point leading to new experiences we offer you in the World of Taste.',
      id: 'vinhood.b2c.termsConditions.whatisVhHeadingDesc1',
    },
    whatisVhHeadingDesc2: {
      defaultMessage:
        'We identify the #characters of your Taste profile to create your “taste passport” and to provide you with suggestions and services matching your profile',
      id: 'vinhood.b2c.termsConditions.whatisVhHeadingDesc2',
    },
    whatisVhHeadingDesc3: {
      defaultMessage:
        'You may use various channels (apps, websites, widgets and kiosks) to develop and to enrich your innovative and customized experience',
      id: 'vinhood.b2c.termsConditions.whatisVhHeadingDesc3',
    },
    whatisVhHeadingDesc4: {
      defaultMessage:
        'We will provide you with exclusive content and you may take part in customized events that we will suggest based on your #character of Taste profile, the level of knowledge you have about the products, and your behavior and interests',
      id: 'vinhood.b2c.termsConditions.whatisVhHeadingDesc4',
    },
    whatisVhHeadingDesc5: {
      defaultMessage:
        'You can suggest content related to the World of Taste and tell us what you think about our products or our services.',
      id: 'vinhood.b2c.termsConditions.whatisVhHeadingDesc5',
    },
  }),
  useB2cBannersText: defineMessages({
    completeProfileText: {
      defaultMessage: 'Complete your profile. Set your profile to increase the accuracy of the content displayed.',
      id: 'vinhood.b2c.myhome.completeProfileText',
    },
    completeTasteTestText: {
      defaultMessage: `**Something's missing... Do you feel like taking the tests on the other products? Come on, that way you'll have your ultimate Taste Identity.**`,
      id: 'vinhood.b2c.myhome.completeTasteTestText',
    },
    multiProductResultBannerText: {
      defaultMessage: `Everything is ready, the table is set, the wine is uncorked and decanted. If you don't sign up quick, we'll start without you!`,
      id: 'vinhood.b2c.resultPage.multiProductResultBannerText',
    },
    profilePageTasteTabBannerText: {
      defaultMessage: `Do you feel like your #Character has changed? It happens. Our taste is constantly evolving. That's why you can take the test again any time you want.`,
      id: 'vinhood.b2c.profile.taste.bannerText',
    },
    recipeBannerText: {
      defaultMessage: 'If you do not find your recipe you could try to start searching from the main ingredient',
      id: 'vinhood.b2c.recipeSearch.bannerText',
    },
  }),
  userCharacterInfo: defineMessages({
    tasteIdentityText: {
      defaultMessage: 'Your **Taste Identity**',
      id: 'vinhood.b2c.userCharacterInfo.tasteIdentityText',
    },
  }),
  userCharacterInfoItem: defineMessages({
    characterInfoTitle: {
      defaultMessage: '**#{characterName}** {productCategoryText}',
      id: 'vinhood.b2c.userCharacterInfo.characterInfoTitle',
    },
    missingBeerExpereince: {
      defaultMessage: 'You are missing the beer experience!',
      id: 'vinhood.b2c.userCharacterInfo.missingBeerExpereince',
    },
    missingCharacterTitle: {
      defaultMessage: 'Take the test!',
      id: 'vinhood.b2c.userCharacterInfo.missingCharacterTitle',
    },
    missingCoffeeExpereince: {
      defaultMessage: 'You are missing the coffee experience!',
      id: 'vinhood.b2c.userCharacterInfo.missingCoffeeExpereince',
    },
    missingWineExpereince: {
      defaultMessage: 'You are missing the wine experience!',
      id: 'vinhood.b2c.userCharacterInfo.missingWineExpereince',
    },
  }),
  userCharacterPersonalizeSection: defineMessages({
    personalizeExperienceDesc: {
      defaultMessage: `Select the values that you prefer: we'll become great friends and we'll only suggest content that's in line with your interests.`,
      id: 'vinhood.b2c.userCharacterInfo.personalizeExperienceDesc',
    },
    personalizeExperienceText: {
      defaultMessage: 'Personalize your experience',
      id: 'vinhood.b2c.userCharacterInfo.personalizeExperienceText',
    },
  }),
  userInfoForm: defineMessages({
    cancelBtnText: {
      defaultMessage: 'Cancel',
      id: 'vinhood.b2c.profile.settings.cancelBtnText',
    },
    countryFieldTitle: {
      defaultMessage: 'Country You Live In',
      id: 'vinhood.b2c.profile.settings.form.countryFieldTitle',
    },
    countryValidationText: {
      defaultMessage: 'Select a country',
      id: 'vinhood.b2c.profile.settings.form.countryValidationText',
    },
    emailFieldTitle: {
      defaultMessage: 'Email',
      id: 'vinhood.b2c.profile.settings.form.emailFieldTitle',
    },
    emailValidationText: {
      defaultMessage: 'Enter correct email',
      id: 'vinhood.b2c.profile.settings.form.emailValidationText',
    },
    lastNameFieldTitle: {
      defaultMessage: 'Last Name',
      id: 'vinhood.b2c.profile.settings.form.lastNameFieldTitle',
    },
    lastNameValidationText: {
      defaultMessage: 'Last Name should be at least two characters.',
      id: 'vinhood.b2c.profile.settings.form.lastNameValidationText',
    },
    nameFieldTitle: {
      defaultMessage: 'Name',
      id: 'vinhood.b2c.profile.settings.form.nameFieldTitle',
    },
    nameValidationText: {
      defaultMessage: 'First Name should be at least two characters.',
      id: 'vinhood.b2c.profile.settings.form.nameValidationText',
    },
    saveBtnText: {
      defaultMessage: 'Save',
      id: 'vinhood.b2c.profile.settings.saveBtnText',
    },
    birthYearText: {
      defaultMessage: 'Birth Year',
      id: 'vinhood.b2c.profile.settings.form.birthYearText',
    },
    genderText: {
      defaultMessage: 'Gender',
      id: 'vinhood.b2c.profile.settings.form.genderText',
    },
    selectText: {
      defaultMessage: 'Select',
      id: 'vinhood.b2c.profile.settings.form.select',
    },
  }),
  qrScannerModal: defineMessages({
    notValidCodeErrorText: {
      defaultMessage: `Please scan a valid code!`,
      id: 'vinhood.b2c.qrScannerModal.notValidCodeErrorText',
    },
    permissionDeniedErrorText: {
      defaultMessage: `Please allow to use the camera. ||**You can go to your browser settings - privacy settings - manage permissions and enable camera access for Vinhood App.**`,
      id: 'vinhood.b2c.qrScannerModal.permissionDeniedErrorText',
    },
  }),
  loadingScreen: defineMessages({
    textOne: {
      defaultMessage: `Just a sec.`,
      id: 'vinhood.b2c.loadingScreen.textOne',
    },
    textTwo: {
      defaultMessage: `Turning your responses into a scrumptious outcome 🤤`,
      id: 'vinhood.b2c.loadingScreen.textTwo',
    },
  }),
};
