import styled, { css, keyframes } from 'styled-components';

import { IToastPlacement, IToastType } from '@components/web/src/atoms/Toast/ToastMessage';
import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { ROOT_ELEMENT_KIOSK } from '@components/web/src/foundations/consts';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const getColor = (type: IToastType) => {
  switch (type) {
    case 'success':
      return `${STATIC_COLORS.green['400']}`;
    case 'warning':
      return `${STATIC_COLORS.warning['400']}`;
    case 'error':
      return `${STATIC_COLORS.error['600']}`;
    default:
      return `${STATIC_COLORS.azure['600']}`;
  }
};

interface IToastProps {
  duration: number | false;
  autoClose: boolean;
  type: IToastType;
  position: IToastPlacement;
}

export const ToastContainer = styled.div<IToastProps>`
  position: absolute;
  ${({ position }) =>
    position === 'top'
      ? css`
          top: 0;
        `
      : css`
          bottom: 0;
        `}
  width: 100%;
  max-width: 600px;
  z-index: 99999;
  animation:
    ${fadeIn} 0.5s,
    ${fadeOut} 0.5s ${({ duration, autoClose }) => (duration && autoClose ? `${duration}ms` : '0')} forwards;
`;

export const ToastContent = styled.div<IToastProps>`
  display: flex;
  gap: 16px;
  background: ${STATIC_COLORS.base.white};
  box-shadow: ${STATIC_SHADOWS.md};
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  animation:
    ${fadeIn} 0.5s,
    ${fadeOut} 0.5s ${({ duration, autoClose }) => (duration && autoClose ? `${duration}ms` : '0')} forwards;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      width: 56px;
      height: 56px;
    `}
  svg {
    width: 100%;
  }
`;

export const TextContainer = styled.div<IToastProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${({ type }) => getColor(type)};
`;

export const CloseButton = styled.button`
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const ProgressBar = styled.div<IToastProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7px;
  background: ${STATIC_COLORS.base.white};
  ${({ theme }) =>
    theme?.rootElementType === ROOT_ELEMENT_KIOSK &&
    css`
      height: 10px;
    `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ type }) => getColor(type)};
    animation: progress ${({ duration }) => `${duration}ms`} linear forwards;
  }

  @keyframes progress {
    from {
      width: 100%;
    }
    to {
      width: 0;
    }
  }
`;
