import Skeleton from '@components/web/src/components/Skeleton/Skeleton';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const RecipeItemSkeleton = () => {
  return (
    <Flexbox isFullWidth>
      <Skeleton styles={{ height: '66px', width: '100px' }} />
      <Skeleton styles={{ height: '30px', marginBlock: 'auto' }} />
    </Flexbox>
  );
};

export default RecipeItemSkeleton;
