import { css, styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const SublistItemsList = styled.div<{ $isUpperCase: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
  background: ${STATIC_COLORS.base.white};

  ${({ $isUpperCase }) =>
    $isUpperCase &&
    css`
      .item-title {
        text-transform: uppercase;
      }
    `}

  ${({ theme }) => {
    if (theme?.isKioskRootElementType) {
      return css`
        flex-flow: row wrap;
        gap: 16px;
        padding: 16px;
      `;
    }
    return css`
      padding: 0;
    `;
  }}
`;

export const SublistItem = styled.p<{ $variant?: string; $isActive?: boolean; $isRatingItem?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 26px 20px 24px;
  border-top: 1px solid ${STATIC_COLORS.warmGray['300']};
  margin: 0px;

  ${({ $isRatingItem }) =>
    $isRatingItem &&
    css`
      border: none !important;
      padding: 0px !important;
    `}

  ${({ $variant, $isActive }) =>
    $variant === 'rounded' &&
    css`
      gap: 22px;
      border: 1px solid ${STATIC_COLORS.warmGray['400']};
      border-radius: 99px;

      ${$isActive &&
      css`
        background-color: ${STATIC_COLORS.teal['25']};
        border: 1px solid ${STATIC_COLORS.teal['200']};
      `}
    `}
`;

export const SublistRatingList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background-color: ${STATIC_COLORS.base.white};
`;

export const TitleText = styled(Text)`
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const SublistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${STATIC_COLORS.warmGray['300']};
`;

export const SublistContainer = styled.div<{ $isOpened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  cursor: pointer;
  background: ${STATIC_COLORS.warmGray['100']};

  ${({ $isOpened }) =>
    $isOpened &&
    css`
      background: ${STATIC_GRADIENTS.warmGradient()};
    `}
`;

export const SublistButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 1px solid ${STATIC_COLORS.teal['600']};
  border-radius: 50%;
`;

export const Icon = styled.div<{ $isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${STATIC_COLORS.base.white};
  border: none;
  border-radius: 50%;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background-color: ${STATIC_COLORS.teal['300']};

      svg {
        path {
          stroke: ${STATIC_COLORS.base.white};
        }
      }
    `}
`;
