import styled, { css } from 'styled-components';

import { SCREEN_SIZE } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ProductListContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0;
  gap: 24px;
  width: 100%;
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      padding: 32px 16px;
    `}

  .infinite-scroll-component__outerdiv {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .infinite-scroll-component {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1080px;
    width: 100%;
    gap: 24px;

    @media (min-width: ${SCREEN_SIZE.medium}) {
      grid-template-columns: repeat(3, 1fr);
      gap: 32px 16px;
    }

    @container rootElementExperience (min-width: 500px) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }
`;

export const ProductCardWrapper = styled(Flexbox)`
  width: 100%;
  ${({ theme }) =>
    theme?.isExperienceRootElementType &&
    css`
      padding: 0 16px;
    `}
`;
