import { styled } from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';

export const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px 16px 32px;
  background-color: var(--color-secondary-100);
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 16px;

  img {
    position: absolute;
    top: 25px;
    width: 225px;
    height: 225px;
  }
`;

export const BackgroundIcon = styled.div<{ $backgroundColor?: string }>`
  width: 225px;
  height: 225px;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor || STATIC_COLORS.teal[50]};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LinkButton = styled(Button)<{ $isDeleteDataVariant?: boolean }>`
  justify-content: start;
  padding: ${({ $isDeleteDataVariant }) => ($isDeleteDataVariant ? '0' : '10px 0')};

  span {
    text-align: left;
    color: ${STATIC_COLORS.base.black};
    font-weight: ${({ $isDeleteDataVariant }) => $isDeleteDataVariant && 500};

    strong {
      text-decoration: underline;
      font-weight: ${({ $isDeleteDataVariant }) => ($isDeleteDataVariant ? 700 : 500)};
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
