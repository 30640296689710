import classNames from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { isAppInIframe } from '@lib/tools/comms/utils';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';
import Portal from '@lib/tools/views/web/components/Portal';

import VinhoodHeader from '@components/web/src/app/HeaderApp/HeaderApp';
import CloseComponent from '@components/web/src/atoms/CloseComponent/CloseComponent';
import RootElement from '@components/web/src/atoms/RootElement/rootElement';

type ModalProps = {
  isOpen: boolean;
  children: ReactNode;
  modalClassName?: string;
  portalClassname?: string;
  hideModal?: () => void;
  fullWidthModal?: boolean;
  defaultPadding?: boolean;
  isAllowContentScrolling?: boolean;
  maxContentHeight?: boolean;
};

const ModalWrapper = ({
  isOpen = true,
  children,
  hideModal,
  modalClassName,
  portalClassname,
  fullWidthModal = false,
  defaultPadding,
  isAllowContentScrolling = false,
  maxContentHeight = false,
}: ModalProps) => {
  const modalRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { retailerLanguages } = useRetailer();
  const { locale } = useApp();

  useEffect(() => {
    const rootElement = document.querySelector('#root') as HTMLDivElement;
    if (isOpen && rootElement && !isAllowContentScrolling) {
      rootElement.style.overflowY = 'hidden';
      rootElement.style.height = '100vh';
    }
    return () => {
      if (rootElement) {
        rootElement.style.overflowY = 'unset';
        rootElement.style.height = '100%';
      }
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const onModalClickHandler = e => {
    const checkDiv = document.getElementById('b2c-modal-wrap');
    const closeModal = e.target === checkDiv;
    // close modal if backdrop is clicked
    if (closeModal && hideModal) hideModal();
  };

  const onHeaderIconClick = () => {
    navigate(prependBasename(PAGES.vinhood.home));
    hideModal();
  };

  return (
    <Portal className={classNames(portalClassname, 'app-modal-overlay')}>
      <RootElement>
        <div
          ref={modalRef}
          id="b2c-modal-wrap"
          role="presentation"
          className={classNames(
            'app-modal',
            modalClassName,
            { 'app-modal-full': fullWidthModal },
            { 'max-content-modal': maxContentHeight },
            { 'default-padding': defaultPadding },
          )}
          onClick={e => onModalClickHandler(e)}
          onKeyDown={onModalClickHandler}
        >
          <div
            className={classNames('modal-wrapper', {
              'full-width-modal-wrapper': fullWidthModal,
              isInIframe: isAppInIframe,
            })}
          >
            {fullWidthModal && (
              <VinhoodHeader
                className="fixed-header"
                locale={locale}
                retailerLanguages={retailerLanguages}
                onHeaderIconClick={onHeaderIconClick}
              />
            )}
            <div className={classNames('content', { 'full-width-content': fullWidthModal })}>{children}</div>
            {fullWidthModal && <CloseComponent handleCloseClick={hideModal} isVinhoodApp={isDesignSetVinhoodApp} />}
          </div>
        </div>
      </RootElement>
    </Portal>
  );
};

export default ModalWrapper;
