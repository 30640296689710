import { useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers, isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { useApp } from '@lib/core/service/hooks';
import { useUser } from '@lib/core/users/hooks';
import { CHARACTERS_URL_PARAM } from '@lib/tools/shared/helpers/consts';

import CharacterDetailsComponent from '@components/web/src/components/CharacterDetails/CharacterDetails';
import CharacterDetailsSkeleton from '@components/web/src/components/Skeleton/CharacterDetailsSkeleton/CharacterDetailsSkeleton';
import * as S from '@components/web/src/pages/widget/Catalog/styles';

const CatalogContainerHOC = ({ children }) => {
  const { isFetchingUserData } = useUser();

  const { locale } = useApp();
  const { productCategory } = useApp();

  const [searchParams] = useSearchParams();

  const { characters } = useCharacters();
  const characterStringFromUrl: string | null = searchParams.get(CHARACTERS_URL_PARAM);
  const { userCharactersIds } = useUser();

  const translatedUserCharacterData = characters
    .filter(character => isCharacterByIdentifiers(character, userCharactersIds))
    .find(character => isCharacterByServiceProductCategory(character));

  return (
    <S.CatalogContainerHoc data-testid={`CatalogContainerHOC-${productCategory}`}>
      {isFetchingUserData && !characterStringFromUrl && <CharacterDetailsSkeleton productCategory={productCategory} />}
      {!isFetchingUserData && !characterStringFromUrl && translatedUserCharacterData && (
        <CharacterDetailsComponent character={translatedUserCharacterData} locale={locale} />
      )}
      {children}
    </S.CatalogContainerHoc>
  );
};

export default CatalogContainerHOC;
