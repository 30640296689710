/* eslint-disable sort-keys-fix/sort-keys-fix */
import { defineMessages } from '@formatjs/intl';

export const localeWidget = {
  homepagePourtButtonText: defineMessages({
    wine: {
      defaultMessage: "Let's uncork them!",
      id: 'vh.vinhood_experience.home_page_pour_button.wine',
    },
    beer: {
      defaultMessage: "Let's pour them!",
      id: 'vh.vinhood_experience.home_page_pour_button.beer',
    },
    coffee: {
      defaultMessage: "Let's pour them!",
      id: 'vh.vinhood_experience.home_page_pour_button.coffee',
    },
  }),
  homePage: defineMessages({
    modalLogoutDeleteData: {
      defaultMessage: 'Even the best love stories end: you are going to delete all your profile data.',
      id: 'vh.vinhood_experience.start_page.modal_logout.modalLogoutDeleteData',
    },
    modalLogoutDeleteDataBtn: {
      defaultMessage: 'Delete all my data',
      id: 'vh.vinhood_experience.start_page.modal_logout.modalLogoutDeleteDataBtn',
    },
    defaultTitle: {
      defaultMessage: 'The World of Taste is just behind the corner.',
      id: 'vh.vinhood_experience.start_page.defaultTitle',
    },
    anonTitle: {
      defaultMessage: 'Hello!',
      id: 'vh.vinhood_experience.start_page.anonTitle',
    },
    authTitle: {
      defaultMessage: 'Hello { userFirstName }!',
      id: 'vh.vinhood_experience.start_page.authTitle',
    },
    defaultSubtitle: {
      defaultMessage: `But first, let's understand your **Taste ID in less than 45 seconds**`,
      id: 'vh.vinhood_experience.start_page.defaultSubtitle',
    },
    characterSubtitle: {
      defaultMessage:
        'The perfect { productCategory, select, other {{ productCategoryPluralText }}} for your taste have a **#{ characterName } character**.',
      id: 'vh.vinhood_experience.start_page.characterSubtitle',
    },
    tasteChanged: {
      defaultMessage: 'Has your taste changed? **Try the test again!**',
      id: 'vh.vinhood_experience.start_page.tasteChanged',
    },
    alreadyRegistered: {
      defaultMessage: 'Already registered? **Log in!**',
      id: 'vh.vinhood_experience.start_page.alreadyRegistered',
    },
    startTest: {
      defaultMessage: 'Start the test',
      id: 'vh.vinhood_experience.start_page.startTest',
    },
    typeRecipe: {
      defaultMessage: `Let’s do a food pairing`,
      id: 'vh.vinhood_experience.start_page.typeRecipe',
    },
    deleteYourData: {
      defaultMessage: `Do you want to **delete your data?** ||Don't worry: friends as before.`,
      id: 'vh.vinhood_experience.start_page.deleteYourData',
    },
    seeMy: {
      defaultMessage: 'Go to the catalog',
      id: 'vh.vinhood_experience.start_page.seeMy',
    },
  }),
  startPage: defineMessages({
    title: {
      defaultMessage:
        'Discover your taste profile, {productCategory, select, wine {your perfect {productCategoryPluralText} and how to pair them with your recipes} beer {your perfect {productCategoryPluralText} and how to pair them with your recipes} bread {your perfect {productCategoryPluralText} and how to pair them with your recipes} coffee {your perfect {productCategoryPluralText} and how to pair them with your recipes} other{}}.',
      id: 'vh.vinhood_experience.start_page.title',
    },
    subTitle: {
      defaultMessage:
        'VINHOOD will guide you through the world of {productCategory, select, wine {{productCategoryPluralText}} beer {{productCategoryPluralText}} bread {{productCategoryPluralText}} coffee {{productCategoryPluralText}} other {}} with advice and recommendations that are always just a click away. We’ll save your profile at the end of the test, even if you don’t register, so that you can use it again next time!',
      id: 'vh.vinhood_experience.start_page.subTitle',
    },
    checkOutCookie: {
      defaultMessage: 'Check out our [linkTo=cookie-page]cookie policy[linkTo=cookie-page] to learn more.',
      id: 'vh.vinhood_experience.start_page.checkOutCookie',
    },
    btnText: {
      defaultMessage: 'Let’s get started!',
      id: 'vh.vinhood_experience.start_page.btnText',
    },
  }),
  productCatalog: defineMessages({
    pourCoffee: {
      defaultMessage: 'Pour your coffee',
      id: 'vh.vinhood_experience.product_catalog_page.pourCoffee',
    },
    pourBeer: {
      defaultMessage: 'Pour your beer',
      id: 'vh.vinhood_experience.product_catalog_page.pourBeer',
    },
    uncorkTheWineShort: {
      defaultMessage: 'Uncork the wine',
      id: 'vh.vinhood_experience.product_catalog_page.uncorkTheWineShort',
    },
    yourBestMatchIs: {
      defaultMessage: 'Your best match is:',
      id: 'vh.vinhood_experience.product_catalog_page.yourBestMatchIs',
    },
    allProductsByProximity: {
      defaultMessage:
        'Here are all {productCategory, select, beer {beers} wine {wines} coffee {coffees} other {}} sorted by proximity to your taste criteria.',
      id: 'vh.vinhood_experience.product_catalog_page.allWinesByProximity',
    },
    productsByProximity: {
      defaultMessage:
        'Here are {productCategory, select, beer {beers} wine {wines} coffee {coffees} other {}} sorted by proximity to your taste criteria.',
      id: 'vh.vinhood_experience.product_catalog_page.winesByProximity',
    },

    soManyProducts: {
      defaultMessage: 'So many products! Use filters to simplify your choice.',
      id: 'vh.vinhood_experience.product_catalog_page.soManyProducts',
    },
    seeOtherProducts: {
      defaultMessage: 'Other products perfect for you',
      id: 'vh.vinhood_experience.product_catalog_page.seeOtherProducts',
    },
  }),
  exitPage: defineMessages({
    title: {
      defaultMessage: 'Even the best love stories end: delete your profile.',
      id: 'vh.vinhood_experience.exit_page.title',
    },
    errorSubTitle: {
      defaultMessage: 'We are going to delete your Character.',
      id: 'vh.vinhood_experience.exit_page.errorSubTitle',
    },
    successSubTitle: {
      defaultMessage: 'We are going to delete all the information and data we have collected about you.',
      id: 'vh.vinhood_experience.exit_page.successSubTitle',
    },
    goBackBtnText: {
      defaultMessage: 'Go back',
      id: 'vh.vinhood_experience.exit_page.goBackBtnText',
    },
    deleteTasteProfileBtnText: {
      defaultMessage: 'Delete my taste profile',
      id: 'vh.vinhood_experience.exit_page.deleteBtnText',
    },
    goodByBtnText: {
      defaultMessage: 'Goodbye: open the app and delete your profile',
      id: 'vh.vinhood_experience.exit_page.goodbyeBtnText',
    },
  }),
  recognizedEmailPage: defineMessages({
    title: {
      defaultMessage: 'Hey!',
      id: 'vh.vinhood_experience.recognized_email_page.title',
    },
    subTitle: {
      defaultMessage: 'Look who is here!',
      id: 'vh.vinhood_experience.recognized_email_page.subTitle',
    },
    firstText: {
      defaultMessage: "It's really true that you always go back to where you have a good time!",
      id: 'vh.vinhood_experience.recognized_email_page.text',
    },
    secondText: {
      defaultMessage: 'Your profile is waiting for you just behind this button.',
      id: 'vh.vinhood_experience.recognized_email_page.secondText',
    },
    goToLogin: {
      defaultMessage: 'Go to login',
      id: 'vh.vinhood_experience.recognized_email_page.goToLogin',
    },
    preferNotToLoin: {
      defaultMessage: 'I prefer not to login now',
      id: 'vh.vinhood_experience.recognized_email.preferNoToLogin',
    },
  }),
  cookiePolicyPage: defineMessages({
    mainTitle: {
      defaultMessage: 'Cookie policy',
      id: 'vinhood.vinhood_experience.cookie_policy_page.mainTitle',
    },
    mainDescription: {
      defaultMessage:
        '**TASTE S.r.l.** is a company incorporated and operating under Italian law, with registered office in Milan, Piazza della Repubblica 32, Vinhood (the **“Controller”**, **“Us”** or **“VINHOOD”**), may be contacted at the e-mail address: privacy@vinhood.com. As data Controller, it provides you with the following information regarding the use of cookies on the widget that you will find on the VINHOOD Platform or at third parties’ websites (referred as **“Widget”**).',
      id: 'vinhood.vinhood_experience.cookie_policy_page.mainDescription',
    },
    whatAreCookiesTitle: {
      defaultMessage: '1. What are cookies and what cookie categories we use',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesTitle',
    },
    whatAreCookiesParagraph1: {
      defaultMessage:
        'Cookies are small text strings that the Platform you visit sends to your computer, smartphone tablet, etc., where they are stored and then resent to the Platform on your next visit.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph1',
    },
    whatAreCookiesParagraph2: {
      defaultMessage: 'Cookies used on this Platform may be identified using the following guidelines:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph2',
    },
    whatAreCookiesParagraph3: {
      defaultMessage: '**a) Based on who manages the cookies:**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph3',
    },
    whatAreCookiesParagraph4: {
      defaultMessage:
        '(i) Proprietary or first-party cookies: these are cookies directly installed by Us through the Widget hosted in the site;',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph4',
    },
    whatAreCookiesParagraph5: {
      defaultMessage:
        '(ii) Third-party cookies: these are cookies installed through the Widget on third parties’ site other than Us.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph5',
    },
    whatAreCookiesParagraph6: {
      defaultMessage: '**b) Based on the time they persist and remain active:**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph6',
    },
    whatAreCookiesParagraph7: {
      defaultMessage:
        '(i) Session cookies: these cookies store the information required during a browsing session, and they are deleted immediately after the session is closed, or after the browser is closed.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph7',
    },
    whatAreCookiesParagraph8: {
      defaultMessage:
        '(ii) Persistent cookies: these cookies are stored on your hard drive and the widget reads them each time you visit the widget again. A persistent cookie has a specific expiration date, after which it will stop working.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph8',
    },
    whatAreCookiesParagraph9: {
      defaultMessage: '**c) Based of the purposes:**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph9',
    },
    whatAreCookiesParagraph10: {
      defaultMessage:
        'Some cookies are necessary to allow the user to browse the Widget and use its features (so-called technical cookies). Others are used to obtain statistical information, in aggregate or not, on the number of users accessing the site and how the widget is used (so-called analytics cookies). Finally, others are used to track your profile in order to display advertisements that may be in line with your interests, based on your taste and consumption habits (so-called profiling cookies).',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph10',
    },
    whatAreCookiesParagraph11: {
      defaultMessage: 'Our Widget uses technical cookies, analytics cookies and profiling cookies.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph11',
    },
    whatAreCookiesParagraph12: {
      defaultMessage:
        'By changing the settings on the browser you use, you can decide at any time to block cookie installation, in whole or in part. The configuration of each browser is different and is described in the browser “Guide” menu. If you want to learn about cookie settings, you can consult the instructions provided by the most common browsers:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph12',
    },
    whatAreCookiesGoogleChrome: {
      defaultMessage: '**Google Chrome:** https://support.google.com/chrome/answer/95647?hl=it',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesGoogleChrome',
    },
    whatAreCookiesMozillaFirefox: {
      defaultMessage:
        '**Mozilla Firefox:** https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesMozillaFirefox',
    },
    whatAreCookiesInternetExplorer: {
      defaultMessage:
        '**Internet Explorer:** https://support.microsoft.com/it-it/topic/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesInternetExplorer',
    },
    whatAreCookiesOpera: {
      defaultMessage: '**Opera:** https://help.opera.com/en/latest/web-preferences/#cookies',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesOpera',
    },
    whatAreCookiesSafari: {
      defaultMessage: '**Safari:** https://support.apple.com/it-it/guide/safari/sfri11471/mac',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesSafari',
    },
    cookiesUsedTitle: {
      defaultMessage: '2. Cookies used by VINHOOD',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedTitle',
    },
    cookiesUsedParagraph1: {
      defaultMessage: '**Technical cookies**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph1',
    },
    cookiesUsedParagraph2: {
      defaultMessage:
        'Technical cookies are those that Vinhood uses for the sole purpose of allowing you to use the Widget and be able to enjoy it. They can be further divided into:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph2',
    },
    cookiesUsedParagraph3: {
      defaultMessage:
        '(i) Navigation cookies: they guarantee the normal navigation and usage of the Widget, allowing you, for example, to make a purchase or authenticate to access reserved areas.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph3',
    },
    cookiesUsedParagraph4: {
      defaultMessage:
        '(ii) Functionality cookies: allow you to browse, based on a series of selected features (for example, language or country of origin) for enhancing the service provided on the Platform. These are persistent cookies that are stored on the computer you use also after closing the web browser until the expiry date set for each cookie (normally from two months to two years) is reached or until you decide to remove them.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph4',
    },
    cookiesUsedParagraph5: {
      defaultMessage: 'Technical cookies are not used for purposes other than those described above.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph5',
    },
    cookiesUsedParagraph6: {
      defaultMessage: 'The following technical cookies are currently used on the Platform:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph6',
    },
    cookiesUsedParagraph7: {
      defaultMessage:
        'You can disable proprietary and third-party technical cookies by changing your browser settings. However, you should be aware that by removing or blocking these cookies, several of the websites you visit (or the relevant features) may not work properly or efficiently.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph7',
    },
    cookiesUsedParagraph8: {
      defaultMessage: '**Cookie Analytics**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph8',
    },
    cookiesUsedParagraph9: {
      defaultMessage:
        'Widget’s analytics cookies are not installed directly by Us, but by third parties and are used to collect statistical information, aggregated or non-aggregate form, on the number of users accessing the Widget and/or the site that hosts it and how they visit it.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph9',
    },
    cookiesUsedParagraph10: {
      defaultMessage:
        'The following third-party cookie analytics are installed on the Platform without your prior consent, because in they are less invasive, in that they and anonymized, preventing third parties from accessing unbundled analytics at IP address level (in other words, when using these cookies, third parties cannot cross-check data for tracing a user’s identity) and the third parties cannot cross-check data obtained using cookies with other data they already hold:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph10',
    },
    thirdPartyCookiesAnalytics: {
      defaultMessage: 'Third-party Cookie Analytics',
      id: 'vinhood.vinhood_experience.cookie_policy_page.thirdPartyCookiesAnalytics',
    },
    serviceName: {
      defaultMessage: 'Service name',
      id: 'vinhood.vinhood_experience.cookie_policy_page.serviceName',
    },
    purpose: {
      defaultMessage: 'Purpose',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purpose',
    },
    persistence: {
      defaultMessage: 'Persistence',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistence',
    },
    purposeGoogleAnalytics: {
      defaultMessage:
        'The service allows Us to understand how you interact with the Platform. This service, with no active advertising features and by hiding your IP address, uses a set of cookies for collecting information and generating statistics on Platform use.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeGoogleAnalytics',
    },
    persistenceGoogleAnalytics: {
      defaultMessage: '14 months',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistenceGoogleAnalytics',
    },
    cookiesUsedParagraph11: {
      defaultMessage:
        'You can decide to block the installation of cookie analytics at any time using the functionality on the site that hosts the Widget. For more information, please read the third party cookie analytics information carefully by following the links in the table above.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph11',
    },
    cookiesUsedParagraph12: {
      defaultMessage: '**Profiling cookies**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph12',
    },
    cookiesUsedParagraph13: {
      defaultMessage:
        'Profiling cookies are used to create your own profile, based on your preferences and interests, as manifested while browsing the Internet, so that you can view personalized content. As required by current privacy legislations, your prior consent is required for the installation of such cookies. For this reason, when you access the site that hosts our Widget a special banner is displayed, informing you that (i) profiling cookies are used and that (ii) you can give your consent to the installation of cookies by clicking a designated button inside the banner itself.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph13',
    },
    cookiesUsedParagraph14: {
      defaultMessage:
        'You are free to block the installation of profiling cookies at any time using the functionality on the site that hosts the Widget.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph14',
    },
    cookiesUsedParagraph15: {
      defaultMessage:
        'All profiling cookies are installed by third parties, acting as autonomous data controllers (third-party cookies). Here is a list of the third party profiling cookies currently used by the Widget, showing the link to the information pages created by their developers:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph15',
    },
    purposeGoogleAds1: {
      defaultMessage:
        'Use of cookies for making advertising campaigns more efficient and for displaying more relevant ads, based on their affinity with you, thanks to the record of Google partner websites that you have visited.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeGoogleAds1',
    },
    purposeGoogleAds2: {
      defaultMessage:
        'Though these data, it is possible to monitor the performance of advertising campaigns and calculate conversion rates.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeGoogleAds2',
    },
    persistenceGoogleAds: {
      defaultMessage: '540 days',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistenceGoogleAds',
    },
    purposeBrainLead: {
      defaultMessage:
        'Use of cookies to make you experience more engaging. A number of common cookie uses include content selection, based on what interests you, improving campaign performance reporting and the option of sending you customized content.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeBrainLead',
    },
    persistenceBrainLead: {
      defaultMessage: '13 months',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistenceBrainLead',
    },
    purposeFacebook1: {
      defaultMessage:
        'Use of cookies for showing ads to people that could be interested in the products and for measuring advertising campaign performance.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeFacebook1',
    },
    purposeFacebook2: {
      defaultMessage:
        'In addition, these data allow statistical data to be provided on people that use Facebook products, on people that interact with ads, advertiser websites and apps, and on companies that use Facebook products.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeFacebook2',
    },
    persistenceFacebook: {
      defaultMessage: '90 days',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistenceFacebook',
    },
    cookiesUsedParagraph16: {
      defaultMessage:
        'The Platform also features "social buttons", i.e. plugins for interacting with social networks and that allow you to share your information and content with social networks, to identify users and store information about the use of the Platform on their profiles. You can review the information regarding social media data management, using the social buttons which will take you to the following links:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph16',
    },
    cookiesUsedInstagram: {
      defaultMessage: '**Instagram** https://help.instagram.com/155833707900388',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedInstagram',
    },
    cookiesUsedLinkedin: {
      defaultMessage: '**Linkedin** https://www.linkedin.com/legal/cookie-policy',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedLinkedin',
    },
    cookiesUsedFacebook: {
      defaultMessage: '**Facebook** https://m.facebook.com/policies/cookies/',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedFacebook',
    },
    cookiesUsedYoutube: {
      defaultMessage: '**Youtube** https://policies.google.com/technologies/ ||cookies?hl=it',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedYoutube',
    },
    amendmentsUpdatesTitle: {
      defaultMessage: '3. Amendments and Updates',
      id: 'vinhood.vinhood_experience.cookie_policy_page.amendmentsUpdatesTitle',
    },
    amendmentsUpdatesParagraph1: {
      defaultMessage:
        'This Cookie Policy is valid from the effective date. The Controller may amend and/or supplement this cookie Policy, also as a result of amended and/or supplemented legislation.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.amendmentsUpdatesParagraph1',
    },
    btnBack: {
      defaultMessage: 'Back',
      id: 'vinhood.vinhood_experience.cookie_policy_page.btnBack',
    },
  }),
  privacyPolicyPage: defineMessages({
    mainTitle: {
      defaultMessage: 'Privacy policy',
      id: 'vinhood.vinhood_experience.privacy_policy_page.mainTitle',
    },
    mainDescription: {
      defaultMessage:
        'TASTE S.r.l. is a company under Italian Law, with registered office in Milan at Piazza della Repubblica 32, (hereinafter “VINHOOD”), data controller, amongst others, for a widget accessible via browsers and mobile devices, such as those available at VINHOOD kiosks (hereinafter “VINHOOD Platform”).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.mainDescription',
    },
    privacyImportantTitle: {
      defaultMessage: 'YOUR PRIVACY IS IMPORTANT TO US',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantTitle',
    },
    privacyImportantParagraph1: {
      defaultMessage:
        'We are aware that by using our services and entrusting us with your data you are placing your trust in us. The responsibility and duty to protect your data is our top priority.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph1',
    },
    privacyImportantParagraph2: {
      defaultMessage:
        'Please read our Privacy Policy which is applicable each time you access and browse the “ VINHOOD Platform” and whenever you decide to use our services.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph2',
    },
    privacyImportantParagraph3: {
      defaultMessage:
        'This policy allows you to better understand which data and information we collect, why we collect them and how to update, manage, export and erase the data and information you have provided us.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph3',
    },
    privacyImportantParagraph4: {
      defaultMessage:
        'To allow you to better understand the purpose and the way we process your data, we will start by telling you about us, and the scopes of “VINHOOD”, an innovative organization in the world of taste.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph4',
    },
    privacyImportantParagraph5: {
      defaultMessage:
        '“VINHOOD” helps you have fun while enhancing your relationship with the world of taste. How do we do this? We help you create your Taste profile passport that you will find useful each time you interact with the world of taste. Therefore, you may use your Taste profile each time you need to, both on websites and on third-party VINHOOD partner platforms.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph5',
    },
    privacyImportantItem1: {
      defaultMessage:
        'You may take different types of tests, based on algorithms studied together with professionals, for identifying your Taste profile on various product categories, as a starting point leading to new experiences we offer you in the world of taste.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem1',
    },
    privacyImportantItem2: {
      defaultMessage:
        'We identify the #characters of your Taste profile to create your “taste passport” and to provide you with suggestions and services matching your profile.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem2',
    },
    privacyImportantItem3: {
      defaultMessage:
        'You can use various channels (such as widgets and devices present at our kiosks) on third-party sites or in third-party stores to develop and enrich your innovative and personalized experience.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem3',
    },
    privacyImportantItem4: {
      defaultMessage:
        'We will provide you with exclusive content and you may take part in customized events that we will suggest to you based on the #character of your Taste profile, the level of knowledge you have about products, and your behavior and interests.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem4',
    },
    privacyImportantParagraph6: {
      defaultMessage:
        'As a VINHOOD user, you are entitled to manage your privacy and configure your privacy settings for monitoring which information we collect about you and how we use such information and, as a result, you may use our services adopting various modes.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph6',
    },
    privacyImportantParagraph7: {
      defaultMessage: 'Based on your privacy settings, you may, for example:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph7',
    },
    privacyImportantItem5: {
      defaultMessage:
        'Take our taste tests, discover your taste #character and sign up on the “VINHOOD Platform”, creating a profile linked to your #character, and use our services. Creating a profile linked to your #character allows us to recognize you and suggest you from time to time the services and produces suitable for you and your #character, depending on your environment and the experience you have chosen (e.g., test).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem5',
    },
    privacyImportantItem6: {
      defaultMessage:
        'Take our tests using the VINHOOD widget on third-party platforms and get customized recommendations about products and services without needing to access your account and, therefore, anonymously.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem6',
    },
    dataControllerTitle: {
      defaultMessage: 'DATA CONTROLLER',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataControllerTitle',
    },
    dataControllerParagraph1: {
      defaultMessage:
        'VINHOOD is the data controller responsible for processing your personal data (“Controller”), acting through its pro tempore legal representative, with business address in Milan, Piazza della Repubblica 32.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataControllerParagraph1',
    },
    dataControllerParagraph2: {
      defaultMessage:
        'In performing its activities, VINHOOD, as “Controller”, processes personal data in compliance with prevailing legislative provisions (Article 13 of Regulation (EU) 2016/679, “Regulation”) and Data Protection legislation applicable, adopting principles based on fairness, legality and transparency, protecting your privacy and your rights, in accordance with the principles shown in this document.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataControllerParagraph2',
    },
    dataProcessMainTitle: {
      defaultMessage: 'Data we process',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessMainTitle',
    },
    dataProcessTitle: {
      defaultMessage: 'DATA WE PROCESS ONLY IF YOU PROVIDE THEM TO US ON A VOLUNTARY BASIS',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessTitle',
    },
    dataProcessItem1: {
      defaultMessage:
        '**Personal and contact details for creating your Taste profile, for allowing you to become part of the VINHOOD world and for using our tools and services**:  When you have finished a test, we will send you the result via e-mail, if you ask us to do this in the special section of the VINHOOD Platform. It is the start of creating your Taste profile. The standard data you provide us for creating this profile are: name, last name and e-mail. You may also create your profile using social media networks. Based on the consents you have given to the social media networks you are using, this provides us the option of gaining access to additional personal data compared to those required on signing up on the VINHOOD Platform.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessItem1',
    },
    dataProcessItem2: {
      defaultMessage:
        '**Data regarding your preferences (for example, food that you like to match with a certain type of produce; fruit or vegetables that you like, etc.):**',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessItem2',
    },
    dataProcessSubItem1: {
      defaultMessage:
        'When you take a test on our VINHOOD Platform (taste test, test for experts or matching with other products) and obtain a #character corresponding to your taste profile, as the result, we collect your answers.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessSubItem1',
    },
    dataProcessSubItem2: {
      defaultMessage:
        'To find out more about your tastes, we may occasionally ask you for additional information as part of our initiatives. For example, we might ask you if you are a beginner or an expert on a specific product, ask for your comments and feedback on our tests, products and events, or ask if you are interested in other products.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessSubItem2',
    },
    dataProcessParagraph1: {
      defaultMessage: 'These data are processed only if you register on the Platform and create a VINHOOD profile.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessParagraph1',
    },
    dataCollectTitle: {
      defaultMessage: 'DATA WE COLLECT AUTOMATICALLY WHEN YOU USE OUR SERVICES',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataCollectTitle',
    },
    dataCollectParagraph1: {
      defaultMessage:
        'We use so-called cookies for collecting some data. To find out more and to learn about the various types of cookies and to manage your preferences, please read the relevant cookie policy https://www.vinhood.com/en/cookie-policy/.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataCollectParagraph1',
    },
    dataNotProcessTitle: {
      defaultMessage: 'DATA THAT WE DO NOT PROCESS',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataNotProcessTitle',
    },
    dataNotProcessItem1: {
      defaultMessage:
        '**Special category data:** We will never ask for, and we never process your data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data and biometric data for the purpose of uniquely identifying a natural person, as well as data regarding sex life and sexual orientation.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataNotProcessItem1',
    },
    dataNotProcessItem2: {
      defaultMessage:
        '**Children’s data:** our services, including the Platform, are not intended for children and VINHOOD, therefore, does not intend to collect children’s personal data.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataNotProcessItem2',
    },
    dataUseForTitle: {
      defaultMessage: 'WHAT DO WE USE YOUR DATA FOR',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForTitle',
    },
    dataUseForParagraph1: {
      defaultMessage: 'We collect your data for the following purposes:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForParagraph1',
    },
    dataUseForItem1: {
      defaultMessage:
        '**For enhancing your experience when you use our services:** We use your data so that you can benefit from our services offered through the VINHOOD Platform. Our services use persistent cookies (see https://www.vinhood.com/en/cookie-policy/) that allow your #character to be stored when you take the test. This feature is not available if you disable these cookies in the browser settings.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem1',
    },
    dataUseForItem2: {
      defaultMessage:
        '**For creating your personal profile on our Platform:** We use your data so that you can create your personalized profile on our Platform and use our services directly or via using an already existing social media account. The legal basis for processing is set out in article 6 (1)(b) of the Regulation, for performing pre-contractual measures to which the data subject is a party.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem2',
    },
    dataUseForItem3: {
      defaultMessage:
        '**For sending you marketing/promotional messages and invitations to our events/workshops:** your personal data are used, whenever you have given your express consent, for sending you invitations and for telling you about our events, for sending you promotional messages about our activities (including new studies and surveys, etc.) for recommending products with affinity to your #character and for sending you market research and statistical studies. The legal basis for processing is set out in article 6(a) of the Regulation, that is, the data subject’s consent. You may revoke your consent at any time.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem3',
    },
    dataUseForItem4: {
      defaultMessage:
        '**Sharing your data with third-party partners:** your data, and in particular your email address, may be communicated to third-party partners of VINHOOD, as identified from time to time, in order to receive promotional messages related to the activities and services of those partners. The legal basis for processing is set out in article 6(a) of the Regulation, that is, the data subject’s consent. You may revoke your consent at any time.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem4',
    },
    dataProvideTitle: {
      defaultMessage: 'YOU DECIDE WHETHER TO PROVIDE US WITH YOUR PERSONAL DATA',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProvideTitle',
    },
    dataProvideParagraph1: {
      defaultMessage:
        'Giving your Personal Data for using the services, for creating a personal profile, for sending marketing/promotional messages, for telling you about our events/workshops is always optional. However, you should be aware that failure to give your data may adversely affect the chance to use some Services, for example, you may not create a personal profile.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProvideParagraph1',
    },
    dataProvideParagraph2: {
      defaultMessage:
        'You may revoke your consent to the sending of marketing/promotional messages at any time and without providing any reason. The simplest way to do this is to click on the “Unsubscribe” link that can be found in each newsletter or message received. Or, alternatively, you may send a message to the address: privacy@vinhood.com. If you withdraw your consent to receiving sales/promotional mailings, this will not affect the validity of the mailings sent to you up to that time.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProvideParagraph2',
    },
    dataProcessingMethodTitle: {
      defaultMessage: 'WHAT IS THE METHOD USED FOR PROCESSING YOUR DATA',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessingMethodTitle',
    },
    dataProcessingMethodParagraph1: {
      defaultMessage:
        'Processing of your information is performed both using hardcopy supports and data transmission technology, with the aid of modern IT systems and using manual methods, only by authorized persons who have been expressly appointed for this task.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessingMethodParagraph1',
    },
    dataProcessingMethodParagraph2: {
      defaultMessage:
        'Processing will be performed adopting rationales and organizational forms that are strictly linked to the obligations and tasks or other purposes that we have explained in detail in the preceding points.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessingMethodParagraph2',
    },
    dataProcessingMethodParagraph3: {
      defaultMessage:
        'We use precise technical and organizational measures for protecting your data from any tampering, loss and destruction and for preventing unauthorized access. Security measures are constantly monitored and improved, also based on advancements in technology.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessingMethodParagraph3',
    },
    dataContextShareTitle: {
      defaultMessage: 'WITH WHOM AND IN WHICH CONTEXTS DO WE SHARE YOUR DATA',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareTitle',
    },
    dataContextShareParagraph1: {
      defaultMessage:
        'Your personal data will be processed by parties which have been duly appointed processors or processing agents, authorized to perform such tasks and which have adopted security measures specifically for ensuring your privacy and for preventing unlawful access by third parties or unauthorized personnel.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareParagraph1',
    },
    dataContextShareParagraph2: {
      defaultMessage:
        'Your personal data, whenever provided, and always with your consent, as well as the limits strictly linked to the obligations, the tasks and the purposes outlined above, may be shared with companies, organizations or individuals other than VINHOOD.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareParagraph2',
    },
    dataContextShareParagraph3: {
      defaultMessage:
        'Your personal data may be shared with Competent Authorities for the purposes of preventing, investigating or repressing crimes, in compliance with legal provisions governing such matters.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareParagraph3',
    },
    dataContextShareParagraph4: {
      defaultMessage:
        'The updated list of all Processors is available at the VINHOOD offices and may be requested at the following e-mail address: privacy@vinhood.com. This list may be supplemented and/or updated as required.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareParagraph4',
    },
    dataProcessRetainTitle: {
      defaultMessage: 'FOR HOW LONG DO WE PROCESS AND RETAIN YOUR DATA',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessRetainTitle',
    },
    dataProcessRetainParagraph1: {
      defaultMessage: 'We warrant to erase from our systems:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessRetainParagraph1',
    },
    dataProcessRetainItem1: {
      defaultMessage:
        'Your personal data provided for signing up on the Platform and for using the relevant services, after 10 (ten) years from the time the registration was deleted.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessRetainItem1',
    },
    dataProcessRetainItem2: {
      defaultMessage:
        'Your personal data processed for the purpose of sending information of a business and marketing nature, invites to our events/workshops, as well as for taking part in surveys and market research, including personalized studies, based on your profiling, after 5 (five) years from the time of collection, or up to the time of any withdrawal of consent or objection which you may send expressly at any time.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessRetainItem2',
    },
    dataProcessRetainParagraph2: {
      defaultMessage:
        'In any event, we warrant to base data processing on principles of adequacy and minimization (we only process data which we actually need to meet the processing purposes previously explained), and we will check annually the need for retaining data for a period longer than required for satisfying the purposes for which data were collected and processed.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessRetainParagraph2',
    },
    dataProcessRetainParagraph3: {
      defaultMessage:
        'We may retain your data for the purposes of legal compliance or for ascertaining, exercising or defending a right in court.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessRetainParagraph3',
    },
    dataProcessRetainParagraph4: {
      defaultMessage:
        'Once the purposes have been satisfied for which your personal data were collected and processed, we will take suitable measures to render them anonymous to prevent identification of the data subject, however, without prejudice to the option of using personal data in anonymous form.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessRetainParagraph4',
    },
    automatedProcessTitle: {
      defaultMessage: 'WE USE AUTOMATED DECISION-MAKING PROCESSES',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessTitle',
    },
    automatedProcessParagraph1: {
      defaultMessage:
        'As previously explained, we use low-level profiling of our users for the purpose of providing you with a customized service.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph1',
    },
    automatedProcessParagraph2: {
      defaultMessage:
        'Profiling is based on parameters, such as taste #characters, knowledge of products and actions taken on our Platform.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph2',
    },
    automatedProcessParagraph3: {
      defaultMessage:
        'Taste profiling is done using our algorithms that use predictive skills for identifying, for example, your #character in relation to a certain product category (e.g. wine, coffee, beer and bitter liqueurs, etc.) and for providing you with tips in line with your preferences about a certain product.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph3',
    },
    automatedProcessParagraph4: {
      defaultMessage:
        'Following such analyses, you may use the Platform Services customized for you and also receive personalized messages from us that we think are in line with your tastes and preferences (#character).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph4',
    },
    automatedProcessParagraph5: {
      defaultMessage:
        'We have successfully conducted a balancing test for the purpose of determining that processing linked to your categorization is conducted legally based on VINHOOD’s legitimate interest for obtaining maximum efficiency in creating a single “taste passport” and in marketing activities.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph5',
    },
    transferDataTitle: {
      defaultMessage: 'TRANSFER OF PERSONAL DATA TO THIRD COUNTRIES',
      id: 'vinhood.vinhood_experience.privacy_policy_page.transferDataTitle',
    },
    transferDataParagraph1: {
      defaultMessage:
        'All processing of your data is performed in Italy and in European Union Member States. Whenever a need may rise to transfer your data to Third Countries, we warrant to:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.transferDataParagraph1',
    },
    transferDataItem1: {
      defaultMessage:
        'Ensure that the State to which Data provided by you will be sent guarantees an adequate level of protection, as provided under article 45 of the Regulation.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.transferDataItem1',
    },
    transferDataItem2: {
      defaultMessage:
        'Monitor and make transfers only in the case that we, or the Processor, can provide appropriate safeguards for enforcing your rights and effective legal remedies (in accordance with article 46 of the Regulation).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.transferDataItem2',
    },
    yourRightsTitle: {
      defaultMessage: 'YOUR RIGHTS: YOU MAY FIND OUT ABOUT AND MANAGE YOUR DATA AT ANY TIME',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsTitle',
    },
    yourRightsParagraph1: {
      defaultMessage: 'You are guaranteed the following rights under article 15 et seq. of the Regulation:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsParagraph1',
    },
    yourRightsItem1: {
      defaultMessage: 'Right to information.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem1',
    },
    yourRightsItem2: {
      defaultMessage: 'Right to access of the Data Subject.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem2',
    },
    yourRightsItem3: {
      defaultMessage: 'Right to rectification.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem3',
    },
    yourRightsItem4: {
      defaultMessage: 'Right to erasure (right to be forgotten).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem4',
    },
    yourRightsItem5: {
      defaultMessage: 'Right to restriction of processing.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem5',
    },
    yourRightsItem6: {
      defaultMessage: 'Right to data portability.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem6',
    },
    yourRightsItem7: {
      defaultMessage: 'Right to object.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem7',
    },
    yourRightsParagraph2: {
      defaultMessage:
        'If you feel that the processing of your personal data has been performed in an unlawful manner, you may lodge a complaint with one of the supervisory authorities responsible for monitoring data protection compliance. In Italy, a complaint may be lodged with the Garante per la Protezione dei Dati Personali (Italian Data Protection Authority) (http://www.garanteprivacy.it/).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsParagraph2',
    },
    exerciseRightsTitle: {
      defaultMessage: 'HOW YOU CAN EXERCISE YOUR RIGHTS',
      id: 'vinhood.vinhood_experience.privacy_policy_page.exerciseRightsTitle',
    },
    exerciseRightsParagraph1: {
      defaultMessage:
        'To exercise the abovementioned rights, you may send a message to the e-mail address: privacy@vinhood.com, showing in the message header the wording “Privacy - exercising rights”.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.exerciseRightsParagraph1',
    },
    privacyPolicyParagraph1: {
      defaultMessage:
        'This Privacy Policy was published in May 2022. Any updates will always be published on this page.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyPolicyParagraph1',
    },
    btnBack: {
      defaultMessage: 'Back',
      id: 'vinhood.vinhood_experience.privacy_policy_page.btnBack',
    },
  }),
  termsConditionsPage: defineMessages({
    mainTitle: {
      defaultMessage: 'Terms and Conditions',
      id: 'vinhood.vinhood_experience.terms_conditions_page.mainTitle',
    },
    mainDescription: {
      defaultMessage:
        'These standard terms and conditions (“Standard Terms and Conditions”) govern access to and use of the Vinhood website, www.vinhood.com, including the App and the widget (“Platform”), supplied by TASTE s.r.l., with registered office in Milan, Piazza della Repubblica 32, tax code IT09388380967 ("VINHOOD") via the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.mainDescription',
    },
    conditionTitle1: {
      defaultMessage: 'GENERAL INFORMATION AND SCOPE OF APPLICATION',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle1',
    },
    condition1Paragraph1: {
      defaultMessage: 'These Standard Terms and conditions apply to all services offered by VINHOOD via the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph1',
    },
    condition1Paragraph2: {
      defaultMessage:
        'The Standard Terms and Conditions may be changed without notice. Any amendments and/or new terms and conditions will be in force from the time they are published on the Platform. For this purpose, we invite all users to regularly access the Platform and check the most recent Standard Terms and Conditions.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph2',
    },
    condition1Paragraph3: {
      defaultMessage: 'Applicable Standard Terms and Conditions are those in force at the time the service is used.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph3',
    },
    platformDescription: {
      defaultMessage:
        '“VINHOOD” is a Platform that helps you have fun and learn about and deepen your relationship with the Taste World. How do we do this? We make a range of tools and customized experiences available to you on our Platform, such as tests, games and other content (“Services”) which, based on a number of features, such as, type of product, the #character of your Taste profile, behavior, your degree of product knowledge and your interests are used to help you explore and interact with the Taste world in an enjoyable, knowledgeable and customized way and, above all, within everybody’s reach. We help you create your Taste profile passport that you will find useful each time you interact with the Taste world. Therefore, you may use your Taste profile each time you need to, both on websites and on third-party VINHOOD partner platforms.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescription',
    },
    condition1Paragraph4: {
      defaultMessage:
        'Whenever the user does not intend to accept the changes to the Standard Terms and Conditions, he/she is entitled to withdraw, giving notice by sending a recorded mail letter with return receipt or e-mail providing 14 days advance notice.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph4',
    },
    platformDescriptionParagraph1: {
      defaultMessage:
        'You may take different types of tests, based on scientific algorithms, for identifying your Taste profile on various product categories, as a starting point leading to new experiences we offer you in the Taste world.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph1',
    },
    condition1Paragraph5: {
      defaultMessage:
        'Continued use of the service after the term indicated in the previous paragraph has expired will be considered as an expression of the wish to accept the Standard Terms and Conditions.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph5',
    },
    platformDescriptionParagraph2: {
      defaultMessage:
        'We identify the #characters of your Taste profile to create your “taste passport” and to provide you with suggestions and services matching your profile.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph2',
    },
    condition1Paragraph6: {
      defaultMessage:
        'These Standard Terms and Conditions do not govern the sale of goods and/or the provision of services by parties other than VINHOOD that appear on the Platform via links, banners or any other hypertext link. Before entering into commercial transactions with said parties, the user is strongly advised to check their terms and conditions of sale. VINHOOD is not liable in any way whatsoever for the provision of services and/or sale of goods by such parties. VINHOOD does not check and/or monitor websites which can be consulted via said links. Therefore, VINHOOD is not liable for the content of said websites or for any errors and/or omissions and/or breaches of laws by such websites.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph6',
    },
    platformDescriptionParagraph3: {
      defaultMessage:
        'You may use various channels (apps, websites, widgets and kiosks, etc.) to enrich your innovative and customized experience.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph3',
    },
    condition2Paragraph1: {
      defaultMessage:
        'You can decide to create a personal profile for registering your #character and creating your taste portfolio. In this case, you need to register on the Platform, by creating a VINHOOD account. Failure to accept the Standard Terms and Conditions will mean that it is not possible to register on the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph1',
    },
    platformDescriptionParagraph4: {
      defaultMessage:
        'We will provide you with exclusive content and you may take part in customized events that we will suggest to you based on the #character of your Taste profile, the level of knowledge you have about products, and your behavior and interests.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph4',
    },
    condition2Paragraph2: {
      defaultMessage:
        'Registering on the Platform is free. To register, the user must complete the registration form, entering name, last name, e-mail address and a password (“Registration Credentials”). Registration is confirmed via an e-mail sent to the e-mil address provided by the user. You can also create a profile by using several social networks (Facebook, Instagram, etc.) or your Google Account.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph2',
    },
    platformDescriptionParagraph5: {
      defaultMessage:
        'You may suggest content to us linked to the Taste world and tell us what you think about our products or our services.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph5',
    },
    condition2Paragraph3: {
      defaultMessage:
        'Registration Credentials must be used exclusively by the user and they may not be transferred to others. The user is required to promptly notify VINHOOD if undue use of Registration Credentials is suspected. Registration Credentials may be changed by the user at any time, by accessing the Platform and the user profile section.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph3',
    },
    platformDescriptionPrint: {
      defaultMessage: 'Please print a copy of the Standard Terms and Conditions and/or store them on a durable medium.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionPrint',
    },
    condition2Paragraph4: {
      defaultMessage:
        'The user warrants that the Registration Credentials provided during the registration procedure on the Platform are complete, correct and true. The user accepts to indemnify and hold VINHOOD harmless from any and all claims for damages or fines arising out of and/or in any way in connection with a breach by the user of the Platform registration rules. The user is solely responsible for access to the Platform using the Registration Credentials and responds directly for any and all loss or harm caused to VINHOOD or to third parties due to improper use, loss and misappropriation by others or failure to adequately protect the non-disclosure of Registration Credentials. All transactions made using the Registration Credentials are considered made by the user to whose registration Credentials they refer.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph4',
    },
    condition2Paragraph5: {
      defaultMessage: 'Services are delivered in Italian and English.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph5',
    },
    condition2Paragraph6: {
      defaultMessage: 'Only one registration is permitted per user. Multiple registrations will be deleted by VINHOOD.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph6',
    },
    condition2Paragraph7: {
      defaultMessage:
        'VINHOOD reserves the right, at its discretion, to refuse the registration of any user. Furthermore, VINHOOD reserves the right to block the account of a user that acts in breach of the Standard Terms and Conditions or applicable law.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph7',
    },
    condition2Paragraph8: {
      defaultMessage:
        'The user may at any time delete his/her VINHOOD registration by sending an e-mail to the address: info@VINHOOD.com.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph8',
    },
    condition3Paragraph1: {
      defaultMessage:
        'VINHOOD is entitled to temporarily suspend the service, without any prior notice and delivery of the Platform and the services for the time strictly required for technical work necessary and/or opportune for improving the quality of such services.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition3Paragraph1',
    },
    condition3Paragraph2: {
      defaultMessage:
        'VINHOOD may, at any time, interrupt the provision of service, for justified security reasons or breaches of data protection, notifying the user accordingly.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition3Paragraph2',
    },
    conditionTitle2: {
      defaultMessage: 'REGISTRATION',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle2',
    },
    condition4Paragraph1: {
      defaultMessage:
        'The Platform content, including, but not limited to, the #characters, the tests, the works, the audio tracks and videos, the images, the photographs, the music, the documents, the drawings, the figures, the logos and any and all other material, in whatever form, published on the Platform, including the menus, the web pages, the graphics, the colors, the formats, the tools, the characters and the Platform web design, the diagrams, the layout, the methods, the processes, the features and the software are protected by copyright and by any and all other VINHOOD and other holder intellectual property rights Reproducing, changing, duplicating, copying, distributing, selling and, however, using the images and any other content on the Platform, if not authorized in advance in writing by VINHOOD, is prohibited. Likewise, any and all use of the Platform content for commercial and/or advertising purposes is prohibited.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition4Paragraph1',
    },
    condition4Paragraph2: {
      defaultMessage:
        'All the distinctive signs that mark the products/services published on the Platform are the registered trademarks of their respective owners and they are used by VINHOOD for the sole purpose of marking, describing and advertising the products/services published/on sale on the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition4Paragraph2',
    },
    condition4Paragraph3: {
      defaultMessage:
        'Any use whatsoever of the aforesaid distinctive signs that is not compliant with law and, as such, is not authorized, is prohibited. It is not permitted in any way whatsoever to use any distinctive sign appearing on the Platform for exploiting the distinctive character for profit or unlawful gain, or reputation of such signs, o, in any way causing harm to such signs and their owners.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition4Paragraph3',
    },
    condition4Paragraph4: {
      defaultMessage:
        'In no case may the user manipulate, change, modify or adapt the Platform or the material made available by VINHOOD. The Platform is offered as is. Commercial use of the software, the Platform and/or any other content is strictly prohibited. The User may not bypass the technical limitations in the software; translate, decode, decompile or unbundle the software, nor attempt to trace the Software source code or create derivative works based on the Software and publish the software for allowing its duplication by others, rent, sub-license or lend the Software.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition4Paragraph4',
    },
    condition5Paragraph1: {
      defaultMessage:
        'The Platform is provided as is with no warranty provided as to the result. VINHOOD is in no way liable for any interruptions in the provision of the Platform and/or service due to causes not directly attributable to it and/or that are outside its technical control, such as data transmission or telephone network outages.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph1',
    },
    condition5Paragraph2: {
      defaultMessage:
        'VINHOOD is entitled to change, suspend and/or interrupt delivery of the Platform, at any time, in whole or in part, at its sole discretion, also definitively, without prior notice and without such action giving rise to any claim whatsoever for damages and/or reimbursement claimed from VINHOOD.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph2',
    },
    condition5Paragraph3: {
      defaultMessage:
        'VINHOOD will not recognize any warranty other than that granted under these Standard Terms and Conditions: it is understood that the user uses the Platform under his/her full and sole liability and that use of the Platform is required to comply with all instructions given by VINHOOD.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph3',
    },
    condition5Paragraph4: {
      defaultMessage:
        'The User is authorized to use the Platform for personal use only and not collectively or for profit, and he/she accepts full liability for any and all non-authorized use.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph4',
    },
    conditionTitle3: {
      defaultMessage: 'SERVICE SUSPENSION',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle3',
    },
    condition5Paragraph5: {
      defaultMessage:
        'VINHOOD provides no warranty as to the accuracy and/or actuality of the Platform content. VINHOOD may not be considered liable for any loss to Users that may be caused to Users and their use of information and the content included on the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph5',
    },
    conditionTitle4: {
      defaultMessage: 'PLATFORM CONTENT AND INTELLECTUAL PROPERTY RIGHTS',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle4',
    },
    condition5Paragraph6: {
      defaultMessage:
        'VINHOOD accepts no liability for any abuse committed by Users, or in cases where they may be a victim of said abuse, as a result of third-party conduct, whether put in place or not in breach of the Standard Terms and Conditions.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph6',
    },
    condition6Paragraph1: {
      defaultMessage: 'The Standard Terms and Conditions are governed by Italian law.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition6Paragraph1',
    },
    condition6Paragraph2: {
      defaultMessage:
        'Any dispute that may arise in relation to the Standard Terms and Conditions will be referred for adjudication to the Court and venue indicated by the User/consumer as his/her residence or domicile, if located within the territory of the Italian State.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition6Paragraph2',
    },
    condition6Paragraph3: {
      defaultMessage: 'Whenever users are non-consumers, the Courts of Milan will have sole jurisdiction.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition6Paragraph3',
    },
    conditionTitle5: {
      defaultMessage: 'EXCLUSION OF LIABILITY',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle5',
    },
    conditionTitle6: {
      defaultMessage: 'FINAL PROVISIONS, APPLICABLE LAW AND VENUE WITH JURISDICTION',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle6',
    },
    btnBack: {
      defaultMessage: 'Back',
      id: 'vinhood.vinhood_experience.terms_conditions_page.btnBack',
    },
  }),
  expertQuizPage: defineMessages({
    title: {
      defaultMessage: 'Do you want to use the Taste Test to discover the perfect product for you?',
      id: 'vh.vinhood_experience.expert_quiz_page.title',
    },
    youGuideMe: {
      defaultMessage: `Absolutely, let's get started!`,
      id: 'vh.vinhood_experience.expert_quiz_page.youGuideMe',
    },
    answerYes: {
      defaultMessage: `No, come on: I'll tell you what I like!`,
      id: 'vh.vinhood_experience.expert_quiz_page.answerYes',
    },
  }),
  registrationPage: defineMessages({
    title: {
      defaultMessage: 'High five: ||gift unlocked!',
      id: 'vh.vinhood_experience.registrationPage.title',
    },
    subTitle: {
      defaultMessage: `Receive immediately **a personalized report** based on your taste by sharing your email with us. ||But the surprises aren't over yet...`,
      id: 'vh.vinhood_experience.registrationPage.subTitle',
    },
    socialConnection: {
      defaultMessage: 'Connect via one of your social media accounts',
      id: 'vh.vinhood_experience.registrationPage.socialConnection',
    },
    fillForm: {
      defaultMessage: 'Or fill out the form below',
      id: 'vh.vinhood_experience.registrationPage.fillForm',
    },
    nameField: {
      defaultMessage: 'Name',
      id: 'vinhood.vinhood_experience.registrationPage.nameField',
    },
    nameFieldPlaceholder: {
      defaultMessage: 'Enter your name',
      id: 'vinhood.vinhood_experience.registrationPage.nameFieldPlaceholder',
    },
    emailField: {
      defaultMessage: 'Email',
      id: 'vinhood.vinhood_experience.registrationPage.emailField',
    },
    emailFieldPlaceholder: {
      defaultMessage: 'Enter your email',
      id: 'vinhood.vinhood_experience.registrationPage.emailFieldPlaceholder',
    },
    passwordField: {
      defaultMessage: 'Password',
      id: 'vinhood.vinhood_experience.registrationPage.passwordField',
    },
    passwordFieldPlaceholder: {
      defaultMessage: 'Create a password',
      id: 'vinhood.vinhood_experience.registrationPage.passwordFieldPlaceholder',
    },
    submitBtn: {
      defaultMessage: 'Submit',
      id: 'vinhood.vinhood_experience.registrationPage.submitBtn',
    },
    emailValidation: {
      defaultMessage: 'Email is required',
      id: 'vinhood.vinhood_experience.registrationPage.emailValidation',
    },
    validEmailValidation: {
      defaultMessage: 'You cannot use this email to register',
      id: 'vinhood.vinhood_experience.registrationPage.validEmailValidation',
    },
    nameValidation: {
      defaultMessage: 'First Name should be at least two characters.',
      id: 'vinhood.vinhood_experience.registrationPage.nameValidation',
    },
    passwordValidation: {
      defaultMessage: 'Between 8 and 30 characters, at least one uppercase, one lowercase letters and one number.',
      id: 'vinhood.vinhood_experience.registrationPage.passwordValidation',
    },
    passwordTooShortValidation: {
      defaultMessage: 'Must be at least 8 characters.',
      id: 'vinhood.vinhood_experience.registrationPage.passwordTooShortValidation',
    },
    passwordTooLongValidation: {
      defaultMessage: 'The password must not exceed 30 characters.',
      id: 'vinhood.vinhood_experience.registrationPage.passwordTooLongValidation',
    },
    passwordUpperLowerNumberValidation: {
      defaultMessage: 'Passwords should contain at least one capital letter, one lowercase letter and one number',
      id: 'vinhood.vinhood_experience.registrationPage.passwordUpperLowerNumber',
    },
    promotionalMessage: {
      defaultMessage: `You'll receive promotional messages tailored for you! Magic? More or less. Our data management has something to do with it: read our Privacy Policy and Legal.`,
      id: 'vinhood.vinhood_experience.registrationPage.promotionalMessage',
    },
    loginRedirection: {
      defaultMessage: `Already registered?[linkTo=login-page]Enable your Vinhood taste profile.[linkTo=login-page]`,
      id: 'vinhood.vinhood_experience.registrationPage.loginRedirection',
    },
    catalogRedirection: {
      defaultMessage: `Perhaps next time?[linkTo=catalog-page]Skip and go to products.[linkTo=catalog-page]`,
      id: 'vinhood.vinhood_experience.registrationPage.catalogRedirection',
    },
  }),
  tabs: defineMessages({
    yourTastePathTab: {
      defaultMessage: 'Your taste path',
      id: 'vinhood.vinhood_experience.tabs.yourTastePathTab',
    },
    allProductsTab: {
      defaultMessage: 'All products',
      id: 'vinhood.vinhood_experience.tabs.allProductsTab',
    },
  }),
  tastePathPage: defineMessages({
    title: {
      defaultMessage: 'Your taste path',
      id: 'vinhood.vinhood_experience.tastePathPage.title',
    },
    descriptionCT0001wine1: {
      defaultMessage: 'The bubble that drives you wild.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0001wine1',
    },
    descriptionCT0001wine2: {
      defaultMessage: 'The bubble that teases you.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0001wine2',
    },
    descriptionCT0001wine3: {
      defaultMessage: `The one that's never enough.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0001wine3',
    },
    descriptionCT0003wine1: {
      defaultMessage: 'The white that seduces you.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0003wine1',
    },
    descriptionCT0003wine2: {
      defaultMessage: `The one you'd never say no to.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0003wine2',
    },
    descriptionCT0003wine3: {
      defaultMessage: `The white that intrigues you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0003wine3',
    },
    descriptionCT0005wine1: {
      defaultMessage: 'Something that enchants you.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0005wine1',
    },
    descriptionCT0005wine2: {
      defaultMessage: `The one that whisks you away from routine.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0005wine2',
    },
    descriptionCT0005wine3: {
      defaultMessage: `The one that tempts you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0005wine3',
    },
    descriptionCT0002wine1: {
      defaultMessage: 'The red that makes you fall in love.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002wine1',
    },
    descriptionCT0002wine2: {
      defaultMessage: `The one that's always the right answer.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002wine2',
    },
    descriptionCT0002wine3: {
      defaultMessage: `The red that captivates you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002wine3',
    },
    descriptionCT0004wine1: {
      defaultMessage: 'The sweet that makes you dream.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0004wine1',
    },
    descriptionCT0004wine2: {
      defaultMessage: `The one that pampers you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0004wine2',
    },
    descriptionCT0004wine3: {
      defaultMessage: `The sweet that enchants you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0004wine3',
    },
    descriptionCT0010beer1: {
      defaultMessage: `A stroll on the clouds.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0010beer1',
    },
    descriptionCT0010beer2: {
      defaultMessage: `A carefree breeze.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0010beer2',
    },
    descriptionCT0010beer3: {
      defaultMessage: `Dancing effervescence.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0010beer3',
    },
    descriptionCT0002beer1: {
      defaultMessage: `A biting hop.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002beer1',
    },
    descriptionCT0002beer2: {
      defaultMessage: `A dizzying tale.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002beer2',
    },
    descriptionCT0002beer3: {
      defaultMessage: `An enveloping bitter temptation.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002beer3',
    },
    descriptionCT0011beer1: {
      defaultMessage: `A caress of toasted malt.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0011beer1',
    },
    descriptionCT0011beer2: {
      defaultMessage: `A hug of toasted malt.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0011beer2',
    },
    descriptionCT0011beer3: {
      defaultMessage: `A story of fire and passion.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0011beer3',
    },
    descriptionCT0007beer1: {
      defaultMessage: `An explosion of flavors.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0007beer1',
    },
    descriptionCT0007beer2: {
      defaultMessage: `A bold symphony.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0007beer2',
    },
    descriptionCT0007beer3: {
      defaultMessage: `A whirlwind of intensity.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0007beer3',
    },
    btnText: {
      defaultMessage: 'Skip to ',
      id: 'vinhood.vinhood_experience.tastePathPage.btnText',
    },
    alreadyTasted: {
      defaultMessage: 'Already tasted',
      id: 'vinhood.vinhood_experience.tastePathPage.alreadyTasted',
    },
    titleEnd: {
      defaultMessage: 'Made it, mate!',
      id: 'vinhood.vinhood_experience.tastePathPage.titleEnd',
    },
    descriptionEnd: {
      defaultMessage:
        'Your guts and curiosity just wrapped up this delicious journey. ||Maybe your thirst played a role too. ||Catch you on the flip side!',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionEnd',
    },
    tooltipModalTitle: {
      defaultMessage: 'Taste it, enjoy it, and rate it!',
      id: 'vinhood.vinhood_experience.tastePathPage.tooltipModalTitle',
    },
    tooltipModalSubTitle: {
      defaultMessage: `To unlock more, play with the "rate it" emoji!`,
      id: 'vinhood.vinhood_experience.tastePathPage.tooltipModalSubTitle',
    },
  }),
  tastingPathFeedbackLoadingPage: defineMessages({
    titleText1: {
      defaultMessage: 'Hey there, Indiana Jones! Ready to embark on a new leg of your journey?',
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText1',
    },
    titleText2: {
      defaultMessage: `You're about to become a Flavor Adventurer...`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText2',
    },
    titleText3: {
      defaultMessage: `Culinary Explorer Transformation in progress...`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText3',
    },
    titleText4: {
      defaultMessage: `Just one step away from becoming the Flavor Conqueror...`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText4',
    },
    titleText5: {
      defaultMessage: `Gastronomic Guru Wannabe`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText5',
    },
    titleText6: {
      defaultMessage: `Evolving into a Delicacy Hunter`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText6',
    },
    titleText7: {
      defaultMessage: `New level unlocked: Divine Taster`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText7',
    },
    titleText8: {
      defaultMessage: `You: the Wise Gourmet`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText8',
    },
  }),
  toastMessage: defineMessages({
    titleText: {
      defaultMessage: 'Taste it, savour it, and rate it!',
      id: 'vinhood.vinhood_experience.toastMessage.titleText',
    },
    bodyText1: {
      defaultMessage: 'To unlock more, choose the emoji that best matches your mood.',
      id: 'vinhood.vinhood_experience.toastMessage.bodyText1',
    },
    bodyText2: {
      defaultMessage: 'Follow your path and click on "?" to get instructions',
      id: 'vinhood.vinhood_experience.toastMessage.bodyText2',
    },
  }),
  noAvailableProductsCatalog: defineMessages({
    titleText: {
      defaultMessage: 'Whoops...No products now, but joy coming soon!',
      id: 'vinhood.vinhood_experience.noAvailableProductsCatalog.titleText',
    },
  }),
  explorePage: defineMessages({
    titleText: {
      defaultMessage:
        'This {productCategory, select, wine {{ productCategoryText }} beer {{ productCategoryText }} coffee {{ productCategoryText }} other {}} might tickle ||your taste buds',
      id: 'vinhood.vinhood_experience.explorePage.titleText',
    },
    experiencesText: {
      defaultMessage: 'Experiences',
      id: 'vinhood.vinhood_experience.explorePage.experiencesText',
    },
    placeCardSubtitle: {
      defaultMessage: 'You are here!',
      id: 'vinhood.vinhood_experience.explorePage.placeCardSubtitle',
    },
    listTitle: {
      defaultMessage: 'Prepare for your next',
      id: 'vinhood.vinhood_experience.explorePage.listTitle',
    },
    listSubtitle: {
      defaultMessage: ' { productCategory, select, other {{ productCategoryText }}} Adventure! 🚀',
      id: 'vinhood.vinhood_experience.explorePage.listSubtitle',
    },
    listBtn: {
      defaultMessage: 'See all { retailerName } locations',
      id: 'vinhood.vinhood_experience.explorePage.listBtn',
    },
    searchPlaces: {
      defaultMessage: 'Search places',
      id: 'vinhood.vinhood_experience.explorePage.searchPlaces',
    },
  }),
  ageAndGenderPages: defineMessages({
    genderTitle: {
      defaultMessage: 'How do you identify yourself?',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.genderTitle',
    },
    continueBtn: {
      defaultMessage: 'Continue',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.continueBtn',
    },
    skipBtn: {
      defaultMessage: 'Skip',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.skipBtn',
    },
    agePreTitle: {
      defaultMessage: 'Lets Start!',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.agePreTitle',
    },
    ageTitle: {
      defaultMessage: 'What is your birth year?',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.ageTitle',
    },
    tastingTipTitle: {
      defaultMessage: '#TastingTip',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.tastingTipTitle',
    },
    randomGenderTastingTip1: {
      defaultMessage:
        'Females are more sensitive than males regarding odor detection, identification, discrimination, and memory.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip1',
    },
    randomGenderTastingTip2: {
      defaultMessage:
        'Taste preferences and detection abilities differ between genders due to varying estrogen levels, which can modulate the detectability and preference of taste.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip2',
    },
    randomGenderTastingTip3: {
      defaultMessage:
        'Hormonal variation affects bitter taste; bitterness varies with the menstrual cycle, rises in early pregnancy and falls after menopause.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip3',
    },
    randomGenderTastingTip4: {
      defaultMessage:
        'Females are more sensitive in the recognition of all tastes because they have higher papillae density than males.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip4',
    },
    randomGenderTastingTip5: {
      defaultMessage:
        'A regular reduction of papillae density, which means a reduction in taste perception, is observed with age, an effect more evident in males than in females.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip5',
    },
    randomGenderTastingTip6: {
      defaultMessage: 'Women consume wine and beer 2 times less than men.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip6',
    },
    randomAgeTastingTip1: {
      defaultMessage:
        'Young brains react negatively to veggies, while loving the orange juice. Our taste buds mature, appreciating the full flavor spectrum as we grow older.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip1',
    },
    randomAgeTastingTip2: {
      defaultMessage:
        'With age it becomes difficult to perceive bitterness, while the sweet taste is kept even in advanced age, with a consequent liking of sweet and high-calorie meals.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip2',
    },
    randomAgeTastingTip3: {
      defaultMessage:
        'After turning 60 years old, the decreased amount and changed composition of saliva may reduce the perception of bitter  and sour tastes.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip3',
    },
    randomAgeTastingTip4: {
      defaultMessage:
        'Adults tested for their responses to bitter stimuli demonstrated an aversion that decreased with age.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip4',
    },
    randomAgeTastingTip5: {
      defaultMessage: 'Ratings for high-fat foods rose with age for both sexes.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip5',
    },
    randomAgeTastingTip6: {
      defaultMessage:
        'Although there was decreased preference for higher concentrations of sweeteners from childhood through adulthood, there was evidence for a change in direction among older adults.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip6',
    },
    randomAgeTastingTip7: {
      defaultMessage:
        'Older people associate alcohol with relaxation and may link its consumption more to a symbolic ritual, e.g., having a drink to demarcate the end of a working day, while young consumers may perceive alcohol more as a way to socialize and get out of routine.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip7',
    },
    randomAgeTastingTip8: {
      defaultMessage:
        '“Mild-drink lovers” were more likely to be women and young, “spirit lovers” were mainly men aged 30–45, while “beer/wine lovers” were the oldest group with no significant difference by gender.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip8',
    },
    ageError: {
      defaultMessage: 'Please enter a 4-digit number between 1920 and { maxValidBirthYear }',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.ageError',
    },
  }),
};
