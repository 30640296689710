import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { LEGAL_TAB_SLUG_REPLACEMENT } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import {
  actionAcceptAllBasicAgreements,
  actionChangeAgreements,
  actionRejectAllBasicAgreements,
} from '@lib/core/service/slices/technical/agreements';
import { prependBasename } from '@lib/core/service/utils';
import { isAppInIframe } from '@lib/tools/comms/utils';
import { useModals } from '@lib/tools/modals/hooks';
import RouteUtils from '@lib/tools/routes';
import {
  LEGAL_PAGE_COOKIE_POLICY_TAB,
  LEGAL_PAGE_PRIVACY_POLICY_TAB,
  LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB,
  MODALS,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import StartPage from '@components/web/src/pages/widget/StartPage/StartPage';
import CookieModal from '@components/web/src/templates/Modals/CookieModal/CookieModal';
import PermissionModal from '@components/web/src/templates/Modals/PermissionModal/PermissionModal';

const HocStart = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { closeAllModals, openModal, isCookieModalOpened, isPermissionModalOpened } = useModals();
  const { appAgreements } = useTechnical();
  const { isUserAllowedUseLocalStorage } = appAgreements;
  const { productCategory } = useApp();

  const isLegalPage = [
    LEGAL_PAGE_COOKIE_POLICY_TAB,
    LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB,
    LEGAL_PAGE_PRIVACY_POLICY_TAB,
  ].some(legalPage => RouteUtils.getPage().includes(legalPage));
  const isRedirectPage = RouteUtils.getPage().includes(PAGES.vinhood.redirect);

  useEffect(() => {
    if (!isAppInIframe && !isUserAllowedUseLocalStorage && !isLegalPage) {
      openModal(MODALS.COOKIE_MODAL);
    }
  }, [location]);

  const handleWidgetLegalConfirmation = () => {
    dispatch(actionAcceptAllBasicAgreements());
    navigate(prependBasename(PAGES.vinhood.home));
  };

  const handleInformativeModalManagePreferences = () => {
    closeAllModals();
    openModal(MODALS.PERMISSION_MODAL);
  };

  const navigateToCookiePolicyTab = () => {
    closeAllModals();
    navigate(
      prependBasename(PAGES.vinhood.legal.tabs.replace(LEGAL_TAB_SLUG_REPLACEMENT, LEGAL_PAGE_COOKIE_POLICY_TAB)),
    );
  };

  const handleInformativeModalAcceptAllBtn = () => {
    dispatch(actionAcceptAllBasicAgreements());
    closeAllModals();
  };

  const handlePermissionConfirmButton = (values: Record<string, boolean>) => {
    dispatch(actionChangeAgreements(values));
    closeAllModals();
  };

  if (isAppInIframe && !isUserAllowedUseLocalStorage && !isLegalPage) {
    return (
      <StartPage
        handleWidgetLegalConfirmation={handleWidgetLegalConfirmation}
        navigateToCookiePolicyTab={navigateToCookiePolicyTab}
        productCategory={productCategory}
      />
    );
  }

  const handleHideModal = () => {
    closeAllModals();
    dispatch(actionRejectAllBasicAgreements());
  };

  return (
    <>
      {children}
      {!isAppInIframe && isCookieModalOpened && !isLegalPage && !isRedirectPage && (
        <CookieModal
          handleConfirm={handleInformativeModalAcceptAllBtn}
          handleManagePreferences={handleInformativeModalManagePreferences}
          hideModal={handleHideModal}
          isModalOpen={isCookieModalOpened}
        />
      )}
      {!isAppInIframe && isPermissionModalOpened && (
        <PermissionModal
          shouldOpenModalsUnderHeader
          handleConfirmButton={handlePermissionConfirmButton}
          hideModal={closeAllModals}
          isModalOpen={isPermissionModalOpened}
        />
      )}
    </>
  );
};

export default HocStart;
