import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ACTIVE_PANEL_FILTER_URL_PARAM } from '@app/web/src/helpers/consts';

import { TJournalProductPanelFilter } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  FORMAT_QUERY,
  IS_FROM_PRODUCT_DETAILS_PAGE,
  ORIGINS_QUERY,
  PRODUCT_CHARACTERISTICS_QUERY,
  PRODUCT_NAME_QUERY,
  SEARCH_BY_NAME_FIELDS_QUERY,
  STYLE_QUERY,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useScrollToTop } from '@lib/core/service/hooks/useScrollToTop';
import { fetchProductsListsExtended, resetProductCatalogState } from '@lib/core/service/slices/productCatalogSlice';
import { prependBasename } from '@lib/core/service/utils';
import { useFeedback, useWishlist } from '@lib/core/users/hooks';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { removeUrlParams, setUrlParams } from '@lib/tools/shared/helpers';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_ITEM_NAME,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  JOURNAL_PRODUCT_VARIANT,
  OS_PRODUCT_NAME_QUERY,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_CATEGORY_URL_PARAM,
  PRODUCT_IDENTIFIER_QUERY,
  TJournalPlacesPanelFilter,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';
import { useEffectSkipFirst, useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import JournalPage from '@components/web/src/templates/TasteId/TasteIdJournalPage/TasteIdJournalPage';

const JournalProductsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { locale } = useApp();
  const { retailerStoreType } = useRetailerLocation();

  const productCategoryUrlParam = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);
  const isFromProductDetailsPage = searchParams.get(IS_FROM_PRODUCT_DETAILS_PAGE) === 'true';
  const activePanelFilterFromUrl = searchParams.get(ACTIVE_PANEL_FILTER_URL_PARAM);

  const [activeJournalPanelFilter, setActivePanelFilter] = useState<TJournalProductPanelFilter>(
    (activePanelFilterFromUrl as TJournalProductPanelFilter) || FILTER_TYPE_SHOW_FAVORITES,
  );
  useScrollToTop(activeJournalPanelFilter);

  const handleProductPanelFilterChange = (value: TJournalProductPanelFilter) => {
    setActivePanelFilter(value);
    setUrlParams([{ key: ACTIVE_PANEL_FILTER_URL_PARAM, value }]);
  };

  const handlePanelFilterChange = (value: TJournalProductPanelFilter | TJournalPlacesPanelFilter) => {
    handleProductPanelFilterChange(value as TJournalProductPanelFilter);
  };

  const {
    isProductsRequestLoading,
    productsData: { results: products, count: itemsCount },
  } = useTypedSelector(state => state.productCatalog);

  const {
    [FILTER_TYPE_CHARACTERISTICS]: selectedCharacteristicsQuery,
    [FILTER_TYPE_FORMAT]: selectedFormatsQuery,
    [FILTER_TYPE_ORIGIN]: selectedOriginsQuery,
    [FILTER_TYPE_STYLE]: selectedStylesQuery,
    [FILTER_TYPE_ITEM_NAME]: searchTextValue,
    [FILTER_TYPE_SHOW_FAVORITES]: selectedWishlistProductsQuery,
    [FILTER_TYPE_RATING]: selectedRatedProductsQuery,
    isFilterOpened,
  } = useCatalogFilter({ activeJournalPanelFilter });

  const { feedbackData, handleUpdateFeedback, isFeedbackLoading } = useFeedback();
  // TODO: replace useWishlist to useProductList after full start using useProductList
  const { wishlistProductIds, handleUpdateWishlistProductList, isProductListLoading, isWishlistLoaded } = useWishlist();

  const { discoveryQuiz } = useDiscoveryQuizData();

  useEffect(() => {
    if (!isFromProductDetailsPage) {
      dispatch(actionResetCatalogFilters());
      dispatch(resetProductCatalogState());
    }

    if (isFromProductDetailsPage) {
      removeUrlParams({
        keys: [IS_FROM_PRODUCT_DETAILS_PAGE, ACTIVE_PANEL_FILTER_URL_PARAM],
      });
    }
    return () => {
      dispatch(resetProductCatalogState());
    };
  }, []);

  const handleBackBtnClick = () =>
    navigate(prependBasename(PAGES.vinhood.tasteId.tasteId, { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam }));

  const handleProductsRequest = ({
    isPagination = false,
    isForCollectFilters = false,
  }: IHandleProductsRequestParams) => {
    return dispatch(
      fetchProductsListsExtended({
        isForCollectFilters,
        isPagination,
        params: {
          [PRODUCT_CATEGORY_QUERY]: productCategoryUrlParam,
          ...(searchTextValue && {
            [PRODUCT_NAME_QUERY]: `*${searchTextValue}*`,
            [SEARCH_BY_NAME_FIELDS_QUERY]: OS_PRODUCT_NAME_QUERY,
          }),
          ...(selectedOriginsQuery && { [ORIGINS_QUERY]: selectedOriginsQuery }),
          ...(selectedFormatsQuery && { [FORMAT_QUERY]: selectedFormatsQuery }),
          ...(selectedStylesQuery && { [STYLE_QUERY]: selectedStylesQuery }),
          ...(selectedCharacteristicsQuery && {
            [PRODUCT_CHARACTERISTICS_QUERY]: selectedCharacteristicsQuery,
          }),
          ...(selectedWishlistProductsQuery && {
            [PRODUCT_IDENTIFIER_QUERY]:
              activeJournalPanelFilter === FILTER_TYPE_RATING
                ? selectedRatedProductsQuery
                : selectedWishlistProductsQuery,
          }),
        },
      }),
    );
  };

  useEffectSkipFirst(() => {
    handleProductsRequest({});
  }, [activeJournalPanelFilter]);

  const isAllProductsLoaded = itemsCount === products.length;

  if (!isWishlistLoaded) return null;

  return (
    <JournalPage
      activeJournalPanelFilter={activeJournalPanelFilter}
      discoveryQuiz={discoveryQuiz}
      feedbackData={feedbackData}
      handleBackBtnClick={handleBackBtnClick}
      handlePanelFilterChange={handlePanelFilterChange}
      handleProductsRequest={handleProductsRequest}
      handleUpdateFeedback={handleUpdateFeedback}
      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
      isAllProductsLoaded={isAllProductsLoaded}
      isFilterOpened={isFilterOpened}
      isItemsLoading={isProductsRequestLoading || isProductListLoading}
      isProductListLoading={isProductListLoading || isFeedbackLoading}
      items={products}
      itemsCount={itemsCount}
      locale={locale}
      productCategory={productCategoryUrlParam}
      storeType={retailerStoreType}
      variant={JOURNAL_PRODUCT_VARIANT}
      wishlistProductIds={wishlistProductIds}
    />
  );
};

export default JournalProductsContainer;
