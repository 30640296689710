import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { setHideLoginPrompt } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { googleLoginCallback } from '@lib/core/users/slices/auth';
import { REGISTRATION_URL_PARAM, START_EMAIL_REGISTRATION_URL_PARAM } from '@lib/tools/shared/auths/helpers/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import SocialRegistrationPrompt from '@components/web/src/templates/Login&Registration/SocialRegistrationPrompt/SocialRegistrationPrompt';

const SocialRegistrationPromptContainer = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const lastScrollRef = useRef(0);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setShowPrompt(true);
    }, 5000);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById('root-scroll-element');

    if (!element) return undefined;

    function handleScroll() {
      const currentScrollY = element.scrollTop;
      if (currentScrollY > lastScrollRef.current) {
        setShowPrompt(false);
      } else {
        setShowPrompt(true);
      }
      lastScrollRef.current = currentScrollY;
    }
    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigateToRegistrationPage = () =>
    navigate(
      prependBasename(PAGES.vinhood.registration, {
        [REGISTRATION_URL_PARAM]: START_EMAIL_REGISTRATION_URL_PARAM,
      }),
      {
        state: { isFromCatalogPage: true },
      },
    );

  return (
    <SocialRegistrationPrompt
      handleEmailClick={navigateToRegistrationPage}
      hidePrompt={() => dispatch(setHideLoginPrompt())}
      isVisible={showPrompt}
      responseGoogle={googleLoginCallback(dispatch, false)}
    />
  );
};

export default SocialRegistrationPromptContainer;
