/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useSelector } from 'react-redux';

import { actionResetUserQuiz } from '@lib/core/quizzes/slices/userQuiz';
import { useRetailer } from '@lib/core/retailers/hooks';
import { actionGetRetailerDetail } from '@lib/core/retailers/slices';
import { resetUserProductPreferencesSlice } from '@lib/core/service/slices/productPreferencesSlice';
import { actionChangeAgreements, actionResetAllAgreements } from '@lib/core/service/slices/technical/agreements';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { selectAuthToken } from '@lib/core/users/selectors/auth';
import {
  selectIsProfileLoading,
  selectProfileBirthYear,
  selectProfileCountry,
  selectProfileCountryOptions,
  selectProfileGenderOptions,
  selectProfileGenderSlug,
  selectProfileId,
  selectProfileLanguage,
} from '@lib/core/users/selectors/profile';
import {
  selectIsFetchingUserData,
  selectIsUploadingProfilePicture,
  selectIsUserAllowedUseThirdParty,
  selectIsUserFullUserDataLoadedTemporaryHint,
  selectIsUserHasAnyCharacter,
  selectIsUserHasCharacterForCurrentPC,
  selectIsUserProfiled,
  selectIsUserProfiledSomePC,
  selectIsUserSelectedUseThirdParty,
  selectKioskUserProductCategory,
  selectKioskUserRetailerLocationId,
  selectKioskUserRetailerSlug,
  selectMixpanelUserDidIdentify,
  selectSelectedUserProductPreferences,
  selectUserCharacters,
  selectUserCharactersIds,
  selectUserDataRaw,
  selectUserEmail,
  selectUserFirstName,
  selectUserGroups,
  selectUserId,
  selectUserImage,
  selectUserLastName,
  selectUserRole,
  selectUserRoleIsKiosk,
  selectUserSessionId,
} from '@lib/core/users/selectors/user';
import { actionResetAuthSlice } from '@lib/core/users/slices/auth';
import { actionResetProductList } from '@lib/core/users/slices/productList';
import { actionResetUserProfile } from '@lib/core/users/slices/profile';
import { actionGenerateUserSession, actionResetUserSlice } from '@lib/core/users/slices/user';
import { IUser } from '@lib/core/users/types';
import { USER_PRODUCT_PREFERENCES } from '@lib/tools/shared/helpers/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useUser = (): IUser => {
  const dispatch = useTypedDispatch();

  const { retailerSlug } = useRetailer();

  const handleResetUserData = async () => {
    dispatch(actionResetUserSlice());
    dispatch(actionResetAuthSlice());
    dispatch(actionResetUserQuiz());
    dispatch(actionResetAllAgreements());
    dispatch(actionGenerateUserSession());
    dispatch(
      actionChangeAgreements({
        [Agreements.isUserAllowedUseLocalStorage]: false,
      }),
    );
    localStorage.removeItem(USER_PRODUCT_PREFERENCES);
    dispatch(resetUserProductPreferencesSlice());
    dispatch(actionResetProductList());
    dispatch(actionResetUserProfile());
    dispatch(actionGetRetailerDetail({ retailerSlug }));
  };

  return {
    handleResetUserData,
    isFetchingUserData: useSelector(selectIsFetchingUserData),
    isUploadingProfilePicture: useSelector(selectIsUploadingProfilePicture),
    isUserAllowedUseThirdParty: useSelector(selectIsUserAllowedUseThirdParty),
    isUserSelectedUseThirdParty: useSelector(selectIsUserSelectedUseThirdParty),
    isUserAuthenticated: !!useSelector(selectAuthToken),
    isUserFullUserDataLoadedTemporaryHint: useSelector(selectIsUserFullUserDataLoadedTemporaryHint),
    isUserHasAnyCharacter: useSelector(selectIsUserHasAnyCharacter),
    isUserHasCharacterForCurrentPC: useSelector(selectIsUserHasCharacterForCurrentPC),
    isUserKioskAdmin: useSelector(selectUserRoleIsKiosk),
    isUserProfiled: useSelector(selectIsUserProfiled),
    isUserProfiledSomePC: useSelector(selectIsUserProfiledSomePC),
    kioskUserProductCategory: useSelector(selectKioskUserProductCategory),
    kioskUserRetailerLocationId: useSelector(selectKioskUserRetailerLocationId),
    kioskUserRetailerSlug: useSelector(selectKioskUserRetailerSlug),
    mixpanelUserDidIdentify: useSelector(selectMixpanelUserDidIdentify),
    selectedUserProductPreferences: useSelector(selectSelectedUserProductPreferences),
    userCharacters: useSelector(selectUserCharacters),
    userCharactersIds: useSelector(selectUserCharactersIds),
    userDataRaw: useSelector(selectUserDataRaw),
    userImage: useSelector(selectUserImage),
    userEmail: useSelector(selectUserEmail),
    userFirstName: useSelector(selectUserFirstName),
    userGroups: useSelector(selectUserGroups),
    userId: useSelector(selectUserId),
    userLastName: useSelector(selectUserLastName),
    userRole: useSelector(selectUserRole),
    userSessionId: useSelector(selectUserSessionId),
    // profile
    isProfileLoading: useSelector(selectIsProfileLoading),
    profileGenderSlug: useSelector(selectProfileGenderSlug),
    profileBirthYear: useSelector(selectProfileBirthYear),
    profileCountry: useSelector(selectProfileCountry),
    profileLanguage: useSelector(selectProfileLanguage),
    profileId: useSelector(selectProfileId),
    // profile options
    profileGenderOptions: useSelector(selectProfileGenderOptions),
    profileCountryOptions: useSelector(selectProfileCountryOptions),
  };
};
