import styled from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import { ReactComponent as BookmarkIcon } from '@components/web/src/assets/svg/journal_bookmark.svg';
import { ReactComponent as RatingIcon } from '@components/web/src/assets/svg/journal_rating.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const JournalPageContainer = styled.div<{ $productCategory: TProductCategory }>`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ $productCategory }) => $productCategory && STATIC_COLORS.productColors[$productCategory][500]};
`;

export const JournalPageLeftPanel = styled.div<{ $isPlacesVariant?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 48px;
  padding-top: ${({ $isPlacesVariant }) => ($isPlacesVariant ? '60px' : '135px')};
`;

export const JournalPageLeftPanelButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const JournalPageLeftPanelButton = styled.div<{ $isActive?: boolean; $productCategory: TProductCategory }>`
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  transition: top 0.1s linear;

  svg:last-child {
    ${({ $isActive }) => !$isActive && `opacity: 0;`}
    ${({ $productCategory }) => `path {
    fill:${$productCategory && STATIC_COLORS.productColors[$productCategory][500]};
  }`}
    position: absolute;
    left: 48px;
    z-index: 999;
  }
  ${({ $isActive }) => !$isActive && `top: 100px;transition:top 0.1s linear;`}
`;

export const JournalPageRateButton = styled(RatingIcon)<{ $isActive?: boolean; $productCategory: TProductCategory }>`
  cursor: pointer;
  top: 30px;
  fill: rgba(255, 255, 255, 0.25);

  path {
    fill: rgba(255, 255, 255, 0.25);
  }
  ${({ $productCategory }) => `path {
    stroke:${$productCategory && STATIC_COLORS.productColors[$productCategory][500]};
  }`}
  ${({ $isActive }) =>
    $isActive &&
    `fill: white;path {
    fill: white;
  }`}
`;

export const JournalPageBookmarkButton = styled(BookmarkIcon)<{ $isActive?: boolean }>`
  cursor: pointer;
  top: 100px;
  fill: rgba(255, 255, 255, 0.45);
  path {
    stroke: rgba(255, 255, 255, 0);
    fill: rgba(255, 255, 255, 0.45);
  }

  ${({ $isActive }) =>
    $isActive &&
    `fill: white;path {
    fill: white;stroke: white;
  }`}
`;

export const JournalCounter = styled.div<{ $isPlacesVariant?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 36px;
  margin: ${({ $isPlacesVariant }) => ($isPlacesVariant ? `55px 0 16px 22px` : `0 0 16px 22px`)};
`;

export const ProductText = styled(Text)`
  text-transform: lowercase;
`;

export const JournalPageContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${STATIC_COLORS.warmGray[100]};
  border-top-left-radius: 20px;
  width: 100%;
  .mp-input-container {
    height: 40px;
  }
  .applied-filters-container {
    padding: 8px 0 !important;
  }
  .catalogue-filter-section {
    padding: 16px 16px 0 26px;
  }
  .badge-container {
    margin-bottom: 5px !important;
  }
`;

export const JournalPageHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 44px;
  align-items: center;
  background: ${STATIC_COLORS.warmGray[100]};
  box-shadow: 0 1px 3px 0 #1018281a;
  z-index: 999;
  text-transform: capitalize;
  width: 100%;
  max-width: 500px;

  button {
    position: absolute;
    left: 10px;
    width: 100px;
  }
`;

export const JournalPlacesList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .scroll-wrapper {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .scroller {
      padding: 0 16px 70px 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      width: 100%;
      height: auto;
      overflow: auto;
    }
  }
`;
