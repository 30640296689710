import { styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const RecipeItemContainer = styled(Flexbox)`
  height: 66px;
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  flex: 0 0 100px;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
`;
