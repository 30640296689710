import { FC, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import * as S from '@components/web/src/components/Catalog/ProductList/styles';
import ProductCard, { TProductCardVariants } from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type ICatalogProps = {
  isProductsRequestLoading: boolean;
  disablePagination?: boolean;
  products: TProductInstance[];
  itemsCount?: number;
  storeType: TRetailerLocationStoreType;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  isEnableTastePathAddon?: boolean;
  locale: string;
  producerName?: string;
  isMatchedProductsAvailable?: boolean;
  shouldHideWishlist?: boolean;
  shouldHideFeedback?: boolean;
  feedbackData?: IFeedbackData[];
  wishlistProductIds?: string[];
  isProductListLoading?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  variant?: TProductCardVariants;
  isShowThirdPartyNewsletter?: boolean;
  shouldShowDownloadAppCard?: boolean;
  handleThirdPartyNewsletter?: (value: boolean) => void;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback?: (feedback: TFeedback, productId: string) => void;
  handleAuthFeatureClick?: () => void;
};

const CatalogueResult: FC<ICatalogProps> = ({
  disablePagination = false,
  products,
  isProductsRequestLoading = false,
  itemsCount,
  storeType,
  isEnableLocationMapAddon,
  isShowProductLocationAddon,
  isEnableVusionAddon,
  isEnableTastePathAddon,
  locale,
  producerName,
  shouldHideFeedback,
  feedbackData = [],
  shouldHideWishlist,
  wishlistProductIds = [],
  isProductListLoading,
  discoveryQuiz,
  variant,
  isMatchedProductsAvailable,
  shouldShowDownloadAppCard,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  handleProductsRequest,
  handleAuthFeatureClick,
  handleThirdPartyNewsletter,
  isShowThirdPartyNewsletter,
}) => {
  return (
    <S.ProductListContainer data-testid="ProductListContainer">
      <InfiniteScroll
        dataLength={products.length}
        hasMore={!itemsCount || products.length < itemsCount}
        scrollableTarget="root-scroll-element"
        loader={
          isProductsRequestLoading ? (
            <>
              {new Array(6).fill(null).map((_i, index) => (
                <S.ProductCardWrapper key={index} justify="center">
                  <ProductCard isLoading={isProductsRequestLoading} locale={locale} storeType={storeType} />
                </S.ProductCardWrapper>
              ))}
            </>
          ) : null
        }
        next={
          (isProductsRequestLoading || disablePagination) && !products.length
            ? null
            : () => handleProductsRequest({ isPagination: true })
        }
      >
        {products?.map((productInstanceData, index) => (
          <Fragment key={productInstanceData.identifier}>
            <S.ProductCardWrapper align="center" direction="column" gap={24} justify="center">
              <ProductCard
                isResponsive
                discoveryQuiz={discoveryQuiz}
                feedback={feedbackFilter(feedbackData, productInstanceData)}
                handleAuthFeatureClick={handleAuthFeatureClick}
                handleUpdateFeedback={handleUpdateFeedback}
                handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                isEnableLocationMapAddon={isEnableLocationMapAddon}
                isEnableTastePathAddon={isEnableTastePathAddon}
                isEnableVusionAddon={isEnableVusionAddon}
                isLoading={false}
                isProductInWishlist={isProductInWishlistFilter(wishlistProductIds, productInstanceData)}
                isProductListLoading={isProductListLoading}
                isShowProductLocationAddon={isShowProductLocationAddon}
                locale={locale}
                mixpanelIndex={index}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
                productInstanceData={productInstanceData}
                shouldHideFeedback={shouldHideFeedback}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
                variant={variant}
              />
            </S.ProductCardWrapper>
            {index === 0 && isShowThirdPartyNewsletter && !isMatchedProductsAvailable && isShowThirdPartyNewsletter && (
              <ThirdPartyNewsletter
                handleThirdPartyNewsletter={handleThirdPartyNewsletter}
                producerName={producerName}
                withMarginTop={shouldShowDownloadAppCard}
              />
            )}
          </Fragment>
        ))}
      </InfiniteScroll>
    </S.ProductListContainer>
  );
};

export default CatalogueResult;
