import { createSelector } from 'reselect';

import { TProductInstance } from '@lib/core/products/types';
import { IKioskResultPageProducts, IKioskRootState } from '@lib/core/service/slices/kioskRootSlice';
/**
 * @returns kioskApp slice state
 */
export const selectKioskState = (state): IKioskRootState => state.kioskApp || {};

/**
 * @returns kiosk result page products
 */
export const selectKioskResultPageProducts: (state) => IKioskResultPageProducts = createSelector(
  [selectKioskState],
  kioskApp => kioskApp.resultPageProducts,
);

export const selectKioskProductsIsLoading: (state) => boolean = createSelector(
  [selectKioskResultPageProducts],
  resultPageProducts => resultPageProducts.isProductsLoading,
);

export const selectKioskProducts: (state) => TProductInstance[][] = createSelector(
  [selectKioskResultPageProducts],
  resultPageProducts => resultPageProducts.products,
);

/**
 * @returns true if anonymous preference
 */
export const selectKioskIsAnonymousPreference: (state) => boolean = createSelector(
  [selectKioskState],
  kioskApp => kioskApp.homePage.isAnonymousPreference,
);
/**
 * @returns the number of products in the home page of kiosk
 */
export const selectKioskProductQuantity: (state) => number = createSelector(
  [selectKioskState],
  kioskApp => kioskApp.homePage.productQuantity,
);
