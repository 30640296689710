import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

/**
 * @returns the whole explore state
 */
const selectExploreLocationsState = (state: RootState) => state.exploreLocations;

/**
 * @returns {boolean} status of loading explore state
 */
export const selectIsExploreLocationListLoading = createSelector(
  [selectExploreLocationsState],
  state => state?.isLoading || false,
);

/**
 * @returns {IRetailerLocation[]} retailer locations list
 */
export const selectExploreLocationList = createSelector([selectExploreLocationsState], state => state?.list || []);
