import { ENV_TRACKING_BLACKLIST } from '@lib/core/service/consts';
import { IAgreementsTypes } from '@lib/core/service/slices/technical/agreements';

/**
 * @returns boolean value is user IP is blocked in our app
 */
export const selectIsClientIpBlocked = (state, defaultValue = false): boolean => {
  return ENV_TRACKING_BLACKLIST?.includes(state.clientIp.data?.ip) || defaultValue;
};

export const selectIsClientIpLoading = (state, defaultValue = false): boolean =>
  state.clientIp?.isLoading || defaultValue;

/**
 * @returns boolean value is google captcha is disabled
 */
export const selectIsGoogleCaptchaIsDisabled = (state, defaultValue = false): boolean =>
  state.clientIp.data?.disable_captcha || defaultValue;

/**
 * @returns IAgreementsTypes[] user agreements values
 */
export const selectAppAgreements = (state, defaultValue = {}): IAgreementsTypes => state.agreements || defaultValue;

export const selectIsScannerLoading = state => state.scanner.isScanningLoading || false;
