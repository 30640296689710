import { useEffect, useMemo, useRef, useState } from 'react';

import {
  BEER_CREAMINESS_ESSENCE_ID,
  HARDCODED_ESSENCE_DATA,
  WINE_STRUCTURE_ESSENCE_ID,
} from '@app/web/src/helpers/consts';

import { useCharacters } from '@lib/core/characters/hooks';
import { TCharacter } from '@lib/core/characters/types';
import { charactersByProductCategory } from '@lib/core/characters/utils';
import { isCharacterByIdentifiers, isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { getLimitedProductsListData, getProductsListData } from '@lib/core/products/utils';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_CHARACTER_QUERY, PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { shuffleArray } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { parseCharacterAdjectiveData } from '@lib/tools/characterAdjective';
import {
  LIMITED_CATALOG,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { ICharacterAdjectiveData } from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import { TSwiperData } from '@components/web/src/organisms/ExtraInfoSwiper/ExtraInfoSwiper';
import { IQuizSectionQuizData } from '@components/web/src/organisms/TestResult/QuizSection/QuizSection';

const useTestResultData = () => {
  const { characters } = useCharacters();

  const { userCharacters, isFetchingUserData, userCharactersIds } = useUser();

  const userCharactersIndexedByProductCategory = charactersByProductCategory(userCharacters);
  const { locale, productCategory } = useApp();
  const { serviceInstanceProductCategory } = useServiceInstance();
  const { retailerLocationProductCategories, associatedRetailerLocation, retailerLocationCatalogType } =
    useRetailerLocation();
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [resultProductInstanceData, setResultProductInstanceData] =
    useState<Record<TProductCategory, TProductInstance>>(undefined);
  const allProductCategories =
    productCategory === PRODUCT_CATEGORY_NONE ? retailerLocationProductCategories : [productCategory];
  const availableProductCategories = serviceInstanceProductCategory
    ? [serviceInstanceProductCategory]
    : allProductCategories;
  const productCategories = availableProductCategories.filter(
    category => category in userCharactersIndexedByProductCategory,
  );

  const fetchProductsDataRef = useRef(false);
  const userCharacterData = characters
    .filter(character => isCharacterByIdentifiers(character, userCharactersIds))
    .find(character => isCharacterByServiceProductCategory(character));

  useEffect(() => {
    const fetchProductsData = async () => {
      setIsLoadingProduct(true);

      const isLimitedCatalogType = retailerLocationCatalogType === LIMITED_CATALOG;

      try {
        const productInstances = await Promise.all(
          productCategories.map(async category => {
            const params = {
              [GPRL_CHARACTER_QUERY]: userCharacterData?.identifier,
              [PRODUCT_CATEGORY_QUERY]: category,
              ...(isLimitedCatalogType && { [ASSOCIATED_RETAILER_SLUG_QUERY]: associatedRetailerLocation?.slug }),
              limit: 1,
              offset: 0,
            };

            if (isLimitedCatalogType) {
              const res = await getLimitedProductsListData(params);
              return res?.first_priority?.[0] || res?.second_priority?.[0] || res?.third_priority?.[0];
            }

            const res = await getProductsListData(params);
            return res.results?.[0];
          }),
        );

        const updatedProductInstanceData = productCategories.reduce((acc, category, index) => {
          acc[category] = productInstances[index];
          return acc;
        }, {});
        setResultProductInstanceData(updatedProductInstanceData);
      } finally {
        setIsLoadingProduct(false);
      }
    };

    if (!fetchProductsDataRef.current && !isLoadingProduct && productCategories.length && !resultProductInstanceData) {
      fetchProductsDataRef.current = true;
      fetchProductsData();
    }
  }, [productCategories, isLoadingProduct]);

  const translatedUserCharacters: Record<string, TCharacter> = useMemo(() => {
    if (!characters.length || !userCharactersIds.length || !locale) return null;

    return productCategories.reduce((acc, category) => {
      acc[category] = characters
        .filter(character => isCharacterByIdentifiers(character, userCharactersIds))
        .find(character => character.product_category === category);
      return acc;
    }, {});
  }, [characters, userCharactersIds, locale]);

  const quizData: Record<string, IQuizSectionQuizData[]> = useMemo(() => {
    if (!translatedUserCharacters || !locale) return null;

    const getNonUserCharacters = userCharacter =>
      shuffleArray(
        characters.filter(
          product =>
            product.identifier !== userCharacter.identifier &&
            product.product_category === userCharacter.product_category,
        ),
      )[0];

    return productCategories.reduce((acc, category) => {
      const filteredCharacters = getNonUserCharacters(translatedUserCharacters[category]);

      const getQuestion = characterData => {
        let question = '';
        if (category === PRODUCT_CATEGORY_WINE) {
          question = characterData.attributes[HARDCODED_ESSENCE_DATA[WINE_STRUCTURE_ESSENCE_ID].slug[locale]];
        } else if (category === PRODUCT_CATEGORY_BEER) {
          question = characterData.attributes[HARDCODED_ESSENCE_DATA[BEER_CREAMINESS_ESSENCE_ID].slug[locale]];
        } else if (category === PRODUCT_CATEGORY_COFFEE) {
          question = characterData.technical_description;
        }
        return question;
      };

      acc[category] = shuffleArray([
        {
          question: getQuestion(translatedUserCharacters[category]),
          result: true,
        },
        {
          question: getQuestion(filteredCharacters),
          result: false,
        },
      ]);
      return acc;
    }, {});
  }, [translatedUserCharacters, locale]);

  const charactersModalData: Record<string, TSwiperData[]> = useMemo(() => {
    if (!translatedUserCharacters || !locale) return null;

    const generateModalData = category => {
      const idToMove = translatedUserCharacters[category].identifier;
      const characterToMove = characters.find(character => character.identifier === idToMove);
      const filteredCharacters = characters.filter(
        character => character.product_category === category && character.identifier !== idToMove,
      );

      return [
        {
          description: characterToMove.character_description,
          image: characterToMove.image,
          productCategory: characterToMove.product_category,
          title: characterToMove.name,
        },
        ...filteredCharacters.map(item => ({
          description: item.character_description,
          image: item.image,
          productCategory: item.product_category,
          title: item.name,
        })),
      ];
    };

    return productCategories.reduce((acc, category) => {
      acc[category] = generateModalData(category);
      return acc;
    }, {});
  }, [translatedUserCharacters, characters]);

  const characterAdjectiveData: Record<string, ICharacterAdjectiveData> = useMemo(
    () => parseCharacterAdjectiveData(translatedUserCharacters, locale, productCategories),
    [translatedUserCharacters],
  );

  const isTestResultDataLoading =
    !translatedUserCharacters || !characterAdjectiveData || !charactersModalData || !quizData || isFetchingUserData;

  return {
    characterAdjectiveData,
    charactersModalData,
    isLoadingProduct,
    isTestResultDataLoading,
    productCategories,
    quizData,
    resultProductInstanceData,
    translatedUserCharacters,
  };
};

export default useTestResultData;
