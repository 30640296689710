import { actionChangeAgreements } from '@lib/core/service/slices/technical/agreements';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { useUser } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { actionAddToastMessage } from '@lib/tools/toastMessage/slices';
import { ToastMessageTypes } from '@lib/tools/toastMessage/utils/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useThirdPartyNewsletter = () => {
  const dispatch = useTypedDispatch();
  const { isUserAuthenticated, isUserSelectedUseThirdParty } = useUser();
  const { ToastMessages } = localeCommon;

  const isShowThirdPartyNewsletter = isUserAuthenticated && !isUserSelectedUseThirdParty;

  const handleThirdPartyNewsletter = async (isAccepted: boolean) => {
    try {
      await dispatch(
        actionChangeAgreements({
          [Agreements.isUserAllowedUseThirdParty]: isAccepted,
        }),
      ).unwrap();

      dispatch(
        actionAddToastMessage({
          message: ToastMessages.thirdPartyAgreementDescription,
          title: isAccepted ? ToastMessages.thirdPartyAgreementAccepted : ToastMessages.thirdPartyAgreementRejected,
          type: ToastMessageTypes.success,
        }),
      );
    } catch (error) {
      dispatch(
        actionAddToastMessage({
          message: ToastMessages.userDataFailed,
          type: ToastMessageTypes.error,
        }),
      );
    }
  };

  return {
    handleThirdPartyNewsletter,
    isShowThirdPartyNewsletter,
  };
};
