import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TProductInstance } from '@lib/core/products/types';
import { actionResetUserQuiz } from '@lib/core/quizzes/slices';
import { useRetailer } from '@lib/core/retailers/hooks';
import { actionGetRetailerDetail } from '@lib/core/retailers/slices';
import {
  selectKioskIsAnonymousPreference,
  selectKioskProductQuantity,
  selectKioskProducts,
  selectKioskProductsIsLoading,
} from '@lib/core/service/selectors/kioskRootSelector';
import { resetKioskHomePage } from '@lib/core/service/slices/kioskRootSlice';
import { resetPromotionProducts } from '@lib/core/service/slices/promotionProductsSlice';
import { prependBasename } from '@lib/core/service/utils';
import { detachFidelityCard } from '@lib/core/users/slices/fidelityCard';
import { actionResetProductList } from '@lib/core/users/slices/productList';
import { actionResetUserProfile } from '@lib/core/users/slices/profile';
import { actionSetUserCharacters } from '@lib/core/users/slices/user';
import { USER_PRODUCT_PREFERENCES } from '@lib/tools/shared/helpers/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

export const useKioskRoot = (): {
  handleResetKioskUserSession: (params: { showFidelityMessage?: boolean }) => void;
  products: TProductInstance[][];
  isProductsLoading: boolean;
  isAnonymousPreference: boolean;
  productQuantity: number;
} => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { retailerSlug } = useRetailer();

  const handleResetKioskUserSession = async ({ showFidelityMessage = false }: { showFidelityMessage?: boolean }) => {
    dispatch(detachFidelityCard({ displayMessage: showFidelityMessage }));
    dispatch(actionResetUserQuiz());
    dispatch(resetKioskHomePage());
    dispatch(resetPromotionProducts());
    dispatch(actionResetProductList());
    dispatch(actionSetUserCharacters([]));
    localStorage.removeItem(USER_PRODUCT_PREFERENCES);
    dispatch(actionResetUserProfile());
    dispatch(actionResetUserProfile());
    dispatch(actionGetRetailerDetail({ retailerSlug }));
    navigate(prependBasename(PAGES.vinhood.welcome));
  };

  return {
    handleResetKioskUserSession,
    isAnonymousPreference: useSelector(selectKioskIsAnonymousPreference),
    isProductsLoading: useSelector(selectKioskProductsIsLoading),
    productQuantity: useSelector(selectKioskProductQuantity),
    products: useSelector(selectKioskProducts),
  };
};
