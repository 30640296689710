import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';
import { IToastMessageSlice } from '@lib/tools/toastMessage/slices';

/**
 * Selector to get the toast message state from the store.
 * @param state The Redux state.
 * @returns The toast message slice of the state.
 */
const selectToastMessageState = (state: RootState): IToastMessageSlice | null => state?.toastMessage;

/**
 * Selector to get the toast message from the toast message slice.
 */
export const selectToastMessage = createSelector(
  [selectToastMessageState],
  toastMessageState => toastMessageState?.toastMessage,
);
