import { TProductInstance } from '@lib/core/products/types';
import { QA_URL_PARAM_CURRENT_DATE } from '@lib/core/service/consts';
import { store } from '@lib/core/service/store';

export const sortByProductPrice = (products: TProductInstance[]) =>
  [...products].sort((a, b) => {
    if (a?.price?.value < b?.price?.value) {
      return -1;
    }
    if (a?.price?.value > b?.price?.value) {
      return 1;
    }
    return 0;
  });

// ! Is it necessary for this to be stored in the application state?
export const getUniqueItemForToday = ({ itemArray, returnNumberOfUniqueItemForDay = 1 }) => {
  const { [QA_URL_PARAM_CURRENT_DATE]: QACurrentDateOnly } = store.getState().qaParameters;
  const newDate = new Date();
  const todayDate = Number(QACurrentDateOnly) || newDate.getDate();
  if (returnNumberOfUniqueItemForDay > 1) {
    const uniqueItemsOfDay = [];
    const arrayList = [...itemArray];
    for (let i = 1; i <= returnNumberOfUniqueItemForDay && arrayList.length > 0; i += 1) {
      const uniqueIndex = todayDate % arrayList.length;
      uniqueItemsOfDay.push(arrayList[uniqueIndex]);
      arrayList.splice(uniqueIndex, 1);
    }
    return uniqueItemsOfDay;
  }
  const uniqueIndexForDay = todayDate % itemArray.length;
  return [itemArray[uniqueIndexForDay]];
};

export const parseThunkError = action => {
  return action.payload?.errorMessage || action.error?.message || '';
};

export const currentDate = new Date();

export const currentYear = currentDate.getFullYear().toString();

export const fileNameGenerator = (name: string) => {
  if (name) {
    const slug = name.toLowerCase().replace(/\s+/g, '-');
    return slug.slice(0, 20);
  }
  return 'filename';
};

export const birthYearValidation = (birthYear?: string) => {
  const maxValidBirthYear = currentDate.getFullYear() - 18;
  return {
    isValidBirthYear: Number(birthYear) >= 1920 && Number(birthYear) <= maxValidBirthYear,
    maxValidBirthYear: maxValidBirthYear.toString(),
  };
};
