import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { apiUrlListProductsV1, apiUrlProductsByCharacterCorrelation } from '@lib/core/products/slices/urls';
import { TProductInstance } from '@lib/core/products/types';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { IToolkitRejectedAction } from '@lib/core/service/types/common';
import { selectUserCharacters } from '@lib/core/users/selectors/user';

interface IProductsSlice {
  isLoading: boolean;

  characterCorrelationList: TProductInstance[];
  error: string;
  list: TProductInstance[];
}

const initialState: IProductsSlice = {
  characterCorrelationList: [],
  error: '',
  isLoading: false,
  list: [],
};

export const actionGetAllProducts = createTypedAsyncThunk('products/all/get', async _ => {
  const { results } = await request(apiUrlListProductsV1());

  const sortByPortfolioOrder = list =>
    list.sort(
      ({ product: prod1 }, { product: prod2 }) => prod1.attributes.portfolio_order - prod2.attributes.portfolio_order,
    );

  return { data: sortByPortfolioOrder(results) };
});

export const getProductsByCharacterCorrelation = createTypedAsyncThunk(
  'products/correlation/get',
  async (_, { getState }) => {
    const characterIdentifiers = new Set();
    let randomizedProducts = [];

    try {
      const userCharacters = selectUserCharacters(getState());
      const requestData = await request(apiUrlProductsByCharacterCorrelation(userCharacters[0]?.identifier));
      const { results } = requestData;

      const shuffleProducts = arr => {
        const newArr = arr.slice();
        for (let i = newArr.length - 1; i > 0; i -= 1) {
          const rand = Math.floor(Math.random() * (i + 1));
          [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
        }
        return newArr;
      };

      results.forEach((gprl: TProductInstance) => {
        characterIdentifiers.add(gprl.product.character.identifier);
      });

      // * Randomize the order of the products within each character group
      characterIdentifiers.forEach(characterIdentifier => {
        const filteredProducts = results.filter(gprl => gprl.product.character.identifier === characterIdentifier);
        randomizedProducts = [...randomizedProducts, ...shuffleProducts(filteredProducts)];
      });
    } catch (e) {
      console.error(e);
    }
    return randomizedProducts;
  },
);

export const productsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetAllProducts.pending, state => {
      state.isLoading = true;
      state.error = '';
      state.list = [];
    });
    builder.addCase(
      actionGetAllProducts.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: TProductInstance[];
        }>,
      ) => {
        const { payload: { data } = {} } = action;

        state.isLoading = false;
        state.error = '';
        state.list = data;
      },
    );
    builder.addCase(actionGetAllProducts.rejected, (state, action: IToolkitRejectedAction) => {
      const { payload: { errors: { detail = '' } = {} } = {} } = action;
      state.isLoading = false;
      state.error = detail;
      state.list = [];
    });
    builder.addCase(getProductsByCharacterCorrelation.pending, state => {
      state.isLoading = true;
      state.error = '';
      state.characterCorrelationList = [];
    });
    builder.addCase(getProductsByCharacterCorrelation.fulfilled, (state, action: PayloadAction<TProductInstance[]>) => {
      const { payload } = action;

      state.isLoading = false;
      state.error = '';
      state.characterCorrelationList = payload;
    });
    builder.addCase(getProductsByCharacterCorrelation.rejected, (state, action: IToolkitRejectedAction) => {
      const { payload: { errors: { detail = '' } = {} } = {} } = action;
      state.isLoading = false;
      state.error = detail;
      state.characterCorrelationList = [];
    });
  },
  initialState,
  name: 'products',
  reducers: {
    actionResetCorrelatedProducts: state => {
      return {
        ...state,
        characterCorrelationList: [],
      };
    },
  },
});

export default productsSlice.reducer;
export const { actionResetCorrelatedProducts } = productsSlice.actions;
