import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApp } from '@lib/core/service/hooks';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { createTasteJourneyData } from '@lib/core/service/utils';
import { selectFeedbackData } from '@lib/core/users/selectors/feedback';
import { STYLE_NAME, TASTE_PATH_PRODUCT_STYLES } from '@lib/tools/shared/helpers/consts';
import { useTastePathProducts } from '@lib/tools/tastePathProducts/hooks/useTastePathProducts';
import { fetchTastePathProducts, setShouldSkipCategory } from '@lib/tools/tastePathProducts/slices/index';
import { useAddons } from '@lib/tools/views/hooks';

const useTastePathData = () => {
  const dispatch = useDispatch();

  const { isEnableTastePathAddon } = useAddons();
  const { locale } = useApp();
  const productCategory = useSelector(selectServiceProductCategory);
  const feedbackData = useSelector(selectFeedbackData);
  const { isTastePathProductsLoading, tastePathData, currentTastePathCategoryId, nextTastePathCategoryId } =
    useTastePathProducts();
  const nextTastePathCategory =
    (nextTastePathCategoryId &&
      TASTE_PATH_PRODUCT_STYLES[productCategory][nextTastePathCategoryId]?.[`${STYLE_NAME}${locale}`]) ||
    '';

  const currentTastePathProduct = tastePathData?.[currentTastePathCategoryId]?.products.find(
    product => !feedbackData.some(feedback => product.identifier === feedback.gprl.identifier),
  );

  const currentTastePathCategoryIdProductsLength = tastePathData?.[currentTastePathCategoryId]?.products.length;

  const shouldChangeTastePathCategory =
    feedbackData.filter(product => product?.gprl?.character?.type?.identifier === currentTastePathCategoryId).length >=
    currentTastePathCategoryIdProductsLength;

  const shouldDisplaySkipCategoryBtn = tastePathData
    ? Object.values(tastePathData).filter(category => category).length -
        Object.values(tastePathData).filter(category => category.shouldSkipCategory).length !==
      1
    : false;

  const isPathDone = (feedbacks, pathData) => {
    const countMap = {};

    feedbacks.forEach(item => {
      const { gprl: { character: { type: { identifier = '' } = {} } = {} } = {} } = item;
      countMap[identifier] = (countMap[identifier] || 0) + 1;
    });

    return Object.keys(pathData).every(key => {
      const tastePathDataLength = pathData[key].products.length;
      return countMap[key] >= tastePathDataLength;
    });
  };

  const isTastePathDone =
    tastePathData &&
    (Object.values(tastePathData).every(item => item.shouldSkipCategory) || isPathDone(feedbackData, tastePathData));

  const tasteJourneyData = useMemo(() => {
    if (!tastePathData) return null;
    return createTasteJourneyData({
      currentTastePathCategoryId,
      feedbackData,
      locale,
      productCategory,
      tastePathData,
    }).filter(item => item);
  }, [tastePathData, feedbackData, currentTastePathCategoryId, productCategory, locale]);

  useEffect(() => {
    if (isEnableTastePathAddon && !tastePathData) {
      /** Fetch products data and create a new Taste Path Data */
      dispatch(fetchTastePathProducts());
    }
  }, []);

  useEffect(() => {
    if (shouldChangeTastePathCategory && tastePathData) {
      dispatch(setShouldSkipCategory({ currentTastePathCategoryId, shouldChangeTastePathCategory }));
    }
  }, [shouldChangeTastePathCategory]);

  return {
    currentTastePathCategoryId,
    currentTastePathProduct,
    isTastePathDone,
    isTastePathProductsLoading,
    nextTastePathCategory,
    shouldChangeTastePathCategory,
    shouldDisplaySkipCategoryBtn,
    tasteJourneyData,
    tastePathData,
  };
};

export default useTastePathData;
