import { IApiUrlGetProductProps } from '@lib/core/products/types';
import { getRequestCommonParameters } from '@lib/core/service/requests/api';

/**
 * All gprls list for pmi
 */
// extended
export const apiUrlListProductsV1 = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/products/${retailerSlug}/${retailerLocationSlug}/generic-product-retailer-location/?page_size=100`;
};

/**
 * All gprls list
 */
// extended
export const apiUrlAllProducts = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/products/${retailerSlug}/${retailerLocationSlug}/generic-product-retailer-location/`;
};

// limited
export const apiUrlLimitedProducts = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/products/${retailerSlug}/${retailerLocationSlug}/limited-generic-products-catalog/`;
};

// search
export const apiUrlSearchedProducts = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/search/${retailerSlug}/${retailerLocationSlug}/`;
};

/**
 * Returns product instance data
 */
export const apiUrlGetProduct = ({
  productId,
  customRetailerSlug,
  customRetailerLocationSlug,
}: IApiUrlGetProductProps) => {
  const { retailerSlug: defaultRetailerSlug, retailerLocationSlug: defaultRetailerLocationSlug } =
    getRequestCommonParameters();

  const retailerSlug = customRetailerSlug || defaultRetailerSlug;
  const retailerLocationSlug = customRetailerLocationSlug || defaultRetailerLocationSlug;

  return `/products/${retailerSlug}/${retailerLocationSlug}/generic-product-retailer-location/${productId}/`;
};

/**
 * Products sorted by character correlation
 */
export const apiUrlProductsByCharacterCorrelation = (characterId: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/products/character/${characterId}/location/${retailerLocationSlug}/retailer/${retailerSlug}/products/`;
};

/**
 * Used for get Product Sets basic data for TA quiz extra pages
 *
 * @returns url for get Product Sets basic data for TA quiz extra pages
 */
export const productSetsApiUrlCreator = ({ characterID, mood }: { characterID: string; mood: string }) => {
  return `/products/product-sets/${characterID}/mood/${mood}/`;
};

/**
 * Used for get Product Sets full data for TA quiz extra pages
 *
 * @returns url for get Product Sets full data for TA quiz extra pages
 */
export const productSetProductsApiUrlCreator = (productSetIds: string[]) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `products/retailer/${retailerSlug}/retailer-location/${retailerLocationSlug}/product-sets/generic-product-retailer-locations/${
    productSetIds ? `?product_set_identifiers=${productSetIds.slice(0, 2).join(',')}` : ''
  }`;
};

/**
 * Used for get all Usr Product Preferences for current retailer
 *
 * @returns url for get User Product Preferences for current retailer
 */
export const userProductPreferencesApiUrl = '/products/user-product-preferences/';
