/* eslint-disable @typescript-eslint/no-unused-vars */
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TocIcon from '@mui/icons-material/Toc';
import { Alert, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import * as d3 from 'd3';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import CSVReader from 'react-csv-reader';
import { useDispatch } from 'react-redux';

import { useChangedNodes } from '@lib/core/quizzes/hooks/changedNodes';
import { useQuiz } from '@lib/core/quizzes/hooks/quiz';
import { useQuizzes } from '@lib/core/quizzes/hooks/quizzes';
import { useScoreMatrix } from '@lib/core/quizzes/hooks/scoreMatrix';
import { updateQuizPathAndData } from '@lib/core/quizzes/slices/quiz';
import { updateScoreMatrix, updateScoreMatrixByChangedNodes } from '@lib/core/quizzes/slices/scoreMatrix';
import { apiUrlGetCsv } from '@lib/core/quizzes/slices/urls';
import { TQuiz } from '@lib/core/quizzes/types';
import { isQuizForProductCategory } from '@lib/core/quizzes/utils/filters';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import request from '@lib/core/service/requests/request';
import { setProductCategory } from '@lib/core/service/slices';
import StyledTooltip from '@lib/tools/devtools/components/feedback/Tooltip';
import { Button } from '@lib/tools/devtools/components/inputs/Button';
import { ITableData, ITableHead } from '@lib/tools/devtools/components/interfaces';
import { TableComponent } from '@lib/tools/devtools/components/table/index';
import { themeDevTools } from '@lib/tools/devtools/components/theme';
import LayoutFlow from '@lib/tools/devtools/panels/sensory/charts/QuizLayout';
import { QuizSankey } from '@lib/tools/devtools/panels/sensory/charts/QuizSankey';
import ScoreMatrixUtils from '@lib/tools/devtools/panels/sensory/utils/weights';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';

export const Snowball = () => {
  const { retailerLocationProductCategories } = useRetailerLocation();
  const { quizzes: allQuizzes } = useQuizzes();
  const { quizData, quizPath, quizId } = useQuiz();
  const quizzes: TQuiz[] = allQuizzes?.filter(isQuizForProductCategory) || [];

  const { productCategory } = useApp();
  const { scoreMatrix } = useScoreMatrix();
  const { changedNodes } = useChangedNodes();

  const dispatch = useDispatch();
  const [selectedCharacters, setCharacters]: [any, any] = useState([]);
  const [snowballWorker, setSnowballWorker] = useState(null);

  const [firstQuestion, setFirstQuestion] = useState('');
  const [traversedTree, setTraversedTree] = useState({});
  const [viewData, setViewData] = useState(null);
  const [sumOfScoresView, setSumOfScoresView] = useState({});
  const [lastAnswers, setLastAnswers] = useState([]);
  const [characterDistributions, setCharacterDistributions] = useState([]);
  const [nodes, setNodes]: any = useState([]);
  const [edges, setEdges]: any = useState([]);
  const [finalSankeyData, setSankeyData] = useState([['From', 'To', 'Weight']]);
  const [isDominantPathOpen, setIsDominantPathOpen] = useState(false);
  const [isTableViewOpen, setIsTableViewOpen] = useState(false);
  const [isRetrieved, setIsRetrieved] = useState(false);
  const largeArrayRef = useRef([]);
  const [downloadData, setDownloadData]: any = useState([]);
  const [isAllPathsOpen, setAllPathsOpen] = useState(false);
  const [tabValue, setTabValue] = useState('');
  const [isScoreMatrixError, setScoreMatrixError] = useState(false);

  const optionsProductCategories = [<option key="none">none</option>];
  retailerLocationProductCategories.forEach((category, i) => {
    optionsProductCategories.push(
      <option key={i} value={category}>
        {category}
      </option>,
    );
  });
  const ProductCategorySelect = (
    <select name="product" value={productCategory} onChange={e => dispatch(setProductCategory(e.target.value))}>
      {optionsProductCategories}
    </select>
  );
  const optionsQuizzes = [
    <option key="" disabled>
      select quiz
    </option>,
  ];
  quizzes.forEach((quiz: TQuiz, i) => {
    optionsQuizzes.push(
      <option key={i} value={i}>
        {quiz.identifier} - {quiz.slug} - {quiz.quiz_type}
      </option>,
    );
  });

  useEffect(() => {
    if (window.Worker) {
      const worker = new Worker('/worker.js');

      worker.onmessage = workerResult => {
        if (Object.keys(workerResult?.data).length) {
          const {
            characterDistributions: workerCharacterDistributions,
            nodes: workerNodes,
            edges: workerEdges,
            firstQuestionId: workerFirstQuestionId,
            lastAnswersArray: workerLastAnswersArray,
            traversedTree: workerTraversedTree,
            sumOfScoresView: workerSumOfScoresView,
            viewData: workerViewData,
            characters: workerCharacters,
          } = workerResult.data;

          setCharacters(workerCharacters);
          setNodes(workerNodes);
          setEdges(workerEdges);
          setFirstQuestion(workerFirstQuestionId);
          setLastAnswers(workerLastAnswersArray);
          setTraversedTree(workerTraversedTree);
          setSumOfScoresView(workerSumOfScoresView);
          setViewData(workerViewData);
          setCharacterDistributions(workerCharacterDistributions);
          setIsRetrieved(false);

          if (isScoreMatrixError) setScoreMatrixError(false);
        } else {
          setScoreMatrixError(true);
        }
      };

      setSnowballWorker(worker);
    } else {
      console.log("Your browser doesn't support web workers.");
    }
  }, []);

  async function updateSnowballState() {
    const payload = {
      quizData,
      quizPath,
      scores: scoreMatrix[productCategory],
    };

    snowballWorker.postMessage(payload);
  }

  let paths = [];

  type Order = 'asc' | 'desc';
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const headCells: readonly ITableHead[] = [
    {
      alignRight: false,
      id: 'text',
      label: 'Answers',
    },
    {
      alignRight: true,
      id: 'character',
      label: 'Character',
    },
    {
      alignRight: true,
      id: 'score',
      label: 'Score',
    },
  ];

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITableData>('index');

  const handleRequestSort = (property: keyof ITableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createPath = (character: string, index: number, score: number, usefulData: any) => {
    const preparedData: any = [];

    // const viewDataQuestions = avalancheObject.viewData.questions || {};
    // const viewDataAnswers = avalancheObject.viewData.answers || {};

    for (let loopIndex = 0; loopIndex < usefulData.questions.length; loopIndex += 1) {
      const answerID = usefulData.answers[loopIndex];
      const questionID = usefulData.questions[loopIndex];
      const questionText = quizData[questionID]?.text || `ERROR ${questionID}`;

      const answerObject = quizData[questionID].answers.find(answer => Object.keys(answer)[0] === answerID);
      const answerText = answerObject ? answerObject[answerID]?.text : `ERROR ${answerID}`;
      const answerTags = answerObject ? answerObject[answerID]?.tags : [];

      preparedData.push({
        answer_id: answerID,
        // answer_index: usefulData.answerIndices[loopIndex],
        answer_tags: answerTags,
        answer_text: answerText,
        question_id: questionID,
        question_text: questionText,
      });
    }

    return {
      character,
      history: preparedData,
      index,
      score,
    };
  };

  useEffect(() => {
    if (productCategory !== 'none') {
      // it means that it's the first time it's fetched, not coming from scoreMatrixPanel
      if (!(Object.keys(scoreMatrix).length && scoreMatrix[productCategory])) {
        const fetchScoreMatrix = async () => {
          try {
            const response = await request(apiUrlGetCsv(productCategory), { ignoreProfileHeaders: true });
            const rawScoreMatrix = d3.csvParse(response);

            const fetchedScoreMatrix = ScoreMatrixUtils.transformRawScoreMatrix(rawScoreMatrix);

            dispatch(
              updateScoreMatrix({ scoreMatrixData: fetchedScoreMatrix, selectedProductCategory: productCategory }),
            );
          } catch (error) {
            console.error('Error fetching and processing data:', error);
          }
        };

        fetchScoreMatrix();
      }
    }
  }, [productCategory]);

  useEffectSkipFirst(() => {
    // if it's the first time
    if (Object.keys(scoreMatrix).length) {
      updateSnowballState();
    }
  }, [scoreMatrix]);

  useEffect(() => {
    if (isDominantPathOpen || isTableViewOpen) {
      setIsRetrieved(true);
    }
  }, [finalSankeyData]);

  useEffect(() => {
    if (isDominantPathOpen || isTableViewOpen) {
      const copy: any = [['From', 'To', 'Weight']];
      const sankeyData: any = {};

      paths = [];

      selectedCharacters?.forEach((character: any) => {
        const traversedPaths = traversedTree[character];

        traversedPaths?.forEach((path: string[], pathIndex: number) => {
          const usefulData = {
            answers: {},
            questions: {},
          };

          const questions = path.filter((item: string) => item.includes('QQ'));
          const answers = path.filter((item: string) => item.includes('QA'));

          usefulData.questions = questions;
          usefulData.answers = answers;

          answers.forEach((answerId: string, index: number) => {
            if (!sankeyData[answerId]) sankeyData[answerId] = {};
            if (typeof sankeyData[answerId][answers[index + 1]] === 'undefined')
              sankeyData[answerId][answers[index + 1]] = 0;
            else if (typeof answers[index + 1] !== 'undefined')
              sankeyData[answerId][answers[index + 1]] = sankeyData[answerId][answers[index + 1]] + 1;
          });

          paths.push(createPath(character, pathIndex, sumOfScoresView[character], usefulData));
        });
      });

      largeArrayRef.current = paths;

      if (viewData?.answers) {
        const viewDataAnswers = viewData.answers || {};

        Object.keys(sankeyData).forEach((answerId: any) => {
          const relatedAnswerIdKeys: any = Object.keys(sankeyData[answerId]);
          const relatedAnswerIdWeights: any = Object.values(sankeyData[answerId]);

          let answerIdText = '???';
          let relatedAnswerIDText = '???';

          if (viewDataAnswers[answerId]?.text) {
            answerIdText = viewDataAnswers[answerId].text;
          } else {
            answerIdText = answerId;
          }

          relatedAnswerIdKeys.forEach((_, i) => {
            if (answerId && relatedAnswerIdKeys[i] && relatedAnswerIdWeights[i]) {
              if (viewDataAnswers[relatedAnswerIdKeys[i]] && viewDataAnswers[relatedAnswerIdKeys[i]].text) {
                relatedAnswerIDText = viewDataAnswers[relatedAnswerIdKeys[i]].text;
              } else {
                relatedAnswerIDText = relatedAnswerIdKeys[i];
              }

              copy.push([answerIdText, relatedAnswerIDText, relatedAnswerIdWeights[i]]);
            }
          });
        });
      }

      setSankeyData(copy);
    }
  }, [isDominantPathOpen, isRetrieved, isTableViewOpen]);

  useEffect(() => {
    if (productCategory && Object.keys(traversedTree).length && selectedCharacters?.length) {
      if (changedNodes && Object.keys(changedNodes).length) {
        dispatch(updateScoreMatrixByChangedNodes({ changedNodes, productCategory }));
      }
    }
  }, [quizPath, quizData]);

  useEffect(() => {
    if (productCategory !== 'none') dispatch(updateQuizPathAndData(changedNodes[productCategory]));
  }, [tabValue]);

  const getCurrentNodeEdgeInfo = (currentNodes, currentEdges) => {
    setNodes(currentNodes);
    setEdges(currentEdges);
    setAllPathsOpen(false);
    dispatch(updateQuizPathAndData(changedNodes[productCategory]));
  };

  const checkIfReadyToBeDownloaded = () => {
    const nodesCopy = [...nodes];

    const erroneousNodes = [];

    nodesCopy.forEach((node: any) => {
      if (node.id.startsWith('QA')) {
        if (!node.parent || !node.parent.length) {
          erroneousNodes.push(node.id);
        } else if (!node.children || !node.children.length) {
          if (!lastAnswers.includes(node.id)) {
            erroneousNodes.push(node.id);
          }
        }
      } else if (node.id !== firstQuestion && (!node.parent || !node.parent.length)) {
        erroneousNodes.push(node.id);
      } else if (!node.children || !node.children.length) {
        erroneousNodes.push(node.id);
      }
    });
    return !erroneousNodes.length; // Return true if it's ready, false otherwise
  };

  const handleDownloadClick = async () => {
    if (checkIfReadyToBeDownloaded) {
      const csvArray: any = [];
      const { scores } = scoreMatrix[productCategory];

      const { characters } = scoreMatrix[productCategory];

      const headings = [
        'quiz_identifier',
        'quiz_desc',
        'product_category',
        'question_id',
        'answer_id',
        'ordering',
        'question_test_en',
        'answer_text_en',
        'question_min_choices',
        'question_max_choices',
        'question_r_1',
        'question_r_2',
      ];

      characters.forEach(character => {
        headings.push(character);
      });
      csvArray.push(headings);

      let children = [];
      const ProductCategory: string = productCategory;
      const QuizId: string = quizId;
      let item: any = [];

      for (let i = 0; i < nodes.length; i += 1) {
        item = [];
        if (nodes[i].id.startsWith('QA') && nodes[i].parent) {
          // meaning that we are looking an answer node

          const foundParent = nodes.find((parent: any) => parent.id === nodes[i].parent);
          const parentID = foundParent.id; // last parent
          // the parent that we connected
          const childrenArray = foundParent.children;

          const index = childrenArray.indexOf(nodes[i].id);

          let questionText;
          if (!quizData[parentID]) {
            if (foundParent.text) {
              questionText = foundParent.text;
            } else questionText = '';
          } else {
            questionText = quizData[parentID].text;
          }

          const answerText = nodes[i].text;

          item.push(QuizId);
          item.push('');
          item.push(ProductCategory);
          item.push(parentID);

          item.push(nodes[i].id);
          item.push(index);
          item.push(questionText);
          item.push(answerText);
          item.push('1');
          item.push('1');

          let count = 0;

          if (nodes[i].children) {
            children = nodes[i].children;
            const tempItems = [];

            children.forEach((nextQuestion: string) => {
              tempItems.push(nextQuestion);
              count += 1;
            });

            item.push(...tempItems);

            const total = 2 - count;
            for (let c = 0; c < total; c += 1) {
              item.push('');
            }
          } else {
            for (let c = 0; c < 2; c += 1) {
              item.push('');
            }
          }

          let scoreArray;
          const nodeId = nodes[i].id;

          if (changedNodes[productCategory] && Object.keys(changedNodes[productCategory]).includes(nodeId)) {
            scoreArray = changedNodes[productCategory][nodeId].scoresArray;
          } else {
            const foundKey = Object.keys(scores).find(key => {
              const answersObj = scores[key];
              const answerArray = Object.keys(answersObj);
              return answerArray.includes(nodeId);
            });

            scoreArray = scores[foundKey][nodeId];
          }

          for (let b = 0; b < scoreArray.length; b += 1) {
            item.push(scoreArray[b]);
          }
          csvArray.push(item);
        }
      }
      setDownloadData(csvArray);
    }
  };

  const handleToggle = (_event: MouseEvent<HTMLElement>, value) => {
    if (value === 'dominantPaths') {
      setIsDominantPathOpen(true);
      setAllPathsOpen(false);
      setIsTableViewOpen(false);
    }
    if (value === 'tableView') {
      setIsTableViewOpen(true);
      setIsDominantPathOpen(false);
      setAllPathsOpen(false);
    }
    if (value === 'allPathsVisualisation') {
      setAllPathsOpen(true);
      setIsTableViewOpen(false);
      setIsDominantPathOpen(false);
    }
    setTabValue(value);
  };

  const handleFileLoaded = data => {
    const uploadedScoreMatrix = ScoreMatrixUtils.transformRawScoreMatrix(data);

    dispatch(updateScoreMatrix({ scoreMatrixData: uploadedScoreMatrix, selectedProductCategory: productCategory }));
  };
  return (
    <>
      <div className="panel">
        <span className="title">Product Categories</span> {ProductCategorySelect}
      </div>

      <div>
        {selectedCharacters?.length > 0 && !isScoreMatrixError && (
          <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <ToggleButtonGroup exclusive aria-label="locale-toggles" value={tabValue} onChange={handleToggle}>
                <StyledTooltip placement="bottom" title="Dominant Paths">
                  <ToggleButton
                    aria-label="dominantPaths"
                    value="dominantPaths"
                    sx={{
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: `${themeDevTools.color.orange}`,
                      },
                      '&:hover': {
                        background: `${themeDevTools.color.orange}`,
                      },
                    }}
                  >
                    <SsidChartIcon
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: `${themeDevTools.color.black}`,
                        },
                        color: `${themeDevTools.color.white}`,
                        height: `${themeDevTools.spacing.large}`,
                        width: `${themeDevTools.spacing.large}`,
                      }}
                    />
                  </ToggleButton>
                </StyledTooltip>

                <StyledTooltip placement="bottom" title="Table View">
                  <ToggleButton
                    aria-label="tableView"
                    value="tableView"
                    sx={{
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: `${themeDevTools.color.orange}`,
                      },
                      '&:hover': { background: `${themeDevTools.color.orange}` },
                    }}
                  >
                    <TocIcon
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: `${themeDevTools.color.black}`,
                        },
                        color: `${themeDevTools.color.white}`,
                        height: `${themeDevTools.spacing.large}`,
                        width: `${themeDevTools.spacing.large}`,
                      }}
                    />
                  </ToggleButton>
                </StyledTooltip>

                <StyledTooltip placement="bottom" title="All Paths Visualisation">
                  <ToggleButton
                    aria-label="allPathsVisualisation"
                    value="allPathsVisualisation"
                    sx={{
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: `${themeDevTools.color.orange}`,
                      },
                      '&:hover': { background: `${themeDevTools.color.orange}` },
                    }}
                  >
                    <DeviceHubIcon
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: `${themeDevTools.color.black}`,
                        },
                        color: `${themeDevTools.color.white}`,
                        height: `${themeDevTools.spacing.large}`,
                        width: `${themeDevTools.spacing.large}`,
                      }}
                    />
                  </ToggleButton>
                </StyledTooltip>
              </ToggleButtonGroup>
              <div style={{ margin: '5px' }}>
                {Object.keys(traversedTree).length ? (
                  <Button>
                    <CSVLink
                      data={downloadData}
                      style={{ color: 'white', textDecoration: 'none' }}
                      onClick={handleDownloadClick}
                    >
                      Save Excel
                    </CSVLink>
                  </Button>
                ) : (
                  <div />
                )}
              </div>
            </div>
            {characterDistributions && isDominantPathOpen && isRetrieved && <QuizSankey data={finalSankeyData} />}
            {isRetrieved && isTableViewOpen && (
              <TableComponent
                rowData={largeArrayRef.current?.slice().sort(getComparator(order, orderBy))}
                rowType="SnowballRow"
                tableHeadInfo={{ handleRequestSort, headCells, order, orderBy }}
              />
            )}
            {isAllPathsOpen && nodes?.length && Object.values(scoreMatrix).length && Object.keys(viewData)?.length && (
              <div
                style={{
                  height: window.innerHeight * 0.7,
                  overflow: 'scroll',
                  position: 'relative',
                }}
              >
                <LayoutFlow
                  firstQuestion={firstQuestion}
                  getCurrentNodeEdgeInfo={getCurrentNodeEdgeInfo}
                  initialEdges={edges}
                  initialNodes={nodes}
                  lastAnswers={lastAnswers}
                  scoreMatrix={scoreMatrix[productCategory]}
                  selectedProductCategory={productCategory}
                />
              </div>
            )}
          </>
        )}

        {selectedCharacters?.length === 0 && !isScoreMatrixError && (
          <CircularProgress
            style={{
              left: '50%',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
        {isScoreMatrixError && (
          <div style={{ left: '50%', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <Alert severity="error">The ScoreMatrix is not updated, there are mismatched questions/answers</Alert>
            <div className="csv-reader-container">
              <CSVReader parserOptions={{ header: true }} onFileLoaded={handleFileLoaded} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
