import { useSelector } from 'react-redux';

import {
  selectAppAgreements,
  selectIsClientIpBlocked,
  selectIsClientIpLoading,
  selectIsGoogleCaptchaIsDisabled,
  selectIsScannerLoading,
} from '@lib/core/service/selectors/technical';

export const useTechnical = () => {
  return {
    appAgreements: useSelector(selectAppAgreements),
    isCaptchaDisabled: useSelector(selectIsGoogleCaptchaIsDisabled),
    isClientIpBlocked: useSelector(selectIsClientIpBlocked),
    isClientIpLoading: useSelector(selectIsClientIpLoading),
    isScannerLoading: useSelector(selectIsScannerLoading),
  };
};
