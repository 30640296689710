import { FC } from 'react';

import { IPreference } from '@app/native/src/models/UserFoodPreferences.model';
import CharacterMapScrollerContainer from '@app/web/src/containers/CharacterMapScrollerContainer';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { ILocation, IUpdateLocationListParams } from '@lib/core/users/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  JOURNAL_PLACES_VARIANT,
  JOURNAL_PRODUCT_VARIANT,
  PRODUCT_CATEGORY_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import RegistrationBanner from '@components/web/src/components/Authentication/RegistrationBanner/RegistrationBanner';
import { ICharacterInfoCardProps } from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';
import ProfileProgressBar from '@components/web/src/organisms/Cards/MyTasteProfileCard/ProfileProgressBar/ProfileProgressBar';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import CharacterAdjectivesGroup, {
  ICharacterAdjectiveData,
} from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import CharacterInfoGroup from '@components/web/src/organisms/CharacterInfoGroup/CharacterInfoGroup';
import BuyerPrinciples from '@components/web/src/templates/TasteId/TasteIdPage/BuyerPrinciples/BuyerPrinciples';
import { JournalSwiper } from '@components/web/src/templates/TasteId/TasteIdPage/JournalSwiper/JournalSwiper';
import RedirectFooter from '@components/web/src/templates/TasteId/TasteIdPage/RedirectFooter/RedirectFooter';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/styles';

interface IProps {
  locale: string;
  characterAdjectiveData: ICharacterAdjectiveData;
  characterData: TCharacter;
  productCategory: TProductCategory;
  feedbackData: IFeedbackData[];
  wishlistProductIds: string[];
  characterDetailCardsData: IReadMoreCardProps[];
  characterInfoCardsData: ICharacterInfoCardProps[];
  isTasteIdResultPageAddon?: boolean;
  isProductListLoading?: boolean;
  isUserAuthenticated?: boolean;
  isProductInWishlist?: boolean;
  userPreferences: IPreference[];
  storeType: TRetailerLocationStoreType;
  productSwiperData?: TProductInstance[];
  profileProgress: number;
  locationSwiperData?: ILocation[];
  isLocationListLoading: boolean;
  handleUpdateLocationList: ({ retailerLocationSlug }: IUpdateLocationListParams) => void;
  navigateToJournalPage?: () => void;
  handleNavigateClick?: (link: string, params?: { [key: string]: string }) => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string) => void;
}

const TasteIdPage: FC<IProps> = ({
  locale,
  characterAdjectiveData,
  characterData,
  productCategory,
  characterDetailCardsData,
  characterInfoCardsData,
  isTasteIdResultPageAddon = false,
  isUserAuthenticated = false,
  isProductListLoading = false,
  isLocationListLoading,
  feedbackData,
  storeType,
  wishlistProductIds,
  isProductInWishlist,
  userPreferences,
  productSwiperData,
  profileProgress,
  locationSwiperData,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  handleNavigateClick,
  handleUpdateLocationList,
}) => {
  const { completeProfile } = localeCommon.tasteId;

  return (
    <S.TasteIdPageContainer data-testid={`TasteIdPage-${productCategory}`}>
      {profileProgress < 100 && (
        <S.ProfileProgressBarWrapper>
          <ProfileProgressBar
            progress={profileProgress}
            title={completeProfile}
            variant="detached"
            onClick={() =>
              handleNavigateClick(PAGES.vinhood.tasteId.tasteIdSettings, {
                [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
              })
            }
          />
        </S.ProfileProgressBarWrapper>
      )}
      <CharacterAdjectivesGroup
        characterAdjectiveData={characterAdjectiveData}
        characterData={characterData}
        isUserAuthenticated={isUserAuthenticated}
        productCategory={productCategory}
      />
      <S.CharacterDetailsSection data-testid="CharacterDetailsSection">
        {characterDetailCardsData?.map(({ description, cardTitle, title, minimumCharacters }, idx) => (
          <ReadMoreCard
            key={idx}
            cardTitle={cardTitle}
            cardTitleLocaleIndex={{ productCategory }}
            description={description}
            minimumCharacters={minimumCharacters}
            productCategory={productCategory}
            title={title}
          />
        ))}
      </S.CharacterDetailsSection>
      <CharacterInfoGroup characterInfoList={characterInfoCardsData} />
      {isUserAuthenticated && !isTasteIdResultPageAddon && (
        <>
          <BuyerPrinciples
            navigateToTagsPage={() => handleNavigateClick(PAGES.vinhood.tasteId.tasteIdTags)}
            productCategory={productCategory}
            userPreferences={userPreferences}
          />
          <JournalSwiper
            shouldShowFeedbackTooltip
            feedbackData={feedbackData}
            handleEmptyStateNavigate={url => handleNavigateClick(url)}
            handleNavigateClick={handleNavigateClick}
            handleUpdateFeedback={handleUpdateFeedback}
            handleUpdateWishlistProductList={handleUpdateWishlistProductList}
            isProductInWishlist={isProductInWishlist}
            isProductListLoading={isProductListLoading}
            locale={locale}
            productCategory={productCategory}
            storeType={storeType}
            swiperData={productSwiperData}
            variant={JOURNAL_PRODUCT_VARIANT}
            wishlistProductIds={wishlistProductIds}
          />
          {isUserAuthenticated && (
            <JournalSwiper
              handleEmptyStateNavigate={url => handleNavigateClick(url)}
              handleNavigateClick={handleNavigateClick}
              handleUpdateLocationList={handleUpdateLocationList}
              isLocationListLoading={isLocationListLoading}
              productCategory={productCategory}
              swiperData={locationSwiperData}
              variant={JOURNAL_PLACES_VARIANT}
            />
          )}
        </>
      )}
      <CharacterMapScrollerContainer characterName={characterData?.name} productCategory={productCategory} />
      {!isUserAuthenticated && isTasteIdResultPageAddon && (
        <S.BannerWrapper>
          <RegistrationBanner
            handleNavigateToRegistrationPage={() => handleNavigateClick(PAGES.vinhood.registration)}
          />
        </S.BannerWrapper>
      )}
      {isUserAuthenticated && !isTasteIdResultPageAddon && <RedirectFooter handleNavigateClick={handleNavigateClick} />}
    </S.TasteIdPageContainer>
  );
};

export default TasteIdPage;
