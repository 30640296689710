import { css, styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import { STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const CharacterInfoCard = styled(Flexbox)<{ $productCategory: TProductCategory }>`
  width: 100%;
  min-height: 80px;
  padding: 0 0 0 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: ${STATIC_SHADOWS.sm};

  ${({ $productCategory }) => {
    return css`
      background: ${STATIC_GRADIENTS.productGradients[$productCategory]};
    `;
  }}
`;

export const ProductHeader = styled(Flexbox)`
  flex-grow: 0;
  flex-shrink: 0;
  width: 55%;
  padding: ${({ theme }) => (theme?.isKioskRootElementType ? '4px 0' : '10px 0 4px')};
`;

export const TechnicalCaption = styled(Text)`
  min-height: 32px;
  max-height: 32px;
`;

export const ProductImage = styled.div`
  width: 90px;
  height: 80px;
  margin-top: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
