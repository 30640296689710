import { styled } from 'styled-components';

import { StyledTooltipProps } from '@components/web/src/atoms/Tooltips/types';
import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const Tooltip = styled.div<StyledTooltipProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 4px;
  align-items: start;
  border-radius: 8px;
  width: fit-content;
  max-width: 320px;
  box-shadow: ${STATIC_SHADOWS.lg};
  padding: ${({ $isDescription }) => ($isDescription ? '12px;' : '8px 12px;')};
  background: ${({ $variant }) => {
    switch ($variant) {
      case 'light':
        return STATIC_COLORS.base.white;
      case 'dark':
        return STATIC_COLORS.gray['500'];
      case 'green':
        return STATIC_COLORS.green['600'];
      case 'gradient':
        return STATIC_GRADIENTS.primaryGradient;
      default:
        return STATIC_COLORS.base.white;
    }
  }};
  p {
    margin: 0px;
  }
`;
