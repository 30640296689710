import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import { ReactComponent as SearchIcon } from '@components/web/src/assets/legacy/icons/icon_search.svg';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import InputField from '@components/web/src/components/Input/InputFields';
import { IB2CRecipe } from '@components/web/src/components/Recipe/RecipeItem/RecipeItem';
import RecipeResult from '@components/web/src/components/Recipe/RecipeResult/RecipeResult';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/styles';

interface IProps {
  searchQuery: string;
  recipes: IB2CRecipe[];
  foodPairingIcon: string;
  isLoading: boolean;
  isNoRecipesFound: boolean;
  isRecipeQuizAvailable: boolean;
  productCategory: TProductCategory;
  redirectToPreviousPage: () => void;
  redirectToTakeTest: () => void;
  searchHandler: (searchText: string) => void;
}

const FoodPairingPage = ({
  searchQuery,
  recipes,
  foodPairingIcon,
  isLoading,
  isNoRecipesFound,
  isRecipeQuizAvailable,
  productCategory,
  redirectToPreviousPage,
  redirectToTakeTest,
  searchHandler,
}: IProps) => {
  const {
    commonMessages: { goBackBtn },
    foodPairingPage: { searchPlaceholder, title, description, btnText, inputSuggestion, noResult },
  } = localeCommon;

  const { publishedTerms } = LocaleUtils;
  const searchPlaceholderText = publishedTerms[searchPlaceholder?.id];

  return (
    <Flexbox isFullWidth align="center" data-testid="FoodPairingPage" direction="column" gap={32} padding="32px 16px">
      <Image alt="recipes" height="157px" src={foodPairingIcon} width="157px" />
      <Flexbox align="flex-start" direction="column" gap={12}>
        <Text fontFamily="Fraunces" size="h5" text={title} weight="semibold" />
        <Text color={STATIC_COLORS.gray['900']} size="body1" text={description} weight="medium" />
      </Flexbox>
      {isRecipeQuizAvailable && (
        <S.FullWidthButton fontSize="subtitle2" text={btnText} textWeight="medium" onClick={redirectToTakeTest} />
      )}
      <Flexbox isFullWidth align="flex-start" direction="column" gap={12}>
        <Text color={STATIC_COLORS.gray['900']} size="body1" text={inputSuggestion} weight="medium" />
        <InputField
          icon={<SearchIcon />}
          name="search-recipe"
          placeholder={searchPlaceholderText}
          value={searchQuery}
          onChange={e => searchHandler(e.target.value)}
        />
        {isNoRecipesFound && <Text color={STATIC_COLORS.base.red} size="body1" text={noResult} weight="medium" />}
      </Flexbox>
      {!isNoRecipesFound && (isLoading || !!recipes?.length) && (
        <RecipeResult
          isDesignSetVinhoodApp={false}
          isRecipesLoading={isLoading}
          productCategory={productCategory}
          recipes={recipes}
        />
      )}
      <BackButton handleClick={redirectToPreviousPage} text={goBackBtn} />
    </Flexbox>
  );
};

export default FoodPairingPage;
