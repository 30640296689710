import { css, styled } from 'styled-components';

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  margin: 0 auto;
`;

export const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  ${({ theme }) => {
    if (theme?.isKioskRootElementType) {
      return css`
        width: 1080px;
        height: 60px;
        padding: 0 32px;
      `;
    }
    return css`
      width: 100%;
      height: 48px;
      padding: 0 16px;
    `;
  }}

  .logo {
    display: flex;
    align-items: center;
    height: 18px;
  }
`;

export const HeaderRightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .detach-fidelity-card-button {
    margin-right: 24px;
  }

  .close-button {
    all: unset;
    display: flex;
    align-items: flex-start;
    margin-left: 35px;
  }

  .invisible {
    visibility: hidden;
  }
`;
