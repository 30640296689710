import cn from 'classnames';
import { DetailedHTMLProps, InputHTMLAttributes, forwardRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as AlertIcon } from '@components/web/src/assets/icons/alert-circle.svg';
import { ReactComponent as EyeOffIcon } from '@components/web/src/assets/legacy/icons/eyeoff.svg';
import { ReactComponent as EyeOnIcon } from '@components/web/src/assets/legacy/icons/eyeon.svg';
import { ReactComponent as LockIcon } from '@components/web/src/assets/legacy/icons/lock.svg';
import { ReactComponent as MailIcon } from '@components/web/src/assets/legacy/icons/mail.svg';

type Type = 'email' | 'password' | 'text';

type Icons = Record<Type, JSX.Element | null>;

export interface InputFieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  type?: Type;
  hint?: string;
  label?: ILocaleText;
  icon?: JSX.Element;
  errors?: FieldErrors;
  showIcon?: boolean;
  className?: string;
  warning_message?: string;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    { name, icon, hint, label, errors, disabled, className, type = 'text', showIcon = false, warning_message, ...rest },
    ref,
  ) => {
    const [togglePasswordShow, setTogglePasswordShow] = useState(false);
    const error = !!errors && !!errors[name] && errors[name].message;

    const icons: Icons = {
      email: <MailIcon />,
      password: <LockIcon />,
      text: null,
    };

    const renderedIcon = icon || icons[type];
    const iconColor = '#252830';

    return (
      <label
        htmlFor={name}
        className={cn('mp-input', className, {
          'mp-input-disabled': disabled,
          'mp-input-invalid': !!error,
        })}
      >
        {!!label && (
          <span className="label">
            <LocaleFragment message={label} />
          </span>
        )}
        <div className="mp-input-container">
          <div
            className={cn('mp-input-container-start', {
              icon: showIcon || !!icon,
            })}
          >
            {(showIcon || !!icon) && renderedIcon}
          </div>

          <input
            ref={ref}
            autoComplete="on"
            className="input"
            disabled={disabled}
            id={name}
            name={name}
            type={type === 'password' && togglePasswordShow ? 'text' : type}
            {...rest}
          />

          <div className="mp-input-container-end">{error && <AlertIcon className="status-failure" />}</div>
          {type === 'password' && (
            <div
              className="mp-input-container-password-eye"
              role="button"
              tabIndex={0}
              onClick={() => setTogglePasswordShow(!togglePasswordShow)}
              onKeyDown={() => setTogglePasswordShow(!togglePasswordShow)}
            >
              {togglePasswordShow ? (
                <EyeOnIcon style={{ color: iconColor }} />
              ) : (
                <EyeOffIcon style={{ color: iconColor }} />
              )}
            </div>
          )}
        </div>

        {(error || hint || warning_message) && (
          <span className={cn('info', { 'info-error': error || warning_message, 'info-hint': hint })}>
            {(error && JSON.stringify(error)) || hint || warning_message}
          </span>
        )}
      </label>
    );
  },
);

export default InputField;
