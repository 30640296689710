import { createSelector } from 'reselect';

import { TCharacter, TCharacterDataByProductCategory } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { HARDCODED_AGREEMENT_ID_THIRD_PARTY, PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { RootState } from '@lib/core/service/types/appStateType';
import { IUserSlice } from '@lib/core/users/slices/user';
import { TUser, TUserAgreementItem, TUserGroup, TUserRole } from '@lib/core/users/types';
import { USER_ROLE_ANONYMOUS, USER_ROLE_KIOSK } from '@lib/core/users/utils/consts';
import RouteUtils from '@lib/tools/routes';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

/**
 * @returns the whole user state
 */
export const selectUserState = (state): IUserSlice => state.user || {};

/**
 * @returns whether the user is `identified` with Mixpanel or not.
 */
export const selectMixpanelUserDidIdentify = (state: RootState, defaultValue = false): boolean =>
  state?.tracking.mixPanelDidIdentify || defaultValue;

/**
 * @returns the user's role. `null` for anonymous user.
 */
export const selectUserRole: (state) => TUserRole = createSelector(
  [selectUserState],
  user => user?.data?.role || USER_ROLE_ANONYMOUS,
);

/**
 * @returns {boolean} if user is kiosk admin
 */
export const selectUserRoleIsKiosk: (state) => boolean = createSelector(
  [selectUserState],
  user => user?.data?.role === USER_ROLE_KIOSK || false,
);

/**
 * @returns The user's groups in django admin. `[]` for user without groups.
 */
export const selectUserGroups: (state) => TUserGroup[] = createSelector(
  [selectUserState],
  user => user?.data?.groups || [],
);

/**
 * @returns {string} the user's refresh token.
 */
export const selectUserRefreshToken = (state: RootState, defaultValue = ''): string =>
  state.auth.data?.refresh_token || defaultValue;

/**
 * @returns user's email address
 */
export const selectUserEmail: (state) => string = createSelector([selectUserState], user => user?.data?.email || '');

/**
 * @returns user's first name
 */
export const selectUserFirstName: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.first_name || '',
);

/**
 * @returns user's last name
 */
export const selectUserLastName: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.last_name || '',
);

/**
 * @returns user's primary key
 */
export const selectUserId: (state) => number | null = createSelector([selectUserState], user => user?.data?.pk || null);

/**
 * @returns raw user characters data array from profile
 */
export const selectUserCharacters: (state) => TCharacter[] = createSelector(
  [selectUserState],
  user => user?.data?.characters || [],
);

/**
 * @returns array of all user characters Ids
 */
export const selectUserCharactersIds: (state) => string[] = createSelector(
  [selectUserState],
  user => user?.data?.characters?.map(character => character?.identifier) || [],
);

/**
 * @returns the Kiosk user's fidelity card ID. Used for kiosks
 */
export const selectFidelityCardId = (state: RootState, defaultValue = ''): string =>
  state.fidelityCard.fidelityCardData?.fidelity_card?.card_identifier || defaultValue;

/*
/**
 * @returns raw user characters data from profile as JSON with product type as keys
 */
// TODO: remove this as soon as we implement the new user characters API
export const selectUserCharactersGroupByCategory: (state) => TCharacterDataByProductCategory = createSelector(
  [selectUserCharacters],
  userCharactersArray => {
    const userCharacters = {};
    userCharactersArray.forEach(characterData => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { product_category = '' } = characterData || {};
      if (product_category) {
        userCharacters[product_category] = characterData;
      }
    });
    return userCharacters || {};
  },
);

/**
 * @returns the user's initial retailer slug
 */
export const selectUserRetailerSlug: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.initial_retailer?.slug || '',
);

/**
 * @returns the user's retailer location id. Used for kiosks
 */
export const selectKioskUserRetailerLocationId: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.retailer_location?.identifier || '',
);

/**
 * @returns the user's retailer location slug. Used for kiosks
 */
export const selectKioskUserRetailerLocationSlug: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.retailer_location?.slug || '',
);

/**
 * @returns the user's privacy options array
 */
export const selectUserAgreementsArray: (state) => TUserAgreementItem[] = createSelector(
  [selectUserState],
  user => user?.data?.privacy_logs || [],
);

/**
 * @returns state of allowing to use users third party
 */
export const selectIsUserAllowedUseThirdParty: (state) => boolean = createSelector(
  [selectUserAgreementsArray],
  data => data.find(agr => agr.privacy_id === HARDCODED_AGREEMENT_ID_THIRD_PARTY)?.selection || false,
);

/**
 * @returns checks if the user has selected any option for the third party
 */
export const selectIsUserSelectedUseThirdParty: (state) => boolean = createSelector(
  [selectUserAgreementsArray],
  data => !!data.find(agr => agr.privacy_id === HARDCODED_AGREEMENT_ID_THIRD_PARTY),
);

/**
 * @returns the user's privacy options json with IDs as indexes
 */
export const selectUserAgreementsIndexedObj: (state) => Record<string, TUserAgreementItem> = createSelector(
  [selectUserAgreementsArray],
  privacyLogs => {
    const newUserAgreementsIndexedObj = {};
    privacyLogs?.forEach(agreement => {
      newUserAgreementsIndexedObj[agreement.privacy_id] = agreement;
    });
    return newUserAgreementsIndexedObj || {};
  },
);

/**
 * @returns the user's retailer location slug. Used for kiosks
 */
export const selectUserRetailerLocationSlug: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.retailer_location?.slug || '',
);

/**
 * ! Needs improvement
 * @returns the user's product category from the email address of the kiosk.
 */
export const selectKioskUserProductCategory = (
  state: RootState,
  defaultValue = PRODUCT_CATEGORY_NONE,
): TProductCategory => {
  const email = selectUserEmail(state);

  const kioskProductCategories: TProductCategory[] = [
    PRODUCT_CATEGORY_WINE,
    PRODUCT_CATEGORY_COFFEE,
    PRODUCT_CATEGORY_BEER,
  ];
  const productCategory = kioskProductCategories.find(pc => email.includes(`-${pc}`));

  return productCategory || defaultValue;
};

/**
 * @returns user session id
 */
export const selectUserSessionId: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.user_session_id || '',
);

/**
 * @returns value to be sure that both user data requests are done
 */
export const selectIsUserFullUserDataLoadedTemporaryHint: (state) => boolean = createSelector(
  [selectUserState],
  user => user?.isFullUserDataLoadedTemporaryHint || false,
);

/**
 * @returns boolean value is user data is fetching
 */
export const selectIsFetchingUserData: (state) => boolean = createSelector(
  [selectUserState],
  user => user?.isLoading || false,
);

/**
 * @returns user raw data
 */
export const selectUserDataRaw: (state) => TUser | Record<string, never> = createSelector(
  [selectUserState],
  user => user?.data || {},
);
/**
 * @returns kiosk user retailer slug
 */
export const selectKioskUserRetailerSlug: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.initial_retailer?.slug || '',
);

/**
 * @returns if user has any character (completed at least 1 test)
 */
export const selectIsUserHasAnyCharacter: (state) => boolean = createSelector(
  [selectUserState],
  user => !!user?.data?.characters?.length || false,
);

/**
 * @returns if user has character for retailer where we have only 1 product type
 */
export const selectIsUserHasCharacterForCurrentPC: (state) => boolean = createSelector(
  [
    selectUserCharactersGroupByCategory,
    selectUserRoleIsKiosk,
    selectKioskUserProductCategory,
    (state: RootState) => state?.service?.productCategory || RouteUtils.getProductCategory(),
  ],
  (userCharacters, isUserRoleKiosk, kioskUserProductCategory, productCategory) => {
    return isUserRoleKiosk ? !!userCharacters[kioskUserProductCategory] : !!userCharacters[productCategory] || false;
  },
);

/**
 * @returns user product preferences
 */
export const selectSelectedUserProductPreferences: (state) => {
  description: string;
  exposure: string;
  name: string;
  slug: string;
}[] = createSelector([selectUserState], user => user?.data?.user_product_preferences || []);

/**
 * @returns user image
 */
export const selectUserImage: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.profile_image || '',
);

/**
 * @returns upload state of user profile
 */
export const selectIsUploadingProfilePicture: (state) => boolean = createSelector(
  [selectUserState],
  user => user?.isUploadingProfilePicture || false,
);

/**
 * @returns value of user profiling (have character) based ob back-end logic
 */
export const selectIsUserProfiled: (state) => Record<TProductCategory, boolean> | Record<never, never> = createSelector(
  [selectUserState],
  user => user?.data?.is_profiled || {},
);

/**
 * @returns value of user profiling (have character) for some of product categories
 */
export const selectIsUserProfiledSomePC: (state) => boolean = createSelector([selectIsUserProfiled], isProfiled =>
  Object.values(isProfiled).some(Boolean),
);
