import { FC, useState } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { capitalizeFirstLetter } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { getProductCategoryTasteIcon } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_WINE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import LogoutModal from '@components/web/src/templates/Modals/LogoutModal/LogoutModal';
import * as S from '@components/web/src/templates/Start&HomePages/Widget/HomePage/styles';

export const HOME_PAGE_VARIANTS = {
  ANON: 'anon',
  ANON_WITH_CHARACTER: 'anonWithCharacter',
  LOGGED: 'logged',
  LOGGED_WITH_CHARACTER: 'loggedWithCharacter',
} as const;

export type THomePageVariants = (typeof HOME_PAGE_VARIANTS)[keyof typeof HOME_PAGE_VARIANTS];

interface IProps {
  variant: THomePageVariants;
  productCategory: TProductCategory;
  characterImage: string;
  characterName: string;
  userFirstName: string;
  isRecipeQuizAvailable: boolean;
  navigateToQuizSelectionPage: () => void;
  navigateToRecipePage: () => void;
  navigateToLoginPage: () => void;
  handleNavigation: () => void;
  handleResetUserData: () => void;
}

const HomePage: FC<IProps> = ({
  variant = HOME_PAGE_VARIANTS.ANON,
  productCategory = PRODUCT_CATEGORY_WINE,
  characterImage,
  characterName,
  userFirstName,
  isRecipeQuizAvailable,
  navigateToQuizSelectionPage,
  navigateToRecipePage,
  navigateToLoginPage,
  handleNavigation,
  handleResetUserData,
}) => {
  const isAnonVariant = variant === HOME_PAGE_VARIANTS.ANON;
  const isAnonWithCharacterVariant = variant === HOME_PAGE_VARIANTS.ANON_WITH_CHARACTER;
  const isLoggedWithCharacterVariant = variant === HOME_PAGE_VARIANTS.LOGGED_WITH_CHARACTER;

  const isAnonUser = isAnonVariant || isAnonWithCharacterVariant;
  const isUserWithCharacter = isAnonWithCharacterVariant || isLoggedWithCharacterVariant;

  const { productCategoriesPlural, productCategories } = localeCommon;
  const {
    defaultTitle,
    authTitle,
    anonTitle,
    defaultSubtitle,
    characterSubtitle,
    tasteChanged,
    alreadyRegistered,
    startTest,
    typeRecipe,
    seeMy,
    deleteYourData,
  } = localeWidget.homePage;

  const { publishedTerms } = LocaleUtils;
  const productCategoryText = publishedTerms[productCategories[productCategory]?.id];
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory]?.id];

  const [isLogoutModalOpened, setIsLogoutModalOpened] = useState(false);

  const icon = isUserWithCharacter ? characterImage : getProductCategoryTasteIcon(productCategory);

  const getTitleText = () => {
    if (isAnonVariant) return defaultTitle;
    if (isAnonWithCharacterVariant) return anonTitle;
    return authTitle;
  };

  const subtitleText = isUserWithCharacter ? characterSubtitle : defaultSubtitle;
  const btnText = isUserWithCharacter ? seeMy : startTest;
  const handleBtnClick = isUserWithCharacter ? handleNavigation : navigateToQuizSelectionPage;

  return (
    <S.HomePageContainer data-testid={`HomePage-${productCategory}-${variant}`}>
      {isLogoutModalOpened && isAnonUser && (
        <LogoutModal
          handleResetUserData={handleResetUserData}
          hideModal={() => setIsLogoutModalOpened(false)}
          isModalOpen={isLogoutModalOpened}
        />
      )}
      <S.ImageWrapper data-testid="ImageWrapper">
        <S.BackgroundIcon $backgroundColor={isUserWithCharacter && STATIC_COLORS.productColors[productCategory][50]} />
        <img alt="icon" src={icon} />
      </S.ImageWrapper>
      <S.ContentWrapper data-testid="ContentWrapper">
        <S.TextWrapper data-testid="TextWrapper">
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            localeOptions={{ userFirstName }}
            size="h5"
            text={getTitleText()}
            weight="semibold"
          />
          <Text
            color={STATIC_COLORS.base.black}
            size="subtitle2"
            text={subtitleText}
            localeOptions={{
              characterName,
              productCategory,
              productCategoryPluralText,
              productCategoryText,
            }}
          />
          {isUserWithCharacter && (
            <S.LinkButton
              handleClick={navigateToQuizSelectionPage}
              size="sm"
              text={tasteChanged}
              textWeight="normal"
              variant={VH_VARIANTS.LINK}
            />
          )}
        </S.TextWrapper>
        <S.ButtonsWrapper data-testid="ButtonsWrapper">
          <Button
            fontSize="subtitle2"
            handleClick={handleBtnClick}
            size="lg"
            text={btnText}
            localeOptions={{
              characterName,
              productCategory,
              productCategoryPluralText: productCategoryPluralText && capitalizeFirstLetter(productCategoryPluralText),
            }}
          />
          {isRecipeQuizAvailable && (
            <Button
              fontSize="subtitle2"
              handleClick={navigateToRecipePage}
              size="lg"
              text={typeRecipe}
              variant={VH_VARIANTS.LIGHT}
            />
          )}
          {isAnonUser && (
            <S.LinkButton
              handleClick={navigateToLoginPage}
              size="sm"
              text={alreadyRegistered}
              textWeight="normal"
              variant={VH_VARIANTS.LINK}
            />
          )}
          {isAnonWithCharacterVariant && (
            <S.LinkButton
              $isDeleteDataVariant
              handleClick={() => setIsLogoutModalOpened(true)}
              size="sm"
              text={deleteYourData}
              textWeight="normal"
              variant={VH_VARIANTS.LINK}
            />
          )}
        </S.ButtonsWrapper>
      </S.ContentWrapper>
    </S.HomePageContainer>
  );
};

export default HomePage;
