import * as S from '@components/web/src/components/LanguageSwitcher/SelectedLanguage/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import CaretDown from '@components/web/src/shared/Icons/CaretDown';

type Props = {
  activeLanguage: string;
  showLanguageList: boolean;
  isBlackVariant: boolean;
};
const SelectedLanguage = ({ activeLanguage, showLanguageList, isBlackVariant }: Props) => {
  return (
    <S.SelectedLanguageContainer data-testid={`SelectedLanguageContainer-${activeLanguage}`}>
      <Text
        color={!showLanguageList && isBlackVariant ? STATIC_COLORS.base.white : STATIC_COLORS.gray['900']}
        size="subtitle2"
        text={activeLanguage.toUpperCase()}
      />

      <CaretDown
        fillColor={!showLanguageList && isBlackVariant ? STATIC_COLORS.base.white : STATIC_COLORS.base.black}
      />
    </S.SelectedLanguageContainer>
  );
};

export default SelectedLanguage;
