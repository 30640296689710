import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as PlusIcon } from '@components/web/src/assets/icons/plus_icon.svg';
import BeerEmptyPlacesImage from '@components/web/src/assets/png/empty-state/empty_places_beer.png';
import CoffeeEmptyPlacesImage from '@components/web/src/assets/png/empty-state/empty_places_coffee.png';
import WineEmptyPlacesImage from '@components/web/src/assets/png/empty-state/empty_places_wine.png';
import BeerEmptyProductImage from '@components/web/src/assets/png/empty-state/empty_product_beer.png';
import CoffeeEmptyProductImage from '@components/web/src/assets/png/empty-state/empty_product_coffee.png';
import WineEmptyProductImage from '@components/web/src/assets/png/empty-state/empty_product_wine.png';
import BeerEmptyTagImage from '@components/web/src/assets/png/empty-state/empty_tags_beer.png';
import CoffeeEmptyTagImage from '@components/web/src/assets/png/empty-state/empty_tags_coffee.png';
import WineEmptyTagImage from '@components/web/src/assets/png/empty-state/empty_tags_wine.png';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/EmptyStateCard/styles';

type TEmptyVariants = 'places' | 'product' | 'tags';

interface Props {
  variant: TEmptyVariants;
  productCategory: TProductCategory;
  handleBtnClick: () => void;
}

type TVariantsItem = {
  icon: string;
  description: ILocaleText;
  title: ILocaleText;
};

const EmptyStateCard = ({ variant, productCategory, handleBtnClick }: Props) => {
  const {
    noTagsTitle,
    noTagsDescription,
    noPlacesDescription,
    noPlacesTitle,
    noProductDescription,
    noProductTitle,
    btnText,
  } = localeCommon.emptyStateCards;

  const { productCategoriesPlural } = localeCommon;
  const productCategoryPlural = productCategoriesPlural[productCategory];

  const ProductCategoryIcons = {
    places: {
      beer: BeerEmptyPlacesImage,
      coffee: CoffeeEmptyPlacesImage,
      wine: WineEmptyPlacesImage,
    },
    product: {
      beer: BeerEmptyProductImage,
      coffee: CoffeeEmptyProductImage,
      wine: WineEmptyProductImage,
    },
    tags: {
      beer: BeerEmptyTagImage,
      coffee: CoffeeEmptyTagImage,
      wine: WineEmptyTagImage,
    },
  };

  const variantDetails: Record<TEmptyVariants, TVariantsItem> = {
    places: {
      description: noPlacesDescription,
      icon: ProductCategoryIcons.places[productCategory],
      title: noPlacesTitle,
    },
    product: {
      description: noProductDescription,
      icon: ProductCategoryIcons.product[productCategory],
      title: noProductTitle,
    },
    tags: {
      description: noTagsDescription,
      icon: ProductCategoryIcons.tags[productCategory],
      title: noTagsTitle,
    },
  };

  const btnIndex = variant === 'product' ? productCategory : variant;

  return (
    <Flexbox isFullWidth align="center" direction="column" gap={24} justify="flex-start">
      <Image
        alt="empty"
        height={variant === 'places' ? '108px' : '89px'}
        src={variantDetails[variant].icon}
        width="89px"
      />
      <Flexbox isFullWidth align="center" direction="column" gap={16}>
        <Text
          localeIndex={{ productCategory }}
          localeVariables={{ productCategoryPlural }}
          size="subtitle2"
          text={variantDetails[variant].title}
          weight="semibold"
        />
        <S.CenteredText
          color={STATIC_COLORS.gray['800']}
          localeIndex={{ productCategory }}
          localeVariables={{ productCategoryPlural }}
          size="body1"
          text={variantDetails[variant].description}
        />
      </Flexbox>
      <Button
        isFullWidth
        icon={<PlusIcon className="white-icon" />}
        iconPosition="right"
        localeOptions={{ btnIndex }}
        text={btnText}
        onClick={handleBtnClick}
      />
    </Flexbox>
  );
};

export default EmptyStateCard;
